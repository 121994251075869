import { CancelToken } from 'axios'
import { OrderTotalSummaryProps } from '@components/OrderSummary/OrderTotalSummary/OrderTotalSummary'
import { ORDER_EXTEND_ATTRIBUTE_NAMES, ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES } from '../constants/order'
import { RecordSetResponse } from '../services/RequestService'
import { ShippingMethods } from './cart'
import { UsableAddress } from './checkout'
import { AddressFormData } from './form'
import { RxPrescriptionDetails } from './prescription'
import {
  Attachment,
  ContactLensData,
  IProduct,
  IProductAttribute,
  IProductAttributes,
  IProductUnavailableRaw,
  IServerProduct,
  ProductSeo,
} from './product'

export type OrderAdjustment = {
  currency?: string
  value?: string
}

export type Adjustment = {
  amount: string
  code: string
  currency: string
  description: string
  descriptionLanguage: string
  displayLevel: string
  language: string
  usage: string
}

export type OrderExtendAttributeName = (typeof ORDER_EXTEND_ATTRIBUTE_NAMES)[keyof typeof ORDER_EXTEND_ATTRIBUTE_NAMES]

type OrderItemExtendAttributeName =
  (typeof ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES)[keyof typeof ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES]

export type ShippingChargePolicy = {
  name: string
  type: string
  uniqueID: string
}

export type ShippingMode = {
  sequence: string
  carrier: string
  shipModeCode: string
  maxLeadTime: string
  minLeadTime: string
  description: string
  shipModeId: string
  maxLeadTimeIntShipping: string
  minLeadTimeIntShipping: string
  plantIntShipping: string
}
export type usableShippingMode = {
  usableShippingMode: ShippingInfo[]
}
export type ShippingInfo = {
  catentryId: string
  dedicatedShippingMode: ShippingMode[]
  productType: string
  upc: string
  usableShippingAddress: UsableAddress[]
  usableShippingMode?: any[]
}

export interface PaymentProtocolData {
  name: string
  value?: string
}

export interface PaymentInstruction {
  addressLine: string[]
  billing_address_id: string
  city: string
  country: string
  email1: string
  email2: string
  fax1: string
  fax2: string
  firstName: string
  lastName: string
  middleName: string
  nickName: string
  payMethodId: string
  personTitle: string
  phone1: string
  phone1Publish: string
  phone2: string
  phone2Publish: string
  piAmount: string
  piCurrency: string
  piDescription: string
  piId: string
  piLanguage: string
  piStatus: string
  postalCode: string
  protocolData: PaymentProtocolData[]
  state: string
  stateOrProvinceName: string
  xpaym_policyId: string
  xpaym_punchoutPayment: string
  xpaym_tokenization: string
  zipCode: string
}

export type Attribute<Name extends string> = {
  attributeName: Name
  attributeType: string
  attributeValue: string
}

export interface Order {
  promotionCode?: PromotionCode[]
  adjustment: Adjustment[]
  buyerDistinguishedName: string
  buyerId: string
  buyerPONumber: string
  channel: {
    channelIdentifer: {
      channelName: string
      uniqueID: string
    }
    description: {
      language: string // e.g. "-1"
      value: string
    }
    userData: any
  }
  estimatedTaxes: string
  grandTotal: string
  grandTotalCurrency: string
  IS_RX: string // "true" / "false"
  lastUpdateDate: string
  locked: string // "true" / "false"
  orderExtendAttribute: Attribute<OrderExtendAttributeName>[]
  orderId: string
  orderStatus: string
  orderTypeCode?: ORDER_TYPE_CODE
  parsedOrderItems: ParsedOrderItemsMapByItemType
  placedDate?: string
  prepareIndicator: string // "true" / "false"
  recordSetComplete: string // "true" / "false"
  recordSetCount: string
  recordSetStartNumber: string
  recordSetTotal: string
  resourceId: string
  resourceName: string
  shipAsComplete: string // "true" / "false"
  storeNameIdentifier: string
  storeUniqueID: string
  totalAdjustment: string
  totalAdjustmentCurrency: string
  totalProductPrice: string
  totalProductPriceCurrency: string
  totalSalesTax: string
  totalSalesTaxCurrency: string
  totalShippingCharge: string
  totalShippingChargeCurrency: string
  totalShippingTax: string
  totalShippingTaxCurrency: string
  x_isPersonalAddressesAllowedForShipping: string // "true" / "false"
  x_isPurchaseOrderNumberRequired: string // "true" / "false"
  x_promoHasAdjustment: string // "true" / "false"
  x_trackingIds: string
  xinvoiceUrls: { [orderItemId: string]: string }
  x_orderItemsCount: string
  xtrackingUrls: { [orderItemId: string]: string }
  x_firstOrderItemThumbnail?: string
  x_estimatedDeliveryDates?: string
  x_delivery_estimated_dates?: string
}

export interface IOrderItem {
  id?: string
  adjustment: Adjustment[]
  addressId: string
  addressLine?: string[]
  addressType?: AddressFormData['addressType']
  attachments: Attachment[]
  attributes: IProductAttribute[]
  productAttributes: IProductAttributes
  carrier: string
  city?: string
  contractId: string
  correlationGroup: string
  country?: string
  createDate: string
  currency: string
  description: string
  email1: string
  expectedShipDate: string
  firstName?: string
  freeGift: string
  fulfillmentCenterId: string
  fulfillmentCenterName: string
  isExpedited: string
  language: string
  lastName?: string
  lastUpdateDate: string
  stateOrProvinceName: string
  name: string
  nickName: string
  offerID: string
  orderItemExtendAttribute: Attribute<OrderItemExtendAttributeName | string>[]
  orderItemFulfillmentStatus: string
  orderItemId: string
  groupedOrderItemsId?: string[]
  orderItemInventoryStatus: string
  orderItemPrice: string
  orderItemStatus: string
  parentCatalogGroupID: string | string[]
  phone1?: string
  partNumber: string
  productId: string
  quantity: string
  roxableAttributes?: RoxableAttributes
  roxableServices?: any[]
  prescriptionDetails?: {
    data?: RxPrescriptionDetails | null
    file?: {
      content?: any | null
      type?: string
    }
  }
  salesTax: string
  salesTaxCurrency: string
  seo: ProductSeo
  state: string
  shipModeCode: string
  shipModeDescription: string
  shipModeId: string
  shipModeLanguage: string
  shippingCharge: string
  shippingChargeCurrency: string
  shippingTax: string
  shippingTaxCurrency: string
  sibilingOrderItem: IOrderItem | null
  totalAdjustment: OrderAdjustment
  unitPrice: string
  unitQuantity: string
  unitUom: string
  UOM: string
  usableShippingChargePolicy: ShippingChargePolicy[]
  xitem_isPersonalAddressesAllowedForShipping: string
  xitem_display_catentry_id: string
  xitem_field1?: string
  xitem_field2?: string
  xitem_estimatedDeliveryDate?: string
  x_offerpriceRx?: string
  x_offerDiscountpriceRx?: string
  x_priceBadge?: string
  zipCode?: string
  groupedItem?: boolean
  prescriptionUploaded?: boolean
  groupedOrderItemAttributes?: {
    attrName: string
    attrValue: string | IOrderItem['orderItemExtendAttribute']
  }[]
  quantityFormatted?: string
}

export type OrderItemWithRoxProps = IOrderItem & {
  roxableAttributes: RoxableAttributes
  roxableServices: IOrderItem[]
}

export interface OrderUnavailableItem extends IProductUnavailableRaw {
  siblingOrderItemId?: string
  orderItemExtendAttribute?: ContactLensData[]
}

export type RoxableAttributes = {
  correctionType: string
  lensBrand: string
  lensType: string
  lensTreatment: string
  lensThickness: string
  lensColor: string
  polarized: boolean
}

export type OrderItemsAttachments = Record<string, Attachment[]>

export interface IOrderDetails extends Order {
  detailedOrderItems: IOrderItem[]
  orderComplete: boolean
  orderItem: IOrderItem[]
  paymentInstruction: PaymentInstruction[]
  x_displayinvoice: boolean
  x_data: { productDetails: IServerProduct[] }
  prescriptionPeriodExpired?: boolean
  buyerPONumber: string
}

export type PromotionIdentifier = {
  nullcalculationCodeIdentifier: string | null
  externalIdentifier: string | null
  uniqueID: string | null
}

export type Promotion = {
  description: string
  promotionId: string
  promotionIdentifier?: PromotionIdentifier
}

export type PromotionCode = {
  associatedPromotion: Promotion[]
  code: string
}

export interface Cart extends RecordSetResponse<Omit<Order, 'orderTypeCode'>> {
  orderItem: IOrderItem[]
  paymentInstruction: PaymentInstruction[]
  prepareIndicator: string
  promotionCode?: PromotionCode[]
  resourceName: 'cart'
  x_promoHasAdjustment: string
  isCartThresholdReached?: boolean
  isSubscriptionActiveOnCart: boolean
  subscriptionFrequencyValue?: string
  productCount?: number
}

export type AddItemActionPayload = {
  callback?(): void
  params: {
    cancelToken: CancelToken
    catentryId?: string | string[]
    contractId: string
    product?: IProduct
    langId: string
    lensId?: string
    partnumber: string | string[]
    quantity: string[]
    widget?: string
  }
}

export interface OrderRecapItemProps {
  orderItem: IOrderItem
  order?: OrderTotalSummaryProps['order']
  onDelete: (item: IOrderItem) => void
  readonly?: boolean
  onItemUpdated?: (quantity: string, orderItemId: string) => Promise<string | undefined>
  onImageClicked?: () => void
  isDisabledRemoveClick?: boolean
  isLoading?: boolean
  isCLAccessories?: boolean
  isThankYouPage?: boolean
}

export interface OrderRecapRxItemProps {
  orderItem: OrderItemWithRoxProps
  onDelete: (item: IOrderItem) => void
  readonly?: boolean
  onItemUpdated?: (quantity: string, orderItemId: string) => void
  onImageClicked?: () => void
  isDisabledRemoveClick?: boolean
}

export interface ShippingChargesWithoutPromotions {
  freeDeliveryPromotion: boolean
  shipModeCharges: number
  shipModeId: string
  shipModeName: ShippingMethods
}

export type PrescriptionItemType = 'rx' | 'cl' | 'sun' | 'cl-acc' | 'default'
export type ParsedOrderItemsMapByItemType = Record<
  PrescriptionItemType,
  IOrderItem[] | OrderItemWithRoxProps[] | null
> | null

export interface PrescriptionFileStatus {
  file: File | null
  error: any
  errorType: string | null
  isPristine?: boolean
}

export interface IPromoCode {
  orderId: string
  promoCode?: string
  resourceName: string
}

export interface ICreateOrderReminderPayload {
  enabled: string
  sms: string
  email: string
  daysLensWear?: string
  reminderDate?: string
  orderId?: string
  storeId?: string
}

export interface ICreateOrderReminderResponse {
  userId: string
  reminderId: string
  orderId: string
  orderStatus: string
  reminderDate: string
  sms: string
  mobileNumber: string
  email: string
  emailAddress: string
  successCode: string
  successMessage: string
}

export interface IUpdateOrderReminderPayload {
  enabled: string
  sms: string
  mobileNumber?: string
  email: string
  emailAddress?: string
  reminderDate?: string
  daysLensWear?: string
  orderId?: string
  storeId?: string
}

export interface IUpdateOrderReminderResponse {
  reminderId: number
  successMessage: string
  userId: number
  successCode: number
}

export interface IUpdateOrderReminder {
  reminderId: number
  successMessage: string
  userId: number
  successCode: string
}

export interface IGetOrderRemindePayload {
  storeId: string
  orderId: string
}

export interface IGetOrderRemiderResponse {
  emailAddress: string
  orderId: number
  reminderId: number
  daysLensWear: number
  mobileNumber: string
  sms: string
  orderStatus: string
  userId: number
  reminderDate: string
  email: string
}

export interface IDeleteOrderReminderPayload {
  storeId: string
  orderId: string
}
export interface IDeleteOrderReminderResponse {
  successMessage: string
}

export interface IGetNearestOrderPayload {
  storeId: string
}
export interface IGetNearestOrderReminderResponse {
  emailAddress: string
  orderId: number
  reminderId: number
  daysLensWear: number
  mobileNumber: string
  sms: string
  orderStatus: string
  userId: number
  reminderDate: string
  email: string
}

export interface CreateReminderPayload {
  storeId: string
  orderId: string
  enabled?: string
  sms?: string
  email?: string
}

export interface CreateReminderResponse {
  emailAddress: string
  orderId: number
  reminderId: number
  orderItemId?: number
  mobileNumber: string
  sms: 'Y' | 'N' // (enabled)
  orderStatus: string
  userId: number
  reminderDate: string
  email: 'Y' | 'N' // (enabled)
  successCode: string
  successMessage: string
  daysLensWear: number // number of days
}

export type SummaryOrder = Pick<
  Order | Cart,
  | 'orderId'
  | 'grandTotal'
  | 'grandTotalCurrency'
  | 'totalAdjustment'
  | 'totalAdjustmentCurrency'
  | 'totalProductPrice'
  | 'totalProductPriceCurrency'
  | 'totalSalesTax'
  | 'totalSalesTaxCurrency'
  | 'totalShippingCharge'
  | 'totalShippingChargeCurrency'
  | 'totalShippingTax'
  | 'orderExtendAttribute'
  | 'adjustment'
  | 'promotionCode'
  | 'x_promoHasAdjustment'
>

export enum ORDER_TYPE_CODE {
  ORDER = 'ORD',
  RECURRING = 'REC',
}
