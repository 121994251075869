export const isEmpty = <T>(value: T): boolean => {
  if (value === null) {
    return true
  }

  if (typeof value === 'object') {
    return Object.keys(value as object).length === 0
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0
  }

  return false
}

export const compact = <T>(array: T[]): T[] => {
  return array.filter(Boolean)
}

/**
 * Checks whether string is valid JSON or not
 *
 * @param item value to be checked for valid json
 * @returns boolean
 */
export function isJson(item: unknown): boolean {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item
  try {
    value = JSON.parse(value)
  } catch (e) {
    return false
  }
  return typeof value === 'object' && value !== null
}

type GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
    ? '0' extends keyof T
      ? undefined
      : number extends keyof T
        ? T[number]
        : undefined
    : undefined

type FieldWithPossiblyUndefined<T, Key> = GetFieldType<Exclude<T, undefined>, Key> | Extract<T, undefined>

type IndexedFieldWithPossiblyUndefined<T, Key> = GetIndexedField<Exclude<T, undefined>, Key> | Extract<T, undefined>

type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ? FieldWithPossiblyUndefined<T[Left], Right>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
      ? FieldKey extends keyof T
        ? FieldWithPossiblyUndefined<IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>, Right>
        : undefined
      : undefined
  : P extends keyof T
    ? T[P]
    : P extends `${infer FieldKey}[${infer IndexKey}]`
      ? FieldKey extends keyof T
        ? IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
        : undefined
      : undefined

export const sum = <T>(array?: T[]): number => {
  if (!array) {
    return 0
  }

  return array.reduce((acc, num) => (typeof num === 'number' ? acc + num : acc), 0)
}

type Collection<T> = Array<T> | object
export const size = <T>(collection: Collection<T>) =>
  Array.isArray(collection) ? collection.length : Object.keys(collection).length

export const uniq = <T>(array: T[]): T[] => [...new Set(array)]

export const uniqBy = <T>(arr: T[], iteratee: Iteratee<T>): T[] => {
  const cb: Iteratee<T> = typeof iteratee === 'function' ? iteratee : (o: T) => o[iteratee]

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item)
        map.has(key) || map.set(key, item)
        return map
      }, new Map())
      .values(),
  ]
}

export const countBy = <T>(arr: T[], iteratee: Iteratee<T>): Record<string, number> => {
  const cb: Iteratee<T> = typeof iteratee === 'function' ? iteratee : (o: T) => o[iteratee]

  return arr.reduce((countMap, item) => {
    const key = String(cb(item))
    countMap[key] = (countMap[key] || 0) + 1
    return countMap
  }, {})
}

const isPrimitive = (val: unknown): val is Primitive => {
  return ['string', 'number', 'boolean', 'undefined'].includes(typeof val) || val === null
}
export const isEqual = <T1, T2>(obj1: T1, obj2: T2) => {
  if (isPrimitive(obj1) && isPrimitive(obj2)) {
    return (obj1 as Primitive) === (obj2 as Primitive)
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const debounce = <T extends unknown[], O>(mainFunction: (...args: T) => O, delay: number) => {
  let timer

  return function (...args: T) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      mainFunction(...args)
    }, delay)
  }
}

type Dictionary<T> = {
  [key: string]: T[]
}
export const groupBy = <T, K extends keyof Dictionary<T>>(arr: T[], fn: (item: T) => K | boolean) =>
  arr.reduce<Dictionary<T>>((prev, curr) => {
    const fnRes = fn(curr)
    const groupKey = typeof fnRes === 'boolean' ? (`${fnRes}` as K) : fnRes
    const group = prev[groupKey] || []
    group.push(curr)
    return { ...prev, [groupKey]: group }
  }, {})

type Primitive = string | number | boolean | null | undefined

type DeepCloneable = {
  [key: string]: DeepCloneable | Primitive | DeepCloneable[] | Primitive[]
}

type DeepClone<T> = T extends DeepCloneable ? { [K in keyof T]: DeepClone<T[K]> } : T

const isObject = (value: unknown): value is DeepCloneable => typeof value === 'object' && value !== null

export const cloneDeep = <T>(source: T): DeepClone<T> => {
  if (!isObject(source)) {
    return source as DeepClone<T>
  }

  if (Array.isArray(source)) {
    return source.map(item => cloneDeep(item)) as unknown as DeepClone<T>
  }

  const clonedObject = {} as DeepClone<T>

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      clonedObject[key] = cloneDeep(source[key])
    }
  }

  return clonedObject
}

export const pickBy = <T extends object>(
  obj: T,
  fn: (value: T[keyof T], key: keyof T, obj: T) => boolean
): Partial<T> =>
  Object.entries(obj)
    .filter(([key, value]) => fn(value, key as keyof T, obj))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as Partial<T>)

export const last = <T>(array: T[]): T | undefined => {
  if (!Array.isArray(array) || array.length === 0) {
    return undefined
  }
  return array[array.length - 1]
}

type Order = 'asc' | 'desc'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Iteratee<T> = ((item: T) => any) | keyof T

export const orderBy = <T>(collection: T[], iteratees: Iteratee<T>[], orders?: Order[]): T[] => {
  const compareFunction = (a: T, b: T): number => {
    for (let i = 0; i < iteratees.length; i++) {
      const iteratee = iteratees[i]
      const order = orders && orders[i] === 'desc' ? -1 : 1

      const aValue = typeof iteratee === 'function' ? iteratee(a) : a[iteratee]
      const bValue = typeof iteratee === 'function' ? iteratee(b) : b[iteratee]

      if (aValue < bValue) {
        return -1 * order
      }
      if (aValue > bValue) {
        return 1 * order
      }
    }
    return 0
  }
  return collection.slice().sort(compareFunction)
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export const omit = <T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => {
  const result: Partial<T> = {}

  Object.keys(obj).forEach(key => {
    const typedKey = key as K
    if (!keys.includes(typedKey)) {
      result[typedKey] = obj[typedKey]
    }
  })

  return result as Omit<T, K>
}

export const chunk = <T>(array: T[], size: number): T[][] => {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

export const union = <T>(...arrays: T[][]): T[] => {
  const set = new Set<T>()

  arrays.forEach(arr => {
    arr.forEach(item => {
      set.add(item)
    })
  })

  return Array.from(set)
}

export const unionBy = <T, K extends keyof T>(key: K, ...arrays: T[][]): T[] => {
  const keySet = new Set()
  const result: T[] = []

  arrays.forEach(arr => {
    arr.forEach(item => {
      const keyValue = item[key] as T[K]
      if (!keySet.has(keyValue)) {
        keySet.add(keyValue)
        result.push(item)
      }
    })
  })

  return result
}

export const pick = <T extends object, K extends keyof T>(object: T | undefined, ...props: K[]): Pick<T, K> => {
  const picked: Partial<T> = {}

  if (!object) return picked as Pick<T, K>

  props.forEach(key => {
    if (key in object) {
      picked[key] = object[key]
    }
  })

  return picked as Pick<T, K>
}

export const values = <T>(obj: Record<string, T> | null | undefined): T[] => {
  if (obj == null) {
    return []
  }

  return Object.keys(obj).map(key => obj[key])
}

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const merge = <T extends Record<string, any>>(obj1: T, obj2: T): T => {
  const merged: T = { ...obj1 }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
        merged[key] = merge(obj1[key] || ({} as T[typeof key]), obj2[key])
      } else {
        merged[key] = obj2[key]
      }
    }
  }

  return merged
}

type FlattenDeep<T> = T extends (infer U)[] ? (U extends Array<T> ? FlattenDeep<U> : U) : T
export const flattenDeep = <T>(arr: Array<T | Array<T>>): Array<FlattenDeep<T>> => {
  return arr.reduce<Array<FlattenDeep<T>>>((acc, item) => {
    if (Array.isArray(item)) {
      return acc.concat(flattenDeep(item))
    } else {
      return acc.concat(item as FlattenDeep<T>)
    }
  }, [])
}

type Comparator<T1, T2> = (item1: T1, item2: T2) => boolean

export const intersectionWith = <T1, T2>(arr1: T1[], arr2: T2[], comparator: Comparator<T1, T2>): T1[] =>
  arr1.filter(item1 => arr2.some(item2 => comparator(item1, item2)))

export const pipe =
  <T>(...fns: ((arg: T) => T)[]) =>
  (x: T) =>
    fns.reduce((v, f) => f(v), x)

export const replaceTextWithPattern = (text: string, pattern: string, replacement: string) => {
  if (text) {
    const re = new RegExp(pattern, 'g')

    if (text.indexOf(pattern) >= 0) {
      return text.replace(re, replacement)
    }
  }
  return text
}

export const scrollInto = (element: HTMLElement | null, scrollIntoViewOptions?: ScrollIntoViewOptions) => {
  if (element) {
    element.scrollIntoView(scrollIntoViewOptions || {})
  }
}

export const hashCode = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}

/**
 * Filters out specified items from an array.
 * @param {Array} originalArray - The array to filter.
 * @param {Array} itemsToRemove - Items to be removed from the original array.
 * @returns {Array} - New array with specified items removed.
 */
export function filterArray<T>(originalArray: T[], itemsToRemove: T[]): T[] {
  const removalSet = new Set(itemsToRemove)
  return originalArray.filter(item => !removalSet.has(item))
}
export const isClientSide = () => typeof window !== 'undefined'

/**
 * Check is valid Luhn value
 * Taken from Forter docs
 * https://portal.forter.com/app/integration/docs/guides?path=%2Fdocs%2Fphone%2Fcontent%2FwebInjection%2Fweb-id
 * @param value string representation of number
 * @returns result of Luhn check
 */
export const isValidLuhnValue = (value: string) => {
  // Luhn check from forter docs
  let sum = 0
  let mul = 1
  let len = value.length
  while (len--) {
    const ca = parseInt(value.charAt(len), 10) * mul
    sum += ca > 9 ? ca - 9 : ca
    mul = 3 - mul
  }
  return sum % 10 === 0 && sum > 0
}

export const normalizedTeaserText = teaserText => teaserText?.replace(/<.*?>/gi, '') || ''

export const pluralize = (count, word) => (count > 1 ? `${word}s` : word)
