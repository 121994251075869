import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import loginIdentityService from '@foundation/apis/transaction/loginIdentity.service'
import personService from '@foundation/apis/transaction/person.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PersonResponse } from '@typesApp/user'
import { isRememberMeEnabledSelector } from '@redux/selectors/site'
import { isPartiallyAuthenticatedSelector } from '@features/context/selector'
import { isAutoLoginSelector } from '@features/user/selector'
import { RootReducerState } from '@redux/reducers'
import Axios, { Canceler, AxiosResponse } from 'axios'

import { setUserDetails, setUserLoggedIn, setUserIsLoggingIn } from '../slice'
import { ILoginArgs } from './login'
import { logout } from './logout'

export type ILoginAndFetchDetailsArgs = ILoginArgs

export const loginAndFetchDetails = createAsyncThunk<any, ILoginAndFetchDetailsArgs>(
  'user/loginAndFetchDetails',
  async (args, { dispatch, getState }) => {
    const CancelToken = Axios.CancelToken
    const cancels: Canceler[] = []

    const state = getState() as RootReducerState
    const isPartiallyAuthenticated = isPartiallyAuthenticatedSelector(state)
    const isRememberMeEnabled = isRememberMeEnabledSelector(state)
    const isThirdPartyPaymentAutologin = args.isAutoLogin ?? false
    const isCtaAutologin = isAutoLoginSelector(state)
    const payloadBase: unknown = {
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c)
      }),
      ...(isRememberMeEnabled && { rememberMe: false, updateCookies: true }),
      storeId: args?.storeId,
      skipRedirect: true,
    }
    const payload = payloadBase ?? {}

    if (isPartiallyAuthenticated || isCtaAutologin) {
      await dispatch(logout(payload))
      dispatch(setUserIsLoggingIn(true))
    }

    const response = await loginIdentityService.login(args)

    const loginPayload = { ...response.data, storeId: args?.storeId }
    if (args?.widget) {
      loginPayload['widget'] = args.widget
    }

    dispatch(setUserLoggedIn({ ...loginPayload, isRememberMeEnabled, isAutologin: isThirdPartyPaymentAutologin }))
    dispatch(fetchUserContext(loginPayload))
    dispatch(fetchContract(loginPayload))
    dispatch(fetchEntitledOrganization(loginPayload))

    const response2: AxiosResponse<PersonResponse> = await personService.findPersonBySelf({
      widget: args.widget,
      storeId: args.storeId,
      langId: args.langId,
    })
    const loginPayload2 = response2.data
    dispatch(setUserDetails(loginPayload2))
  }
)
