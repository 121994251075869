import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { cmsApi } from './query'

import { IContentMegaMenu, IMegaMenu } from '@typesApp/cms'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IFooter, IHeader, IPage, IPageStaticLanding } from './cms.type'

export interface ICmsState {
  preFooter: IPlacement | null
  menuLoaded: boolean
  menuLoading: boolean
  headerLoading: boolean
  headerLoaded: boolean
  footerLoading: boolean
  footerLoaded: boolean
  header: IHeader | null
  megaMenu: IMegaMenu | null
  footer: IFooter | null
  pageStaticLanding: IPageStaticLanding | null
  contentMegaMenu: IContentMegaMenu[]
}
export const initialCmsState: ICmsState = {
  preFooter: null,
  menuLoaded: false,
  menuLoading: false,
  headerLoading: false,
  headerLoaded: false,
  footerLoading: false,
  footerLoaded: false,
  header: null,
  megaMenu: null,
  footer: null,
  pageStaticLanding: null,
  contentMegaMenu: [],
}
export const cmsSlice = createSlice({
  name: 'cms',
  initialState: initialCmsState,
  reducers: {
    setPreFooter: (state: ICmsState, action) => {
      state.preFooter = action.payload
    },
    setHeader: (state: ICmsState, action) => {
      state.header = action.payload
    },
    setMegaMenu: (state: ICmsState, action) => {
      state.megaMenu = action.payload
    },
    setFooter: (state: ICmsState, action) => {
      state.footer = action.payload
    },
    setPageStaticLanding: (state: ICmsState, action) => {
      state.pageStaticLanding = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(cmsApi.endpoints.getMegaMenu.matchPending, state => {
        state.menuLoading = true
      })
      .addMatcher(cmsApi.endpoints.getMegaMenu.matchFulfilled, (state, action) => {
        state.contentMegaMenu = action.payload?.contentMegaMenu
        state.menuLoaded = true
      })
      .addMatcher(
        isAnyOf(cmsApi.endpoints.getMegaMenu.matchFulfilled, cmsApi.endpoints.getMegaMenu.matchRejected),
        state => {
          state.menuLoading = false
        }
      )
      .addMatcher(cmsApi.endpoints.getHeader.matchPending, state => {
        state.headerLoading = true
      })
      .addMatcher(cmsApi.endpoints.getHeader.matchFulfilled, state => {
        state.headerLoaded = true
      })
      .addMatcher(
        isAnyOf(cmsApi.endpoints.getHeader.matchRejected, cmsApi.endpoints.getHeader.matchFulfilled),
        state => {
          state.headerLoading = false
        }
      )
      .addMatcher(cmsApi.endpoints.getFooter.matchPending, state => {
        state.footerLoading = true
      })
      .addMatcher(cmsApi.endpoints.getFooter.matchFulfilled, state => {
        state.footerLoaded = true
      })
      .addMatcher(
        isAnyOf(cmsApi.endpoints.getFooter.matchRejected, cmsApi.endpoints.getFooter.matchFulfilled),
        state => {
          state.footerLoading = false
        }
      )
  },
})

export const { setPreFooter, setHeader, setMegaMenu, setFooter, setPageStaticLanding } = cmsSlice.actions
export default cmsSlice.reducer
