import { createAsyncThunk } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import { ORDER_CONFIGS } from '../../../configs/order'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'
import { CART } from '../../../constants/routes'
import { sendAddToCartClickEvent } from '../../../foundation/analytics/tealium/lib'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { IProduct } from '../../../types/product'
import { sendSuccessMessage } from '../../success/slice'
import fetchCart from './fetchCart'
import { setAddToCartBusy } from '@features/product/slice'

export interface IAddItemArgs {
  cancelToken?: CancelToken
  catentryId?: string | string[]
  contractId?: string
  product?: IProduct
  langId: string
  lensId?: string
  partnumber: string | string[]
  quantity: string[]
  widget?: string
  callback?: any
  updateCart?: boolean
  customerSegments?: string[]
}

const addItem = createAsyncThunk<any, IAddItemArgs>('order/addItem', async (args, { dispatch, rejectWithValue }) => {
  try {
    const payload = args
    const addToCartSuccessCallback = args.callback
    const shouldUpdateCart = args.updateCart
    const cartPayload = {
      contractId: payload.contractId,
    }

    const _orderExtendAttributes: any[] = []
    const _orderItemExtendAttribute: any[] = []
    const _orderItems: any[] = []
    let catentryIds: string[] = []
    let partnumbers: string[] = []
    let quantities: any[] = []
    if (payload.partnumber) {
      partnumbers = payload.partnumber instanceof Array ? payload.partnumber : [payload.partnumber]
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity]
    } else if (payload.catentryId) {
      catentryIds = payload.catentryId instanceof Array ? payload.catentryId : [payload.catentryId]
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity]
    }

    for (const i in partnumbers) {
      if (payload.lensId) {
        _orderItemExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'RxLensId',
              attributeType: 'String',
              attributeValue: payload.lensId,
            },
          ]
        )
      }
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId: payload.contractId,
        orderItemExtendAttribute: payload.lensId ? _orderItemExtendAttribute : undefined,
      }
      _orderExtendAttributes[i] = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }
    }
    for (const i in catentryIds) {
      _orderItemExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
        ]
      )
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: payload.contractId,
        orderItemExtendAttribute: _orderItemExtendAttribute,
      }
      _orderExtendAttributes[i] = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }
    }
    const body = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        orderExtendAttribute: _orderExtendAttributes,
      },
    }
    if (payload.widget) {
      body['widget'] = payload.widget
      cartPayload['widget'] = payload.widget
    }

    const response = await cartService.addOrderItem(body)

    shouldUpdateCart && dispatch(fetchCart({ ...args, ...cartPayload })) // yield call(fetchCart, { ...args, payload: cartPayload })
    dispatch(
      sendSuccessMessage({
        key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
        link: {
          url: CART,
          textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
        },
      })
    )

    const productsWithQuantity = payload.product
      ? [
          {
            ...payload.product,
            quantity: args.quantity[0],
            id: payload.product.uniqueID,
          },
        ]
      : []
    sendAddToCartClickEvent(productsWithQuantity, args.customerSegments)

    addToCartSuccessCallback?.()
    dispatch(setAddToCartBusy(false))
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  } finally {
  }
})

export default addItem
