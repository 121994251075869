import { getClientLocale, replaceLocaleInUrl } from '@utils/locale'
import NextLink, { type LinkProps } from 'next/link'
import { CSSProperties, forwardRef, PropsWithChildren } from 'react'

type Link = LinkProps &
  PropsWithChildren & {
    className?: string
    id?: string
    target?: string
    style?: CSSProperties
    isLocaleDomain: boolean
    locationOrigin: string
    locale: LinkProps['locale']
  }

export const Link: React.FC<Link> = ({
  href,
  prefetch = false,
  children,
  className,
  id,
  target,
  locale,
  isLocaleDomain,
  locationOrigin,
  ...rest
}) => {
  const internalHref = replaceLocaleInUrl({
    isLocaleDomain,
    locationOrigin,
    locale: locale as string,
    href: href as string,
  })

  return (
    <NextLink
      href={internalHref ?? '#NOT_FOUND'}
      prefetch={prefetch}
      className={className}
      id={id}
      target={target}
      locale={getClientLocale(isLocaleDomain, locale as string)}
      {...rest}
    >
      {children}
    </NextLink>
  )
}
