import { padDecimals } from '@components/common/UI/ProductPrice/utils/utils'
import { ANALYTICS_PRODUCT_CATEGORY_MAP, USER_SEGMENT_GUEST, USER_SEGMENT_REGISTERED_USERS } from '@constants/common'
import { PRODUCT_SOLDOUT_STATUS } from '@constants/product'
import {
  PRODUCT_STATUS,
  LOGIN_REDIRECT_EVENT,
  TRACKER_ADDTOWISHLIST,
} from '@foundation/analytics/tealium/constants/tracker'
import {
  CommonData,
  PLPData,
  ProductForAnalytics,
  ProductForAnalyticsFields,
} from '@foundation/analytics/tealium/interfaces'
import { getFrameAdvisorAnalyticsData, sendAnalyticEventRaw } from '@foundation/analytics/tealium/lib'
import { FrameGeniusType } from '@hooks/useFrameGenius'
import SizeAdvisorUtil from '@utils/FrameGenius/SizeAdvisorUtil'
import {
  getBrand,
  getFrameMaterial,
  getFrameShape,
  getFrameType,
  getFrontColor,
  getLensType,
  getLensesColor,
  getLensesTreatment,
  getModelName,
  getProductType,
  getSoldOut,
} from '@utils/productAttributesAlgolia'
import { IAlgoliaHit, ProductTypesEnum } from '../../../src/types/product'
import { Refinement } from '@utils/Plp/PlpReducer'
import { ANALYTICS_PAGE_TYPE } from '@foundation/hooks/useAnalyticsData'
import { LENS_TYPE } from '@foundation/analytics/tealium/formatters/productFormatter'

export const ATTRIBUTES_TRANSLATED = 'attributes_translated.'
export const SORT_BY = 'sort_by'
const SORT_RECOMMENDED_FACET = '__reco_desc'
const SORT_BY_PRICE_STRING = 'Price'

export const getAttributesFromUrl = (parameters, customerSegment: string) => {
  const keys = Object.keys(parameters ?? {})
  const attributes = {}
  keys.forEach(key => {
    if (key.startsWith(ATTRIBUTES_TRANSLATED) || key.startsWith('attributes.')) {
      attributes[key] = Array.isArray(parameters[key]) ? parameters[key] : [parameters[key]]
    }
    if (key.startsWith('discount')) {
      key = `sort.discount.${customerSegment}`
      parameters[key] = parameters['discount']
      attributes[key] = Array.isArray(parameters[key]) ? parameters[key] : [parameters[key]]
    }
  })
  //removes special characters that are read from URL
  for (const facet in attributes) {
    attributes[facet] = attributes[facet].map((el: string) => decodeURIComponent(el.replace(/\+/g, ' ')))
  }
  return attributes
}

export const getHingeRangeAttributeFromUrl = (parameters): string => {
  const hingeRangeAttributeName = `${ATTRIBUTES_TRANSLATED}HINGE_DISTANCE_RANGE`
  const hingeRangeFacet = parameters[hingeRangeAttributeName]

  if (hingeRangeFacet) {
    return `${hingeRangeAttributeName}:${hingeRangeFacet}`
  }

  return ''
}

export const transformAttachmentsToImage = attachments => {
  return attachments
    ? attachments.map(({ id, identifier, name, rule, url, sequence }) => ({
        attachementAssetID: id,
        identifier,
        name,
        usage: rule,
        attachmentAssetPathRaw: url,
        sequence: sequence.toString(),
      }))
    : null
}

export const INDICES_MAP = {
  BEST_SELLERS: 0,
  NEW_ARRIVALS: 5,
  PRICE_ASC: 3,
  PRICE_DESC: 4,
  QUERY_SUGGESTIONS: -1,
}

const formatProduct = (item, islogged?: boolean): ProductForAnalyticsFields => {
  const type = getProductType(item)

  let offerPrice = 0
  let listPrice = 0
  const productCategory = ANALYTICS_PRODUCT_CATEGORY_MAP[type.toUpperCase()] || type

  if (item.prices) {
    Object.keys(item.prices).forEach(key => {
      const itemPrice = item.prices[key]
      if (islogged && itemPrice.segment === USER_SEGMENT_REGISTERED_USERS) {
        listPrice = itemPrice.listPrice
        offerPrice = itemPrice.offerPrice
      } else if (!islogged && itemPrice.segment === USER_SEGMENT_GUEST) {
        listPrice = itemPrice.listPrice
        offerPrice = itemPrice.offerPrice
      }
    })
  }

  const productContext: ProductForAnalyticsFields = {
    Case: '',
    Cloth: '',
    Frame: '',
    Lens: '',
    ModelCode: '',
    Size: '',
    Status: getProductStatus(item),
    OosOptions: '', // TODO
    Category: productCategory.toUpperCase(),
    Type: '', // TODO
    LensType: getLensType(item).toLowerCase() === 'non_prescription' ? 'PLANO' : 'RX',
    Price: padDecimals(offerPrice),
    PriceFull: padDecimals(listPrice),
    Brand: getBrand(item),
    Sku: `${item.productId}`,
    ModelName: getModelName(item),
    MoCo: '', // TODO
    LensColor: getLensesColor(item),
    LensTechnology: getLensesTreatment(item),
    FrameColor: getFrontColor(item),
    FrameTechnology: getFrameMaterial(item),
    Shape: getFrameShape(item),
    LensUPC: '',
    Visibility: 'Public',
    FrameType: getFrameType(item),
    Units: '1',
    PerkCode: '',
    InsuranceAmount: '',
    InsuranceCode: '',
    Warranty: '',
    TaxRate: '',
    CancelledUnits: '',
    CancelledAmount: '',
    Badges: '', // TODO
  }

  return productContext
}
export const getProductsForAnalytics = (products): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p) => {
    if (p.productId) {
      const type = getProductType(p)
      const id = type.toLowerCase() === ProductTypesEnum.ContactLenses.toLowerCase() ? p.objectID : p.productId

      acc[id] = {
        ...formatProduct(p),
      }
    }

    return acc
  }, {})
}

export const sendPlpEvent = (data: {
  common?: Partial<CommonData>
  qnt: number | undefined
  products: any
  pageSection: string
}): void => {
  const pageSectionUrls = data.pageSection.split('/')
  const Products = getProductsForAnalytics(data.products)
  const redirectAnalyticsData = localStorage.getItem(LOGIN_REDIRECT_EVENT)
  let userData = {}
  let loginStatus = 'Guest'
  if (redirectAnalyticsData) {
    const loginPropertiesFromStorage = JSON.parse(redirectAnalyticsData)
    const { id: ignoredId, event: ignoredEvent, ...restProperties } = loginPropertiesFromStorage
    localStorage.removeItem(LOGIN_REDIRECT_EVENT)
    userData = restProperties
    loginStatus = 'Logged'
  }

  const dataToSend: PLPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Plp',
    Page_Section1: pageSectionUrls[1] || '',
    Page_Section2: pageSectionUrls[2] || '',
    Search_ResultItemsQnt: !data.qnt ? undefined : `${data.qnt}`,
    Page_Design: 'Editorial',
    Products,
    User_LoginStatus: loginStatus,
    ...userData,
  }
  sendAnalyticEventRaw(dataToSend)
}

export const getProductStatus = (product: any): string => {
  const soldOutLabel = getSoldOut(product).toUpperCase()

  switch (soldOutLabel) {
    case '':
    case PRODUCT_SOLDOUT_STATUS.NONE: {
      return PRODUCT_STATUS.AVAILABLE
    }
    case PRODUCT_SOLDOUT_STATUS.SOLDOUT: {
      return PRODUCT_STATUS.SOLD_OUT
    }
    case PRODUCT_SOLDOUT_STATUS.COMING_SOON: {
      return PRODUCT_STATUS.COMING_SOON
    }
    default:
      return PRODUCT_STATUS.AVAILABLE
  }
}

export const storeAlgoliaEventParameters = ({
  type,
  ...args
}: {
  type?: string
  queryID?: any
  indexName?: string
  position?: number
  objectID?: string
  filters?: string[]
}) => {
  Object.keys(args).forEach(key => {
    sessionStorage.setItem([type, key].filter(Boolean).join('_'), args[key])
  })
}

export const getAlgoliaEventParameters = (
  keys: string[],
  type?: string | undefined
): { queryID: string | undefined; indexName: string | undefined } => {
  return keys.reduce(
    (acc, curr) => {
      acc[curr] = sessionStorage.getItem([type, curr].filter(Boolean).join('_'))
      return acc
    },
    { queryID: undefined, indexName: undefined, position: undefined }
  )
}

export const clearAlgoliaEventParameters = (keys: string[], type?: string | undefined): void => {
  return keys.forEach(key => {
    sessionStorage.removeItem([type, key].filter(Boolean).join('_'))
  })
}

const formatFacetsForAnalytics = (facets): string => {
  let facetsValue = ''
  Object.keys(facets).forEach((key, i, array) => {
    if (facets[key] && facets[key].length) {
      facets[key].forEach((facet, facetIndex) => {
        if (key !== SORT_BY) {
          facetsValue += `${key.split('.')[1]}=${facet}`
        } else {
          facetsValue += `${key}=${facet}`
        }
        if (i !== array.length - 1 || facets[key].length - 1 !== facetIndex) {
          facetsValue += '|'
        }
      })
    }
  })

  return facetsValue
}

export const sendFilterSelectedEvent = (filters: { [key: string]: string[] }, count?: number) => {
  const facetsValue = formatFacetsForAnalytics(filters)
  const dataToSend = {
    id: 'Event',
    Search_FacetValues_String: facetsValue.replaceAll(' ', ''),
    Search_ResultItemsQnt: count?.toString(),
    Events_SearchFiltering: '1',
  }

  sendAnalyticEventRaw(dataToSend)
}

export const getSuffixFromSortValue = ({
  groupedIndexName,
  ungroupedIndexName,
  sortValue,
  customerSegments,
}: {
  groupedIndexName: string
  ungroupedIndexName: string
  sortValue: string
  customerSegments: string[]
}): string => {
  const currentCustomerSegment = customerSegments && customerSegments[0]
  const pattern = new RegExp(
    `(${groupedIndexName}__${currentCustomerSegment}|${ungroupedIndexName}__${currentCustomerSegment})(.*)`
  )
  const match = sortValue.match(pattern)

  if (match && match[2]) {
    return `${SORT_BY_PRICE_STRING}${match[2]}`
  }
  return SORT_RECOMMENDED_FACET
}

export const removeFacetFromSelectedFacets = ({
  refinement,
  selectedFacets,
}: {
  refinement: Refinement
  selectedFacets: Record<string, string[]>
}) => {
  const { attribute, value } = refinement
  const facetIndex = selectedFacets?.[attribute]?.indexOf(value as string)

  if (facetIndex !== -1) {
    selectedFacets?.[attribute]?.splice(facetIndex, 1)
  }

  return selectedFacets
}

export const flatRefinementToSelectedFacets = (refinement: Refinement[]) => {
  const selectedFacets = {}
  refinement.forEach(facet => {
    const { attribute, value } = facet
    if (selectedFacets?.[attribute]) {
      selectedFacets[attribute] = [...selectedFacets[attribute], value]
    } else {
      selectedFacets[attribute] = [value]
    }
  })

  return selectedFacets
}

const formatAlgoliaProduct = (item: any, pageType: string, islogged?: boolean): Partial<ProductForAnalyticsFields> => {
  const type = getProductType(item)?.toUpperCase() || ''

  let offerPrice = 0
  let listPrice = 0
  const productCategory = ANALYTICS_PRODUCT_CATEGORY_MAP[type] || type
  if (item.prices) {
    Object.keys(item.prices).forEach(key => {
      const itemPrice = item.prices[key]
      if (
        (islogged &&
          (itemPrice.segment === USER_SEGMENT_REGISTERED_USERS || itemPrice.segment === USER_SEGMENT_GUEST)) ||
        (!islogged && itemPrice.segment === USER_SEGMENT_GUEST)
      ) {
        listPrice = itemPrice.listPrice
        offerPrice = itemPrice.offerPrice
      }
    })
  }

  let productContext: Partial<ProductForAnalyticsFields> = {
    Category: productCategory,
    LensType:
      pageType === ANALYTICS_PAGE_TYPE.PLP || getLensType(item).toLowerCase() === 'non_prescription'
        ? LENS_TYPE.PLANO
        : LENS_TYPE.RX,
    Price: padDecimals(offerPrice),
    PriceFull: padDecimals(listPrice),
    Brand: getBrand(item),
    Sku: `${item.productId}`,
    ModelName: getModelName(item),
    LensTechnology: getLensesTreatment(item),
    FrameTechnology: getFrameMaterial(item),
    Shape: getFrameShape(item),
    FrameType: '',
    Engraving: '',
  }

  return productContext
}

export const getAlgoliaProductsForAnalytics = (
  product: IAlgoliaHit,
  pageType: string,
  islogged?: boolean
): ProductForAnalytics => {
  if (product.partnumberId) {
    return {
      [product.partnumberId]: {
        ...formatAlgoliaProduct(product, pageType, islogged),
      },
    }
  }
  return {}
}

export const sendPLPAddToFavoritesEvent = (product: IAlgoliaHit, islogged?: boolean) => {
  const dataToSend = {
    id: TRACKER_ADDTOWISHLIST,
    Events_ProdFavAdd: '1',
    Products: getAlgoliaProductsForAnalytics(product, ANALYTICS_PAGE_TYPE.PLP, islogged),
  }
  sendAnalyticEventRaw(dataToSend)
}
