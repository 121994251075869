import {
  SubscriptionPayload,
  CartSubscriptionResponse,
  SubscriptionListRequestPayload,
  APIResponseBase,
  SubscriptionItem,
  SubscriptionRequestPayload,
  SubscriptionItemExtended,
  SubscriptionPostponePayload,
  SubscriptionPostponeResponse,
  SubscriptionFrequencyPayload,
  SubscriptionFulfillmentPayload,
  CancelSubscriptionPayload,
  ChangeSubscriptionStatusResponse,
  DeleteSubscriptionPayload,
  ActivateSubscriptionPayload,
  UpdateSubscriptionWalletPayload,
  SubscriptionAddressPayload,
  MarketingSpotData,
  SubscriptionDetails,
  SubscriptionData,
  SubscriptionStateToggleResponse,
  DeliveryFrequencyUpdateResponse,
  DeliveryDateUpdateResponse,
} from '@typesApp/productSubscription'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

import { parseRecurrencyString } from '@components/UI/ProductSubscriptionModule/productSubscriptionHelpers'

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  tagTypes: ['SubscriptionHistory'],
  endpoints: builder => ({
    activateRecurringOrSubscription: builder.mutation<ChangeSubscriptionStatusResponse, ActivateSubscriptionPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, fulfillmentInterval, fulfillmentIntervalUOM } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${orderId}/activate_recurring_or_subscription`,
          method: 'POST',
          body: {
            fulfillmentInterval,
            fulfillmentIntervalUOM,
          },
          queryParams: { storeId, orderId },
        })

        if (result?.data) {
          return {
            data: {
              resourceName: result.data.resourceName,
              state: result.state,
              subscriptionId: result.subscriptionId,
              subscriptionType: result.subscriptionType,
            },
          }
        }
        return { error: result.error }
      },
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : [
              { type: 'SubscriptionHistory', id: arg.subscriptionId },
              { type: 'SubscriptionHistory', id: `LIST-${arg.storeId}` },
            ]
      },
    }),
    reactivateSubscription: builder.query<
      SubscriptionStateToggleResponse,
      { storeId: string; subscriptionId: string; parentOrderId: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, parentOrderId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${parentOrderId}/activate_recurring_or_subscription`,
          method: 'POST',
          pathParams: { storeId, parentOrderId },
          extraParams: {
            skipServerErrorEvent: true,
          },
          body: {
            subscriptionId,
          },
        })

        return result.data ? { data: result.data as SubscriptionStateToggleResponse } : { error: result.error }
      },
    }),
    submitSubscriptionInfo: builder.mutation<
      {
        orderComplete: boolean
        orderId: string
        subscriptionId: string
      },
      SubscriptionPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderItemId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${orderItemId}/submit_recurring_or_subscription`,
          method: 'POST',
          body: {
            notifyOrderSubmitted: '1',
            notifyMerchant: '1',
            notifyShopper: '1',
            notify_EMailSender_recipient: '1',
          },
          queryParams: { storeId, orderItemId },
        })

        return result?.data
          ? {
              data: {
                orderComplete: true,
                orderId: orderItemId ?? '',
                subscriptionId: result.data?.subscriptionId ?? '',
              },
            }
          : {
              data: {
                orderComplete: false,
                orderId: '',
                subscriptionId: '',
              },
              error: result.error,
            }
      },
    }),
    updateSubscriptionInfo: builder.mutation<CartSubscriptionResponse, SubscriptionPayload>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, recurringFrequency, orderItemId } = args

        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/update_recurring_frequency`,
          method: 'PUT',
          body: {
            RecurringFrequency: recurringFrequency,
            orderItemId,
          },
          queryParams: { storeId },
        })

        if (result?.data) {
          return {
            data: {
              resourceName: result.data.resourceName,
              status: result.status,
              statusText: result.statusText,
              subscriptionInfo: {
                identifier: orderItemId ?? '',
                recurrency: parseRecurrencyString(recurringFrequency),
                active: !!recurringFrequency,
              },
            },
          }
        }
        return { error: result.error }
      },
    }),
    updateSubscriptionWallet: builder.mutation<CartSubscriptionResponse, UpdateSubscriptionWalletPayload>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, subscriptionId, body } = args

        const result = await fetchWithBQ({
          url: `/store/${storeId}/subscription/${subscriptionId}/wallet`,
          method: 'PUT',
          body,
          queryParams: { storeId, subscriptionId },
        })

        if (result?.data) {
          return {
            data: {
              resourceName: result.data.resourceName,
              status: result.status,
              statusText: result.statusText,
            },
          }
        }
        return { error: result.error }
      },
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : [
              { type: 'SubscriptionHistory', id: arg.subscriptionId },
              { type: 'SubscriptionHistory', id: `LIST-${arg.storeId}` },
            ]
      },
    }),
    deleteSubscriptionInfo: builder.mutation<CartSubscriptionResponse, SubscriptionPayload>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderItemId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/delete_recurring_frequency`,
          method: 'DELETE',
          body: {
            orderItemId,
          },
          queryParams: { storeId },
        })

        if (result?.data) {
          return {
            data: { resourceName: result.data.resourceName, status: result.status, statusText: result.statusText },
          }
        }
        return { error: result.error }
      },
    }),

    deleteRecurringOrSubscription: builder.mutation<ChangeSubscriptionStatusResponse, DeleteSubscriptionPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, subscriptionId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}`,
          method: 'DELETE',
          body: {},
          queryParams: { storeId, subscriptionId },
        })

        if (result?.data) {
          return {
            data: {
              resourceName: result.data.resourceName,
              state: result.state,
              subscriptionId: result.subscriptionId,
              subscriptionType: result.subscriptionType,
            },
          }
        }
        return { error: result.error }
      },
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : [
              { type: 'SubscriptionHistory', id: arg.subscriptionId },
              { type: 'SubscriptionHistory', id: `LIST-${arg.storeId}` },
            ]
      },
    }),
    getUserSubscriptions: builder.query<APIResponseBase<SubscriptionItem[]>, SubscriptionListRequestPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/@history`,
          method: 'GET',
          queryParams: { storeId },
        })
        if (result?.data) {
          return { data: result.data }
        }
        return { error: result.error }
      },
      providesTags: (_result, error, { storeId }) => {
        return error ? [] : [{ type: 'SubscriptionHistory', id: `LIST-${storeId}` }]
      },
    }),
    getUserSubscriptionDetails: builder.query<SubscriptionItemExtended, SubscriptionRequestPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, subscriptionId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}`,
          method: 'GET',
          queryParams: { storeId, subscriptionId },
        })
        if (result?.data) {
          const subscriptionData = { subscription: { ...result.data.resultList[0] }, x_data: { ...result.data.x_data } }
          return { data: subscriptionData }
        }
        return { error: result.error }
      },
      providesTags: (result, error, { subscriptionId }) => {
        return error ? [] : [{ type: 'SubscriptionHistory', id: subscriptionId }]
      },
    }),
    getSubscriptionsTermsAndConditions: builder.query<MarketingSpotData[], { storeId }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/espot/SubscriptionDiscountTermsAndCondition`,
          method: 'GET',
          queryParams: { storeId },
        })
        if (result?.data) {
          return { data: result.data.MarketingSpotData }
        }
        return { error: result.error }
      },
    }),
    postponeSubscription: builder.mutation<SubscriptionPostponeResponse, SubscriptionPostponePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, subscriptionId, periodsToPostpone = 1 } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}/postpone`,
          method: 'PUT',
          body: {
            periodsToPostpone,
          },
          queryParams: { storeId, subscriptionId },
        })

        if (result?.data) {
          return {
            data: result.data,
          }
        }
        return { error: result.error }
      },
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : [
              { type: 'SubscriptionHistory', id: arg.subscriptionId },
              { type: 'SubscriptionHistory', id: `LIST-${arg.storeId}` },
            ]
      },
    }),
    updateSubscriptionShippingDate: builder.mutation<DeliveryDateUpdateResponse, SubscriptionFulfillmentPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, subscriptionId, body } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}/nextfulfillmentdate`,
          method: 'PUT',
          body,
          queryParams: { storeId, subscriptionId },
        })

        return result.data ? { data: result.data as DeliveryDateUpdateResponse } : { error: result.error }
      },
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : [
              { type: 'SubscriptionHistory', id: arg.subscriptionId },
              { type: 'SubscriptionHistory', id: `LIST-${arg.storeId}` },
            ]
      },
    }),
    updateSubscriptionAddress: builder.mutation<unknown, SubscriptionAddressPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, subscriptionId, body } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}/address`,
          method: 'PUT',
          body,
          queryParams: { storeId, subscriptionId },
        })

        if (result?.data) {
          return {
            data: result.data,
          }
        }
        return { error: result.error }
      },
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : [
              { type: 'SubscriptionHistory', id: arg.subscriptionId },
              { type: 'SubscriptionHistory', id: `LIST-${arg.storeId}` },
            ]
      },
    }),
    getSubscriptionDetails: builder.query<SubscriptionDetails, { storeId: string; subscriptionId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}`,
          method: 'GET',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data as SubscriptionDetails } : { error: result.error }
      },
    }),
    getSubscriptionHistory: builder.query<SubscriptionData, { storeId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/@history`,
          method: 'GET',
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })
        return result.data ? { data: result.data as SubscriptionData } : { error: result.error }
      },
    }),
    cancelSubscription: builder.query<SubscriptionStateToggleResponse, { storeId: string; subscriptionId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}`,
          method: 'DELETE',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data as SubscriptionStateToggleResponse } : { error: result.error }
      },
    }),
    pauseSubscription: builder.query<
      SubscriptionStateToggleResponse,
      { storeId: string; subscriptionId: string; parentOrderId: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, parentOrderId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${parentOrderId}/cancel_recurring_or_subscription?subscriptionId=${subscriptionId}`,
          method: 'DELETE',
          pathParams: { storeId, parentOrderId },
          extraParams: {
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data as SubscriptionStateToggleResponse } : { error: result.error }
      },
    }),

    updateSubscriptionDeliveryFrequency: builder.query<
      DeliveryFrequencyUpdateResponse,
      { storeId: string; subscriptionId: string; fulfillmentInterval: string; fulfillmentIntervalUOM: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, fulfillmentInterval, fulfillmentIntervalUOM } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}/period`,
          method: 'PUT',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipServerErrorEvent: true,
          },
          body: {
            fulfillmentInterval,
            fulfillmentIntervalUOM,
          },
        })

        return result.data ? { data: result.data as DeliveryFrequencyUpdateResponse } : { error: result.error }
      },
    }),
  }),
})

export const {
  useActivateRecurringOrSubscriptionMutation,
  useDeleteSubscriptionInfoMutation,
  useDeleteRecurringOrSubscriptionMutation,
  useGetUserSubscriptionsQuery,
  useGetUserSubscriptionDetailsQuery,
  useGetSubscriptionsTermsAndConditionsQuery,
  useSubmitSubscriptionInfoMutation,
  useUpdateSubscriptionInfoMutation,
  usePostponeSubscriptionMutation,
  useUpdateSubscriptionShippingDateMutation,
  useUpdateSubscriptionWalletMutation,
  useUpdateSubscriptionAddressMutation,
  useLazyGetSubscriptionHistoryQuery,
  useGetSubscriptionHistoryQuery,
  useGetSubscriptionDetailsQuery,
  useLazyUpdateSubscriptionDeliveryFrequencyQuery,
} = subscriptionApi
