import { localStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import CurrencyService from '@services/CurrencyService'
import { PlacementCounter } from '@typesApp/common'
import { StoreConfigType } from '@typesApp/store'
import config from '@configs/index'
import { Redirect } from 'next/types'
import { EyeClFieldConfigMap } from '@typesApp/prescription'
import { IProduct } from '@typesApp/product'
import { isMtoProduct } from './product'
import {
  CONTACT_LENS_BASE_CURVE_COLUMN,
  CONTACT_LENS_DIAMETER_COLUMN,
  CONTACT_LENS_SPHERE_POWER_COLUMN,
} from '../constants/common'

const BASE_CURVE_POSITION = 0
const SPHERE_POWER_POSITION = 1
const DIAMETER_POSITION = 2
const LENS_RANGE_VALUES_SEPARATOR = ' '
const CL_LENS_RANGE = 'CL_LENS_RANGE'

/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
/**
 *
 */
export function executeOnce<Args extends unknown[], Return>(cb: (...args: Args) => Return, id: string) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const requiredValuesMissingInObject = (obj, values: string[]): boolean => {
  return Object.keys(obj).filter(key => values.includes(key) && !!obj[key]).length <= 0
}

export const notNull = <T>(x: T | null): x is T => x !== null
export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined

// create function using localeCompare to compare strings
export const areEquals = (a: string | undefined, b: string | undefined): boolean => {
  if (!a || !b) return false
  return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
}

export const stringToBoolean = (s?: string | boolean): boolean => {
  if (!s) return false
  if (typeof s === 'boolean') return s
  return s.toLowerCase() === 'true'
}

export const booleanToString = (b: boolean): string => {
  return `${b}`
}

type RedirectTo = {
  destination: string
  permanent?: boolean
}

export type RedirectReturn = {
  redirect: Redirect
}
export const redirectTo = ({ destination, permanent = false }: RedirectTo): RedirectReturn => {
  return {
    redirect: {
      permanent,
      destination,
    },
  }
}

export const openNewTab = (url: string, fileName?: string): void => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('target', '_blank')
  link.setAttribute('rel', 'noopener noreferrer')
  link.style.visibility = 'hidden'
  fileName && (link.download = fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getHeadersWithToken = () => {
  const currentUserSession = storageSessionHandler.getCurrentUserAndLoadAccount()
  return {
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    WCToken: currentUserSession?.WCToken,
    WCTrustedToken: currentUserSession?.WCTrustedToken,
  }
}

export const numberToMoney = (
  price: number | string | null,
  currency: string,
  defaultCurrency: string | null
): string | null => {
  // TODO: Replace with actual currency function when its done and merged
  if (!price) {
    return null
  }
  const currencySymbol = CurrencyService.getSymbolByName(currency)

  return currency === defaultCurrency
    ? `${Number(price).toFixed(2).replace('.', ',')} ${currencySymbol}`
    : `${currencySymbol} ${Number(price).toFixed(2)}`
}

/**
 * Sets the `aria-label` attribute of a given parent HTML element based on the text content
 * of its child nodes. The text content of all child nodes is concatenated and trimmed
 * to form the value of the `aria-label` attribute.
 *
 * @param parentElement - The parent HTML element whose `aria-label` attribute will be set.
 *                        If the element is null, the function does nothing.
 */
export const setAriaLabelFromChildren = (parentElement: HTMLElement | null): void => {
  if (parentElement) {
    const getTextContent = (node: ChildNode): string => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent?.trim() || ''
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        return Array.from(node.childNodes).map(getTextContent).join(' ').trim()
      }
      return ''
    }

    const childTextContent = Array.from(parentElement.childNodes)
      .map(getTextContent)
      .filter(text => text !== '')
      .join(' ')

    parentElement.setAttribute('aria-label', childTextContent)
  }
}

export const convertToPascalSnakeCase = (text = ''): string => {
  if (text.length === 0) return ''
  const words = text.includes('_') ? text.split('_') : text.split(' ')
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    })
    .join('_')
}

export const getStoresConfigForCountrySelector = (): StoreConfigType[] => {
  return [
    {
      countryCode: 'au',
      countryLanguage: 'en',
      storeId: '70202',
      locale: 'en_au',
    },
    {
      countryCode: 'ca',
      countryLanguage: 'en',
      storeId: '70201',
      locale: 'en_ca',
    },
    {
      countryCode: 'ca',
      countryLanguage: 'fr',
      storeId: '70201',
      locale: 'fr_ca',
    },
    {
      countryCode: 'nz',
      countryLanguage: 'en',
      storeId: '70203',
      locale: 'en_nz',
    },
    {
      countryCode: 'ww',
      countryLanguage: 'en',
      storeId: '70999',
      locale: 'en_ww',
    },
  ]
}

export const getDataElementId = (
  viewType: string | null,
  placementCounter?: PlacementCounter,
  type = 'IMG'
): string => {
  const placementIndex = placementCounter?.placementIndex ?? 0
  const tabIndex = placementCounter?.tabIndex ?? 0
  const tileIndex = placementCounter?.tileIndex ?? 0
  const teaserIndex = placementCounter?.teaserIndex ?? 0
  const actionIndex = placementCounter?.actionIndex ?? ''

  switch (viewType) {
    case 'grid-of-products':
    case 'cly-category-with-cta':
      return `${placementIndex}Placement_Tab${tabIndex}_Tile${tileIndex}_${type}`
    case 'boards-with-fields-below':
    case 'box-with-margin':
      return `${placementIndex}Placement_Tile${tileIndex}_${type}${actionIndex}`
    default:
      return `${placementIndex}Placement_Banner${teaserIndex}_${type}`
  }
}

export const trimText = (text: string, joinedBy = ''): string => {
  return !!text
    ? text
        .split(' ')
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
        })
        .join('')
        .toString() + joinedBy
    : ''
}

/**
 * To enable the feature via Storage, include the following item in the local Storage : HCL--isSoldOutFeatureEnabled
 * For the storage config, it will only affect the current session
 * @returns true if there is a flag to enabled it in the environment config or local Storage configuration
 */
export const isSoldOutFeatureEnabled = () => {
  const isEnabledViaConfig = config.isSoldOutFeatureEnabled
  const isEnabledViaLocalStorage = localStorageUtil.get('isSoldOutFeatureEnabled')

  return !!isEnabledViaConfig || !!isEnabledViaLocalStorage
}

export const uniqueId = ((): ((prefix: string) => string) => {
  let counter = 0
  return (prefix: string): string => `${prefix}${++counter}`
})()

/**
 * @param fieldId  x_diameter, x_baseCurve or x_spherePower
 * @param lensRangeValues This is recovered from the product attribute CL_LENS_RANGE
 * @param value used to identify that the range contains that field value
 * @returns only the ranges that contain the field/value combination will be returned
 */
const getFilteredValues = (fieldId: string, lensRangeValues: string[], value: string): { [key: string]: any[] } => {
  const fieldPosition =
    fieldId === CONTACT_LENS_SPHERE_POWER_COLUMN
      ? SPHERE_POWER_POSITION
      : fieldId === CONTACT_LENS_BASE_CURVE_COLUMN
        ? BASE_CURVE_POSITION
        : DIAMETER_POSITION
  const filteredValues = lensRangeValues.filter(val =>
    value ? val.split(LENS_RANGE_VALUES_SEPARATOR)[fieldPosition] === value : true
  )

  return {
    x_baseCurve: filteredValues.map(value => value.split(LENS_RANGE_VALUES_SEPARATOR)[BASE_CURVE_POSITION]),
    x_spherePower: filteredValues?.map(value => value.split(LENS_RANGE_VALUES_SEPARATOR)[SPHERE_POWER_POSITION]),
    x_diameter: filteredValues.map(value => value.split(LENS_RANGE_VALUES_SEPARATOR)[DIAMETER_POSITION]),
  }
}

const resetAllFieldOptions = (lensRangeValues, isMto: boolean, ...fields) => {
  if (lensRangeValues && fields?.length) {
    fields.forEach(field => {
      field.options.forEach((element, index) => {
        element.notAvailable =
          !isMto &&
          index > 0 &&
          getFilteredValues(field.id, lensRangeValues, element.text || element.value)[field.id].length === 0
      })
    })
  }
}

/**
 * Filters the data to be shown in the drop down select based on the product CL_LENS_RANGE attribute
 * @param contactLensFieldsConfig  the current product field configuration to be displayed
 * @param product  the curent Server product
 * @returns a enriched field config data marking all the fields that were not found in the CL_LENS_RANGE as disabled, so the customer cannot
 * mistakenly add an invalid combination to the cart
 */
export const loadValidProductLensRangeValues = (
  contactLensFieldsConfig: EyeClFieldConfigMap | null,
  product: IProduct
): EyeClFieldConfigMap | null => {
  const lensRangeValues = product.productAttributes[CL_LENS_RANGE]
  if (lensRangeValues && contactLensFieldsConfig) {
    const eyes = ['left', 'right']
    const isMto = isMtoProduct(product)

    eyes.forEach(eye => {
      const diameterField = contactLensFieldsConfig[eye].find(item => item.id === CONTACT_LENS_DIAMETER_COLUMN)
      const baseCurveField = contactLensFieldsConfig[eye].find(item => item.id === CONTACT_LENS_BASE_CURVE_COLUMN)
      const powerSphereField = contactLensFieldsConfig[eye].find(item => item.id === CONTACT_LENS_SPHERE_POWER_COLUMN)
      resetAllFieldOptions(lensRangeValues, isMto, diameterField, baseCurveField, powerSphereField)
    })
  }

  return contactLensFieldsConfig
}

export const isEarlyAccesFeatureEnabled = () => {
  return config.isEarlyAccessFeatureEnabled
}

export const isEarlyAccessFeatureNewsLetterEnabled = () => {
  return config.isEarlyAccessFeatureEnabled && config.isEarlyAccessFeatureNewsLetterEnabled
}
