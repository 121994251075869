import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAmazonPay = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 40 26" {...other}>
      <path
        d="M0 3.33333C0 1.49239 1.49238 0 3.33333 0H36.6667C38.5076 0 40 1.49238 40 3.33333V22.6667C40 24.5076 38.5076 26 36.6667 26H3.33333C1.49238 26 0 24.5076 0 22.6667V3.33333Z"
        fill="#232F3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0378 18.0776C28.1964 20.1694 24.0774 21.2816 20.53 21.2816C15.5586 21.2816 11.0819 19.4484 7.6945 16.3972C7.42858 16.1575 7.66548 15.8303 7.98539 16.0162C11.6401 18.1376 16.16 19.4154 20.8284 19.4154C23.9775 19.4154 27.4392 18.7637 30.6248 17.4158C31.1046 17.2118 31.5075 17.7309 31.0378 18.0776ZM32.2196 16.7311C32.5834 17.1957 31.8153 19.1084 31.4725 19.9627C31.3685 20.2213 31.5912 20.3256 31.8261 20.1297C33.3501 18.8579 33.7442 16.1925 33.4324 15.8075C33.1226 15.4257 30.4581 15.0972 28.8315 16.2363C28.5811 16.4114 28.6243 16.6537 28.9017 16.6201C29.8176 16.511 31.8565 16.2666 32.2196 16.7311Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.106 13.3064L31.0635 5.79766C31.1256 5.64551 31.1573 5.53846 31.1573 5.47585C31.1573 5.3688 31.0945 5.31494 30.969 5.31494H30.2164C30.0727 5.31494 29.9748 5.33783 29.9202 5.38227C29.8662 5.42737 29.8128 5.52567 29.7589 5.67782L27.944 10.88L26.0623 5.67782C26.0083 5.52567 25.955 5.42737 25.901 5.38227C25.847 5.33783 25.7485 5.31494 25.6047 5.31494H24.7982C24.6727 5.31494 24.6099 5.3688 24.6099 5.47585C24.6099 5.53846 24.641 5.64551 24.7037 5.79766L27.2981 12.1801L27.043 12.8641C26.8905 13.2936 26.7204 13.5885 26.5321 13.7494C26.3438 13.9103 26.0792 13.9904 25.7391 13.9904C25.5865 13.9904 25.4657 13.981 25.3766 13.9635C25.2869 13.946 25.2194 13.9366 25.1748 13.9366C25.0405 13.9366 24.973 14.0214 24.973 14.1911V14.5398C24.973 14.665 24.9953 14.7566 25.0405 14.8145C25.0851 14.8724 25.1566 14.9155 25.2558 14.9417C25.4792 15.0037 25.735 15.036 26.0218 15.036C26.5328 15.036 26.9472 14.902 27.265 14.6334C27.5843 14.3661 27.8644 13.9231 28.106 13.3064Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0657 7.16026C16.9354 6.72669 16.7498 6.35775 16.5076 6.05411C16.2659 5.75047 15.9703 5.51753 15.6207 5.35662C15.2718 5.19572 14.8817 5.1156 14.4511 5.1156C14.0482 5.1156 13.6533 5.191 13.2686 5.34316C12.8833 5.49531 12.5201 5.71883 12.1793 6.01372L12.1125 5.58418C12.0855 5.40577 11.9822 5.31623 11.8027 5.31623H11.2115C11.0326 5.31623 10.9429 5.40577 10.9429 5.58418V14.6084C10.9429 14.7875 11.0326 14.8764 11.2115 14.8764H12.0045C12.184 14.8764 12.2731 14.7868 12.2731 14.6084V11.4711C12.8556 11.9982 13.5501 12.2621 14.3566 12.2621C14.7953 12.2621 15.1942 12.1746 15.5525 12.0009C15.9109 11.8266 16.2153 11.5828 16.4664 11.2698C16.7174 10.9574 16.9125 10.5777 17.0509 10.13C17.1899 9.68293 17.2594 9.18203 17.2594 8.62794C17.2608 8.08328 17.1953 7.59383 17.0657 7.16026ZM14.0482 11.189C13.438 11.189 12.8468 10.9749 12.2738 10.5454V6.80478C12.838 6.39343 13.4387 6.18809 14.0752 6.18809C15.2941 6.18809 15.9035 7.02426 15.9035 8.69527C15.9028 10.3582 15.2846 11.189 14.0482 11.189Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1271 5.64207C22.7235 5.294 22.0965 5.11963 21.2455 5.11963C20.8243 5.11963 20.4032 5.158 19.982 5.23341C19.5602 5.30949 19.2065 5.4098 18.9197 5.53502C18.8124 5.58013 18.7402 5.62659 18.7044 5.67573C18.6686 5.72488 18.6504 5.8124 18.6504 5.93695V6.29849C18.6504 6.4594 18.7084 6.54019 18.8252 6.54019C18.861 6.54019 18.8994 6.53345 18.9399 6.51999C18.9804 6.50652 19.0142 6.49575 19.0405 6.48633C19.7309 6.28098 20.389 6.17798 21.0167 6.17798C21.5539 6.17798 21.9305 6.27627 22.1458 6.47353C22.3611 6.67012 22.4684 7.00944 22.4684 7.49216V8.37681C21.8414 8.22466 21.2765 8.14925 20.7744 8.14925C19.9861 8.14925 19.3591 8.34382 18.8927 8.73229C18.427 9.12075 18.1935 9.64185 18.1935 10.2942C18.1935 10.9028 18.3818 11.3869 18.7584 11.7491C19.1343 12.1113 19.6452 12.2918 20.2904 12.2918C20.6758 12.2918 21.0632 12.2157 21.4533 12.0642C21.8434 11.9127 22.1991 11.6973 22.5217 11.4206L22.5892 11.8629C22.6162 12.0326 22.7147 12.1174 22.8855 12.1174H23.4632C23.6421 12.1174 23.7325 12.0278 23.7325 11.8494V7.2646C23.7318 6.53143 23.53 5.99081 23.1271 5.64207ZM22.4684 10.6039C22.1728 10.8274 21.8637 10.9971 21.5411 11.1136C21.2185 11.23 20.9093 11.2879 20.6137 11.2879C20.2641 11.2879 19.9955 11.1964 19.8072 11.0133C19.6189 10.8301 19.5251 10.5689 19.5251 10.2289C19.5251 9.45132 20.0313 9.06218 21.0436 9.06218C21.2765 9.06218 21.5161 9.07834 21.7624 9.10931C22.0088 9.14095 22.2443 9.18336 22.4684 9.23655V10.6039Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default SvgAmazonPay
