//Standard libraries
import { AnyAction, createSlice } from '@reduxjs/toolkit'
//Redux
// @ts-ignore
import {
  LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION,
  SET_SUBSCRIPTION_CONFIG_ACTION,
  SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION,
} from '@redux/actions/subscription'
import { ProductSubscriptionReducerState } from './reducerStateInterface'

export const productSubscriptionInitialState: ProductSubscriptionReducerState = {
  enabled: false,
  frequencyUnit: 'MON',
  frequencyOptions: [],
  frequencyDefault: '1',
}

/**
 * Product Subscription reducer
 * handles states used by product subscriptions related components
 * @param state State object managed by the product subscription reducer
 * @param action The dispatched action
 */

const productSubscriptionSlice = createSlice({
  name: 'productSubscription',
  initialState: productSubscriptionInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION,
      (state: ProductSubscriptionReducerState | any, action: AnyAction) => {
        const {
          enabled,
          allowedProducts,
          showOnCartRecap,
          showOnPdp,
          showOnOnePageCheckout,
          frequencyUnit,
          frequencyOptions,
          frequencyDefault,
          initialOrderTypeValue,
        } = action.payload

        Object.assign(state, {
          ...state,
          enabled,
          allowedProducts,
          showOnCartRecap,
          showOnPdp,
          showOnOnePageCheckout,
          frequencyUnit,
          frequencyOptions,
          frequencyDefault,
          initialOrderTypeValue,
        })
      }
    )
    builder.addCase(
      SET_SUBSCRIPTION_CONFIG_ACTION,
      (state: ProductSubscriptionReducerState | any, action: AnyAction) => {
        const updatedItem = action.payload.item
        const allItems = state.subscribedItems || []
        const currentItems = allItems.filter(item => item.identifier !== updatedItem.identifier)
        currentItems.push({
          identifier: updatedItem.identifier,
          recurrency: updatedItem.recurrency,
          active: updatedItem.active,
        })

        Object.assign(state, {
          ...state,
          subscribedItems: currentItems,
        })
      }
    )
    builder.addCase(
      SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION,
      (state: ProductSubscriptionReducerState | any, action: AnyAction) => {
        Object.assign(state, {
          ...state,
          lastSubscribedOrderId: action.payload,
          subscribedItems: [],
        })
      }
    )
  },
})

export default productSubscriptionSlice.reducer
