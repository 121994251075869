import config from '../../configs'
import { ITeaserTargets } from '../../types/cmsPlacement/LXTeaser'

export const controlTeaserTarget = (teaserTargets?: ITeaserTargets[]) => {
  const teaserTarget = teaserTargets?.find(teaser => teaser) || ({} as ITeaserTargets)
  const type = (teaserTarget?.target?.type || '') as string
  const fullyQualifiedUrl = (teaserTarget?.target?.fullyQualifiedUrl || '') as string

  switch (type) {
    case 'do-not-sell-my-personal-information':
      return 'javascript:utag.gdpr.showConsentPreferences()'
    case 'CMDownload':
      return `${config.cmsImageServerUrl}${fullyQualifiedUrl}`
    default:
      return ''
  }
}

export const sanitizePageId = (pageId: string): string => {
  /* filter out any query params from the page url */
  return decodeURIComponent(pageId)?.split('?')?.[0] ?? ''
}
