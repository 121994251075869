import config from '@configs/index'
import { getSite } from '@foundation/hooks/useSite'
import Head from 'next/head'

export const MainHead: React.FC<{ hostnameUrls: Record<string, string>; locationOrigin: string }> = ({
  hostnameUrls,
  locationOrigin,
}) => {
  const site = getSite()
  const productImagePreconnect = site?.xStoreCfg?.['damDomain'] || config.defaultDamDomain
  const cmsPreconnect = hostnameUrls?.cmsPreconnect ?? config.cmsImageServerUrlPreconnect
  const transactionHost = hostnameUrls?.transactionHost ?? config.transactionHost

  const brand = config.name || site?.xStoreCfg?.brand?.toLowerCase()

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1, width=device-width, maximum-scale=1" />
      <meta charSet="utf-8" />
      <link rel="preconnect" href={cmsPreconnect} />
      <link rel="preconnect" href={transactionHost} />
      <link rel="preconnect" href={productImagePreconnect} />
      <link rel="preconnect" href="//tags.tiqcdn.com" />

      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link rel="shortcut icon" href={`${locationOrigin}/images/${brand}/icons/favicon.ico`} type="image/x-icon" />
      <link rel="apple-touch-icon" href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon.png`} />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${locationOrigin}/images/${brand}/icons/apple-touch-icon-180x180.png`}
      />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  )
}
