export const BASE_COUNTRY_KEY = 'country'
export const BASE = '/'
export const HOME = '/'
export const CMSPREVIEW = '/cmpreview'
export const NOT_FOUND = '/not-found-page'
export const INTERNAL_ERROR = '/internal-error'
export const PLAYGROUND = '/playground'

//Order
export const CART = 'cart'
export const WISHLIST = 'wishlist'
export const PRESCRIPTION_VERIFICATION = 'checkout'
export const STORELOCATOR = 'store-locator'
export const STORELOCATOR_FR = 'boutiques'
export const BOOKAPPOINTMENT = 'prenota-appuntamento'
export const FAQ = 'c/customer-service/help'
export const FRENCH_CA_LOCALE = 'fr-ca'

//Reminder
export const REMINDER = '/reminder'

//Account
export const SIGNIN = 'sign-in'
export const SIGNUP = 'sign-up'
export const FORGOT_PASSWORD = 'forgot-password'
export const REGISTRATION_CONFIRMATION = 'registration-confirmation'
export const ACCOUNT = 'account'
export const ACCOUNT_CHILDREN = {
  DASHBOARD: 'dashboard',
  ADDRESS_BOOK: 'address-book',
  ORDER_HISTORY: 'order-history',
  PERSONAL_INFORMATION: 'personal-information',
  SETTINGS: 'settings',
  PAYMENT_METHODS: 'payment-methods',
  STORES: 'stores',
  FAVOURITES: 'wishlist',
  ORDER_REMINDER: 'order-reminder',
  ADDRESS_ADD: 'address-add',
  SUBSCRIPTIONS: 'subscriptions',
  SUBSCRIPTION_ID: 'subscriptions/:id',
}
//Checkout
export const CHECKOUT = 'checkout'
export const CHECKOUT_CHILDREN = {
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
  ORDER_CONFIRMATION: 'order-confirmation',
  UPLOAD_PRESCRIPTION: 'upload-prescription',
  REORDER_SUMMARY: 'reorder-summary',
  POST_CHECKOUT: 'post-checkout',
  REVIEW: 'review',
  FINALIZE: 'finalize',
}

export const CHECKOUT_SHIPPING = `${CHECKOUT}/${CHECKOUT_CHILDREN.SHIPPING}`
export const CHECKOUT_PAYMENT = `${CHECKOUT}/${CHECKOUT_CHILDREN.PAYMENT}`
export const CHECKOUT_REVIEW = `${CHECKOUT}/${CHECKOUT_CHILDREN.REVIEW}`
export const CHECKOUT_REORDER_SUMMARY = `${CHECKOUT}/${CHECKOUT_CHILDREN.REORDER_SUMMARY}`
export const ORDER_CONFIRMATION = `${CHECKOUT}/${CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`
export const CHECKOUT_UPLOAD_PRESCRIPTION = `${CHECKOUT}/${CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}`

export const CHECKOUT_DIRECT_BILLING = `${CHECKOUT}/${CHECKOUT_CHILDREN.FINALIZE}/direct-billing`

// //User
// export const CHANGE_PASSWORD = buildAccountPageRoute(ACCOUNT_CHILDREN.CHANGE_PASSWORD)

//Search
export const SEARCH = '/search'

//Services
export const SITEMAP = 'sitemap'
export const CAREERS = 'lavora-con-noi'

//register user access only
export const REGISTER_PROTECTED = 'register'
export const REGISTER_PROTECTED_ROUTES = ['account', 'registration-confirmation']
//only guest and generic user access
export const GENERIC_PROTECTED = 'generic'

export const CMS_PAGE_NAME_LV1 = '/:pageNameLv1'
export const CMS_PAGE_NAME_LV2 = '/:pageNameLv2'
export const CMS_PAGE_NAME_LV3 = '/:pageNameLv3'
export const CMS_PAGE_NAME_LV4 = '/:pageNameLv4'
//export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}${CMS_PAGE_NAME_LV2}${CMS_PAGE_NAME_LV3}${CMS_PAGE_NAME_LV4}${CMS_PAGE_NAME_LV5}`
export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}`

// RETURNS
export const ORDER_RETURN = 'return'
export const ORDER_RETURN_SELECT = 'select'
export const ORDER_RETURN_CONFIRMATION = 'confirmation'
export const ORDER_RETURN_PRINT = 'print'

//CUSTOMER SERVICE PAGE
export const CUSTOMER_SERVICES_CONTACT_US = 'c/customer-service/contact-us'

//NEWSLETTER SUBSCRIPTION LANDING PAGES
export const NEWSLETTER_SUBSCRIPTION_LP_EN = '/c/subscribe'
export const NEWSLETTER_SUBSCRIPTION_LP_FR = '/c/abonner'
