import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootReducerState } from '@redux/reducers'
import { roxApi } from './roxApi'

export interface RoxState {
  openRox?: boolean
  isLoading?: boolean
}

const initialState: RoxState = {
  openRox: false,
  isLoading: false,
}

export const roxSliceInitialState = (): RoxState => initialState

export const roxSlice = createSlice({
  name: 'rox',
  initialState,
  reducers: {
    setPrescriptionLensFormOpen: (state, action: PayloadAction<boolean>) => {
      state.openRox = action.payload
    },
    clearPrescriptionLens: () => initialState,
  },
  extraReducers: builder => {
    builder.addMatcher(roxApi.endpoints.roxLensData.matchPending, state => {
      state.isLoading = true
    })
    builder.addMatcher(roxApi.endpoints.roxLensData.matchFulfilled, state => {
      state.isLoading = false
    })
  },
})

export const { setPrescriptionLensFormOpen, clearPrescriptionLens } = roxSlice.actions

export const prescriptionLensFormOpenSelector = (state: RootReducerState): boolean => !!state.rox?.openRox

export const prescriptionLensDataLoadingSelector = (state: RootReducerState): boolean => !!state.rox?.isLoading
