import {
  LOGIN_REDIRECT_EVENT,
  NEW_REGISTRATION_REDIRECT_EVENT,
  TRACKER_MINICART_OVERLAY_OPEN,
  TRACKER_MOUSE_CLICK,
  TRACKER_NEWSLETTER_SIGNUP,
  TRACKER_PROMOCODE_TOGGLE,
} from './constants/tracker'
import { CART } from '@constants/routes'
import { TFunction } from 'i18next'

import {
  getOrderDiscountCodes,
  getOrderDiscountNames,
  getOrderPaymentType,
  getOrderState,
  getOrderTotalPromoCodeDiscount,
  getOrderZipCode,
  getProductsForAnalytics,
  getProductsForTYPAnalytics,
} from './formatters/productFormatter'
import {
  AccountEventData,
  CartData,
  CommonData,
  CountrySelectionData,
  DataToSend,
  DeliveryData,
  ErrorForAnalytics,
  InsuranceEventPayload,
  LoginData,
  NotFoundData,
  OrderReviewData,
  PDPData,
  PLPData,
  PaymentData,
  ProductUpdateData,
  SearchPageData,
  TYPData,
  VirtualPageData,
} from './interfaces'

import { algoliaEventTrigger } from '@foundation/algolia/algoliaConfig'
import { getUserToken } from '@foundation/hooks/useAnalyticsData'
import { FrameGeniusType } from '@hooks/useFrameGenius'
import { getProductType } from '@utils/productAttributes'
import { AxiosError } from 'axios'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import Log from '../../../services/Log'
import { IOrderDetails, ORDER_TYPE_CODE } from '../../../types/order'
import { IAlgoliaHit, IProduct, ProductAnalyticsRX } from '../../../types/product'
import SizeAdvisorUtil from '../../../utils/FrameGenius/SizeAdvisorUtil'
import { getFormattedError } from './formatters/formatError'
import { UserDetails } from '@typesApp/user'
import {
  ORDER_HISTORY_ORDERS_PER_PAGE,
  USER_SEGMENT_GUEST,
  USER_SEGMENT_LOGGED,
  USER_SEGMENT_REGISTERED_USERS,
} from '@constants/common'
import { getRedirectAnalyticsData } from './helpers'
import orderService from '@foundation/apis/transaction/order.service'
import { USER_TYPE } from './types'
import { isActiveOrderStatus } from '@utils/orderStatus'
import { capitalizeFirstLetter } from '@components/OrderSummary/helpers/terms'
import config from '@configs/index'
import { ExpressOption, ORDER_PAYMENT_TYPE } from '@constants/paymentMethods'
import { sessionStorageUtil } from '@foundation/utils/storageUtil'

export const ANALYTICS_IS_REORDER = 'analytics_is_reorder'

export const getUserDetailsForAnalytics = (loginStatus: boolean, userDetails?: UserDetails | null) => {
  const formattedEmail = userDetails?.logonId?.toLowerCase() || userDetails?.email1?.toLowerCase()
  let phone = userDetails?.phone1

  if (!phone && Array.isArray(userDetails?.contact)) {
    phone = userDetails?.contact[0]?.phone1?.trim()
  }

  const customerSegments = userDetails?.x_data?.customerSegments
  const userSegments = customerSegments && customerSegments.length ? { User_Segments: customerSegments.join(', ') } : {}
  return {
    User_Email_MD5: loginStatus ? md5(formattedEmail).toString() : '',
    User_Email_SHA256: loginStatus ? sha256(formattedEmail).toString() : '',
    User_LoginType: 'Standard',
    User_LoyaltyCard: '-',
    User_LoginStatus: loginStatus ? USER_SEGMENT_LOGGED : USER_SEGMENT_GUEST,
    User_Country: userDetails?.country?.toUpperCase() || userDetails?.preferredLanguage?.substring(3) || '',
    User_Id: userDetails?.userId,
    User_Phone_MD5: phone ? md5(phone).toString() : '',
    User_Phone_SHA256: phone ? sha256(phone).toString() : '',
    ...userSegments,
  }
}

export function sendAnalyticEventRaw<D extends { id: string }>(data: D, sessionId?: D) {
  try {
    const tealiumPayload = sessionId
      ? {
          Session_Id: sessionId,
          ...data,
        }
      : data
    window?.tealium_data2track && window.tealium_data2track.push(tealiumPayload)
  } catch (e) {
    Log.info('error')
  }
}

const sendAnalyticEvent = sendAnalyticEventRaw

export const sendVirtualPageEvent = (data): void => {
  const dataToSend: VirtualPageData = {
    id: 'VirtualPage-View',
    ...data,
  }
  sendAnalyticEvent<VirtualPageData>(dataToSend)
}

export const sendLoadingReadyEvent = (pageType: string, pageSection: string): void => {
  // see tealium documentation, this event overwrites
  // the first page load event and aborts previous events
  // TODO refactor to use sendAnalitycEvent
  Log.info(`ANALYTICS - send page ready pagetype: ${pageType} pageSection: ${pageSection}`)
  let obj
  try {
    obj = {
      id: 'Loading-Ready',
      Page_Type: pageType, // include data object properties listed in Data Layer
      Page_Section_1: pageSection,
    }
  } catch (err) {
    obj = {
      id: 'Loading-Error',
      Error_Source: 'Server',
      Error_Code: 'utag_data object',
      Error_Detail: 'Error setting ',
    }
  } finally {
    window.tealium_data2track && window.tealium_data2track.push(obj)
  }
  Log.info('ANALYTICS - Loading Ready Event Sent')
}

export const sendServerErrorEvent = (error: AxiosError): void => {
  const dataToSend: ErrorForAnalytics = getFormattedError(error)
  sendAnalyticEvent<ErrorForAnalytics>(dataToSend)
}

type NotFoundErrorData = {
  common: CommonData
  loginStatus: boolean
  requestedUrl: string
}
export const sendNotFoundErrorEvent = ({ common, loginStatus, requestedUrl }: NotFoundErrorData) => {
  const dataToSend: NotFoundData = {
    id: 'Event',
    User_LoginStatus: loginStatus ? 'Logged' : 'Guest',
    Page_Type: 'Error',
    Page_Section1: 'Other',
    Page_Section2: 'ErrorHttp404',
    User_LoginType: 'Standard',
    Error_Source: '404',
    Error_Code: '404 - page not found',
    Error_Details: requestedUrl,
    Events_Error: '1',
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendLoginEvent = (userDetails: UserDetails) => {
  const dataToSend: LoginData = {
    id: 'Event',
    Events_UserLogin: '1',
    ...getUserDetailsForAnalytics(true, userDetails),
  }

  localStorage.setItem(LOGIN_REDIRECT_EVENT, JSON.stringify({ ...dataToSend }))
}

export const sendLoginErrorEvent = (translation: TFunction, passwordResetRequired: boolean = false) => {
  Log.info('Login - submit error event')

  const errorDetails = 'Login Request Failed'
  const errorMessage = translation(
    !passwordResetRequired ? 'SignInPage.Msgs.ErrorLogin' : 'ResetPassword.UpdatedOurWebsite'
  )

  const dataToSend = {
    id: 'Event',
    Events_Error: '1',
    Error_Source: 'Server',
    Error_Code: 'Login',
    Error_Message: errorMessage,
    Error_Details: errorDetails,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendRegisterErrorEvent = (translation: TFunction) => {
  Log.info('Signup - submit error event')

  const errorDetails = 'Sign up Request Failed'
  const errorMessage = translation('SignInPage.Msgs.ErrorLogin')

  const dataToSend = {
    id: 'Event',
    Events_Error: '1',
    Error_Source: 'Server',
    Error_Code: 'Signup',
    Error_Message: errorMessage,
    Error_Details: errorDetails,
  }

  sendAnalyticEvent(dataToSend)
}

type AccountEventPayload = {
  common: CommonData
  email?: string
  userId?: string
  Page_Section1?: string
  Page_Section2?: string
  Page_Section3?: string
}
export const sendMyAccountEvent = ({
  common,
  email,
  userId,
  Page_Section1,
  Page_Section2,
  Page_Section3,
}: AccountEventPayload) => {
  const dataToSend: AccountEventData = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Static',
    Page_Section1: 'Account',
    Page_Section2: Page_Section2 ?? 'Static',
    Page_Section3: Page_Section3 ?? '',
    User_LoginStatus: 'Logged',
    User_LoginType: 'Standard',
    User_Email_MD5: md5(email).toString(),
    User_Email_SHA256: sha256(email).toString(),
    User_EmailOptin: '0',
    User_Id: userId,
  }

  const redirectData = getRedirectAnalyticsData()

  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendPdpEvent = (data: {
  common: CommonData
  userDetails?: UserDetails | null
  products: IProduct[]
  pageSection1: string
  pageSection2: string
  vmIsSupported: string
  grouped_queryID: string | null
  ungrouped_queryID: string | null
  grouped_indexName: string | null
  ungrouped_indexName: string | null
  position: string | null
  ungrouped_position: string | null
  objectID: string | null
  filters: (string | null)[] | []
  customerSegments
}): void => {
  const Products = getProductsForAnalytics(data.products, data.customerSegments)
  /*  const products = {
    [pdpData.id]: formatProduct(pdpData)
  }*/
  const userData = data.userDetails ? getUserDetailsForAnalytics(true, data.userDetails) : {}

  const dataToSend: PDPData = {
    ...data.common,
    id: 'VirtualPage-View',
    User_LoginStatus: data.userDetails ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Type: 'Pdp',
    Page_Section1: data.pageSection1,
    Page_Section2: data.pageSection2,
    Vm_IsUpcSupported: data.vmIsSupported,
    Products,
    grouped_queryID: data.grouped_queryID,
    ungrouped_queryID: data.ungrouped_queryID,
    position: data.position,
    grouped_indexName: data.grouped_indexName,
    ungrouped_indexName: data.ungrouped_indexName,
    ungrouped_position: data.ungrouped_position,
    objectIDs: [data.objectID],
    filters: data.filters,
    ...userData,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

// TODO product got from addToCartEvent don't have prices
export const sendAddToCartEvent = (products: IProduct[]) => {
  const indexes = ['grouped', 'ungrouped']
  indexes.forEach(index => {
    algoliaEventTrigger('sendEvents', [
      {
        eventType: 'conversion',
        eventName: 'AddToCart',
        index: sessionStorage.getItem(index + '_indexName') || '',
        userToken: window.utag_data.User_Email_MD5 || getUserToken(),
        queryID: sessionStorage.getItem(index + '_queryID') || '',
        objectIDs: [products[0].partNumber || ''],
      },
    ])
  })
}

export const sendCountrySelectionEvent = (countryCode: string) => {
  const dataToSend: CountrySelectionData = {
    id: 'Click',
    countryCode,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendCartEvent = (data: {
  common?: Partial<CommonData>
  products: ProductAnalyticsRX[]
  customerSegments: string[]
  orderId?: string
  orderSubtotal?: string
  orderTotal?: string
  productsAmount?: string
  userDetails?: UserDetails | null
}): void => {
  const products = getProductsForAnalytics(
    data.products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service'),
    data.customerSegments
  )

  const userData = data.userDetails ? getUserDetailsForAnalytics(true, data.userDetails) : {}

  let dataToSend: CartData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'CartPage',
    User_LoginStatus: data.userDetails ? 'Logged' : 'Guest',
    Page_Section1: 'CartPage',
    Products: products,
    Order_CartId: data.orderId ?? '',
    Order_Subtotal: data.orderSubtotal ?? '',
    Order_Total: data.orderTotal ?? '',
    Order_ProductsAmount: data.productsAmount ?? '',
    ...userData,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendAddToCartClickEvent = (productsData: IProduct[], customerSegments?: string[]): void => {
  Log.info('ANALYTICS - Add to cart event')
  const products = getProductsForAnalytics(productsData, customerSegments || [])

  const dataToSend = {
    id: 'Event',
    Events_CartAdd: '1',
    Products: products,
    Store_Search_StoreId: productsData[0]?.storeID,
  }
  sendAnalyticEvent(dataToSend)
}

interface CouponSubmitPayload extends Partial<CommonData> {
  result: boolean
  Error_Source?: string
  Error_Code?: string
  Error_Message?: string
}

export const sendCouponSubmitEvent = ({
  result,
  Order_DiscountCode,
  Order_DiscountName,
  Error_Code,
  Error_Message,
  Error_Source,
}: CouponSubmitPayload): void => {
  const dataToSend: DataToSend = {
    id: 'Event',
    Events_Error: '1',
    Order_DiscountCode,
    Order_DiscountName,
    ...(result
      ? { Events_DiscountApplied: '1' }
      : {
          Events_DiscountTentative: '1',
          Error_Source,
          Error_Code,
          Error_Message,
        }),
  }

  sendAnalyticEvent(dataToSend)
}

export const sendDeliveryEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: IProduct[]
  shipMode?: string
}): void => {
  const dataToSend: DeliveryData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Delivery',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Order_ShippingMode: data.shipMode,
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Products: getProductsForAnalytics(
      data.products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service')
    ),
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendTYPData = async (data: {
  common?: Partial<CommonData>
  products: IProduct[]
  userDetails?: UserDetails
  userType?: USER_TYPE
  orderDetails: IOrderDetails
  shipMode?: string
  loginStatus: boolean
}): Promise<void> => {
  const Products = getProductsForTYPAnalytics(data.products, data.orderDetails)
  const userData = data?.userDetails ? getUserDetailsForAnalytics(data.loginStatus, data.userDetails) : {}
  const isSubscriptionOrder = data.orderDetails.orderTypeCode === ORDER_TYPE_CODE.RECURRING
  const subscriptionFrequency = data.orderDetails.orderItem[0]?.orderItemExtendAttribute.find(
    attr => attr.attributeName === 'RecurringFrequency'
  )
  const parsedSubscriptionFrequency = subscriptionFrequency?.attributeValue?.split('|')?.[0] ?? '0'
  const orderSubscriptionFrequency = isSubscriptionOrder ? parsedSubscriptionFrequency : '0'

  // For TYP cart discounts aren't available, so we take them from orderDetails
  const orderDetailDiscountNames = getOrderDiscountNames(data.orderDetails)
  const orderDiscountNames = formatOrderDiscountNames(orderDetailDiscountNames)
  const orderDiscountCodes = getOrderDiscountCodes(data.orderDetails)

  const paymentType = getOrderPaymentType(data.orderDetails)
  const isExpressPayment = Object.values(ORDER_PAYMENT_TYPE.EXPRESS_OPTION).includes(paymentType as ExpressOption)

  const dataToSend: TYPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Thankyou',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    Page_Section1: 'Checkout',
    Page_Section2: isExpressPayment ? 'Express' : 'Standard',
    Products,
    user_type: data?.userType,
    User_EmailOptin: '0',
    Order_Currency: data.orderDetails.grandTotalCurrency,
    Order_Id: data.orderDetails?.orderId,
    Order_CartId: data.orderDetails?.orderId,
    Order_ProductsAmount: `${data.orderDetails.orderItem.length}`,
    Order_DiscountName: orderDiscountNames,
    Order_DiscountCode: orderDiscountCodes,
    Order_DiscountAmount: getOrderTotalPromoCodeDiscount(data.orderDetails),
    Order_ShippingAmount: data.orderDetails.totalShippingCharge,
    Order_ShippingTaxRate: data.orderDetails.totalShippingTax,
    Order_TaxAmount: data.orderDetails.totalSalesTax,
    Order_ShippingMode: capitalizeFirstLetter(data.orderDetails.orderItem[0].shipModeDescription),
    Order_PaymentType: paymentType,
    Order_State: getOrderState(data.orderDetails),
    Order_ZipCode: getOrderZipCode(data.orderDetails),
    Order_Subtotal: data.orderDetails.totalProductPrice,
    Order_Total: data.orderDetails.grandTotal,
    Order_SubscriptionFrequency: orderSubscriptionFrequency,
    ...userData,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendPaymentEvent = (data: {
  common?: Partial<CommonData>
  loginStatus?: boolean
  products: IProduct[]
  email?: string
  isOptin?: boolean
  shipMode?: string
}): void => {
  const dataToSend: PaymentData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Payment',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Order_ShippingMode: data.shipMode,
    User_Email_MD5: md5(data.email).toString(),
    User_Email_SHA256: sha256(data.email).toString(),
    User_EmailOptin: !!data.isOptin ? '1' : '0',
    Products: getProductsForAnalytics(
      data.products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service')
    ),
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendAccountInformationEvent = ({
  Page_Type,
  Page_Section1,
  Page_Section2,
}: {
  Page_Type: string
  Page_Section1: string
  Page_Section2: string
}) => {
  const dataToSend = { id: 'VirtualPage-View', Page_Type, Page_Section1, Page_Section2 }
  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendProductUpdateEvent = (product): void => {
  const Products = getProductsForAnalytics([product])
  const dataToSend: ProductUpdateData = {
    id: 'Prods-Update',
    Products,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendHomeEvent = (
  common: Partial<CommonData>,
  orderCartId: string,
  userDetails?: UserDetails | null
): void => {
  const userData = userDetails ? getUserDetailsForAnalytics(true, userDetails) : {}
  let dataToSend: DataToSend = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Home',
    Page_Section1: 'Home',
    User_LoginStatus: userDetails ? 'Logged' : 'Guest',
    Order_CartId: orderCartId,
    ...userData,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEventRaw({ ...dataToSend, ...redirectData })
}

export const sendStaticEvent = (data: {
  common?: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Static',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendLandingEvent = (data: {
  common?: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Landing',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendPlpEvent = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: IProduct[]
  pageSection: string
}): void => {
  const Products = getProductsForAnalytics(data.products as any)
  const dataToSend: PLPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Plp',
    Page_Section1: data.pageSection,
    Search_ResultItemsQnt: `${data.qnt}`,
    Page_Design: 'Editorial',
    Products,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendSearchResultEvent = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: IAlgoliaHit[]
  pageSection: string
  searchKeyword: string
  searchKeyActual: string
}): void => {
  const Products = getProductsForAnalytics(data.products as any)
  const dataToSend: SearchPageData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Search',
    Page_Section1: 'Search',
    Search_ResultItemsQnt: `${data.qnt}`,
    Page_Design: 'Tiles&Pages',
    Products,
    Search_Keyword: data.searchKeyword,
    Search_KeyActual: data.searchKeyActual,
    Search_Type: 'text',
    Search_View: 'SERP',
    Events_SearchRun: '1',
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const getFrameAdvisorAnalyticsData = (frameGeniusData?: FrameGeniusType) => {
  let formattedData = ''
  frameGeniusData &&
    Object.values(frameGeniusData.analysisResults).map((v, i) => {
      if (typeof v === 'object' && !Array.isArray(v)) return

      return (formattedData += `${Object.keys(frameGeniusData.analysisResults)[i]}=${v},`)
    })

  return {
    Page_UserFace: formattedData, // FaceShape=, FaceLength=, Age=, HairColor=, EyeColor= E.g. "FaceShape=Angular,FaceLength=Oval
    Page_UserStyle: '',
  }
}

export const sendFilterSelectedEvent = (
  filters: {
    [key: string]: { value: string; facetName: string }
  },
  count?: number,
  frameGeniusData?: FrameGeniusType
): void => {
  Log.info('ANALYTICS - send filter selected event', filters as any)
  const isFrameGeniusToggleEnabled = SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  const frameAdvisorData = getFrameAdvisorAnalyticsData(frameGeniusData)

  let facetsValue = ''

  Object.values(filters).forEach((v, i, array) => {
    if (v.value) {
      facetsValue += `${v.facetName}=${v.value}`
      if (i !== array.length - 1) {
        facetsValue += '|'
      }
    }
  })

  const dataToSend = {
    ...frameAdvisorData,
    id: 'SearchFilterUpdated',
    Search_FacetValues_String: facetsValue,
    Search_ResultItemsQnt: count?.toString(),
    Events_SearchFiltering: '1',
    Search_FacetValues_Type: !!isFrameGeniusToggleEnabled ? 'framegenius' : '', // standard, framegenius
  }

  sendAnalyticEvent(dataToSend)
}

export const sendNewsletterSubscriptionEvent = (email: string): void => {
  Log.info('ANALYTICS - send newsletter subscription event', email)
  const emailLowerCase = email.toLocaleLowerCase()
  const dataToSend = {
    id: TRACKER_NEWSLETTER_SIGNUP,
    User_Email_MD5: md5(emailLowerCase).toString(),
    User_Email_SHA256: sha256(emailLowerCase).toString(),
    Events_UserEmailSub: '1',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendRegistrationEvent = (userDetails: UserDetails, acceptedNewsletterFlag?: boolean): void => {
  const { email1 } = userDetails
  Log.info('ANALYTICS - send registration event', email1)

  const emailLower = email1.toLocaleLowerCase()

  const dataToSend = {
    id: 'Event',
    Events_UserAccountNew: '1',
    User_LoginType: 'Standard',
    User_Email_MD5: md5(emailLower).toString(),
    User_Email_SHA256: sha256(emailLower).toString(),
    User_Phone_MD5: '',
    User_Phone_SHA256: '',
    User_Country: userDetails?.country?.toUpperCase() || userDetails?.preferredLanguage?.substring(3) || '',
    User_Segments: userDetails?.x_data?.customerSegments?.join(', '),
    Events_UserEmailSub: acceptedNewsletterFlag ? '1' : '0',
    User_Id: userDetails.userId,
    User_LoginStatus: USER_SEGMENT_LOGGED,
  }

  localStorage.setItem(NEW_REGISTRATION_REDIRECT_EVENT, JSON.stringify({ ...dataToSend }))
}

export const sendSearchEvent = (searchingString: string, qty: string): void => {
  Log.info('ANALYTICS - searching event', searchingString)

  const dataToSend = {
    id: 'Event',
    Events_SearchRun: '1',
    Search_ResultItemsQnt: qty,
    Search_Keyword: searchingString,
    Search_KeyActual: searchingString,
    Search_Type: 'text',
    Search_View: 'SERB',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendPromoCodeEvent = (): void => {
  Log.info('ANALYTICS - promoCode event')
  const dataToSend = {
    id: TRACKER_PROMOCODE_TOGGLE,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendMiniCartOverlayOpenEvent = () => {
  sendAnalyticEvent({
    id: TRACKER_MINICART_OVERLAY_OPEN,
  })
}

export const sendInsuranceEvent = (payload: InsuranceEventPayload) => {
  sendAnalyticEvent(payload)
}

export const sendOrderReminderSuccessEvent = (): void => {
  Log.info('OrderReminder - submit Success event')
  const dataToSend = {
    id: 'Event',
    Events_OrderReminderSave: '1',
  }
  sendAnalyticEvent(dataToSend)
}

export const sendOrderReminderErrorEvent = (errorDetails: string, errorMessage: string): void => {
  Log.info('OrderReminder - submit error event')

  const dataToSend = {
    id: 'Event',
    Events_Error: '1',
    Error_Source: 'Server',
    Error_Code: 'OrderReminderSave',
    Error_Message: errorMessage,
    Error_Details: errorDetails,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendRestrictedIntShippingErrorEvent = (errorMessage: string): void => {
  Log.info('InternationalShipping - restricted shipping error event')

  const dataToSend = {
    id: 'Event',
    Events_Error: '1',
    Error_Source: 'User',
    Error_Code: 'Delivery - not available',
    Error_Message: errorMessage,
    Error_Details: 'Delivery not available for the selected area',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendLoginRegisterEvent = (common?: Partial<CommonData>, locale?: string, orderId?: string): void => {
  const dataToSend: DataToSend = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Login&Register',
    Page_Design: 'Login&Register',
    ...(orderId && { Order_CartId: orderId }),
    Order_CartUrl: orderId ? `${window.location.origin}/${locale}/${CART}` : '',
    User_LoginStatus: 'Guest',
  }

  sendAnalyticEventRaw(dataToSend)
}

export const sendCheckoutEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: IProduct[]
  Order_Subtotal?: string
  Order_Total?: string
  Order_ProductsAmount?: number
}): void => {
  let dataToSend: CartData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Payment',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section3: '',
    Products: getProductsForAnalytics(
      data.products.filter(y => !['Rox_lens', 'Rox_service'].includes(getProductType(y)))
    ),
    Order_Total: data?.Order_Total,
    Order_Subtotal: data?.Order_Subtotal,
    Order_ProductsAmount: `${data?.Order_ProductsAmount}`,
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendOrderReviewEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: IProduct[]
  Order_Subtotal?: string
  Order_Total?: string
  Order_ProductsAmount?: number
  shipMode: string
}): void => {
  let dataToSend: OrderReviewData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'OrderSummary',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section3: '',
    Products: getProductsForAnalytics(
      data.products.filter(y => !['Rox_lens', 'Rox_service'].includes(getProductType(y)))
    ),
    Order_Total: data?.Order_Total,
    Order_Subtotal: data?.Order_Subtotal,
    Order_ProductsAmount: `${data?.Order_ProductsAmount}`,
    Order_PaymentType: ORDER_PAYMENT_TYPE.EXPRESS_OPTION.AMAZON_PAY,
    Order_ShippingType: 'Home',
    Order_ShippingMode: data.shipMode,
    Order_PaymentInstallments: '',
  }

  const redirectData = getRedirectAnalyticsData()
  sendAnalyticEvent({ ...dataToSend, ...redirectData })
}

export const sendNewsletterSubEvent = (subscribed: boolean, email: string): void => {
  Log.info('Newsletter - subscribe event')

  const dataToSend = {
    id: 'Event',
    Events_UserEmailSub: subscribed ? '1' : '0',
    User_Email_MD5: md5(email).toString(),
    User_Email_SHA256: sha256(email).toString(),
  }
  sendAnalyticEvent(dataToSend)
}

export const sendPHIAgreementEvent = (): void => {
  Log.info('PHI - agreement event')

  const dataToSend = {
    id: 'Click',
    data_element_id: 'ConsentDataMigration_Y',
    data_description: 'Accepted',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendPHIDisagreementEvent = (): void => {
  Log.info('PHI - disagreement event')

  const dataToSend = {
    id: 'Click',
    data_element_id: 'ConsentDataMigration_N',
    data_description: 'Refuse',
  }

  sendAnalyticEvent(dataToSend)
}

export const getTealiumCustomerType = async (buyerId: string, storeId: string): Promise<USER_TYPE> => {
  const { PROSPECT, CUSTOMER, REPEAT_CUSTOMER } = USER_TYPE

  const response = await orderService.getOrderHistory(
    1, // we only need one page of results
    buyerId,
    ORDER_HISTORY_ORDERS_PER_PAGE,
    storeId
  )
  const totalOrders = response.Order?.filter(isActiveOrderStatus)?.length ?? 0

  return totalOrders === 0 ? PROSPECT : totalOrders === 1 ? CUSTOMER : REPEAT_CUSTOMER
}

/**
 * Checks whether it's a valid segment for the OrderDiscount_Name value
 * @param activeSegment Active customer segment
 */
export const isValidOrderDiscountSegment = (activeSegment: string) => {
  return ![config.algolia?.guestSegment, USER_SEGMENT_REGISTERED_USERS].includes(activeSegment)
}

/**
 * Format the OrderDiscount_Name value
 * @param activeSegment The active customer segment
 * @returns new formatted value
 */
export const formatCustomerSegmentForOrderDiscount = (activeSegment: string) => {
  return `Dynamic_${activeSegment}`
}

/**
 * Format the discounts, using method from previous implementation
 * @param discounts list of discount names
 * @returns a comma seperated list of formated discount names
 */
export const formatOrderDiscountNames = (discounts: string[]) => {
  return discounts?.map(d => d.replace(' ', ''))?.join(',')
}

export const isReorder = () => sessionStorageUtil.get(ANALYTICS_IS_REORDER) === true

export const formatElementString = (key: string) => key?.replaceAll(' ', '_')

export const sendButtonOnClickEvent = (data_element_id?: string, data_description?: string): void => {
  sendAnalyticEvent({
    id: TRACKER_MOUSE_CLICK,
    data_element_id,
    data_description,
  })
}
