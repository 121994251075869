import { ACCOUNT, ACCOUNT_CHILDREN } from './routes'

export const REG_EX = {
  NUMERIC: /^[0-9]+$/,
  EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
  PHONE: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, // /^[-+() ]*[0-9][-+() 0-9]*$/,
  PRICE: /^[0-9]+([.][0-9]{0,2})?$|^[.][0-9]{1,2}$/,
  NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^[a-zA-Z0-9 ]*$/,
  NO_SINGLE_DOTS: /^(?!\.$).*/,
}

export const CONTACT_LENS_DIAMETER_COLUMN = 'x_diameter'
export const CONTACT_LENS_BASE_CURVE_COLUMN = 'x_baseCurve'
export const CONTACT_LENS_SPHERE_POWER_COLUMN = 'x_spherePower'
export const CONTACT_LENS_DEFAULT_QUANTITY = '0'
export const CONTACT_LENS_ACCESSORIES_DEFAULT_QUANTITY = '1'
export const ADJUSTMENT_DISCOUNT = 'Discount'
export const SHIPPING_ADJUSTMENT_TEXT = 'Shipping Adjustment'
export const SURCHARGE_TEXT = 'Surcharge'
export const XXXXXXXXX_TEXT = 'XXXXXXXXX'
export const CONTACT_LENS_MIN_QUANTITY = 0
export const CONTACT_LENS_MAX_QUANTITY = 30

export const DEFINING = 'Defining'
export const DESCRIPTIVE = 'Descriptive'
export const OFFER = 'Offer'
export const DISPLAY = 'Display'
export const SEARCHTERM = 'query'
export const ORIGINALSEARCHTERM = 'originalSearchTerm'

export const EMPTY_STRING = ''
export const SPACE_CHARACTER = ' '
export const STRING_TRUE = 'true'
export const STRING_FALSE = 'false'

//Address Form
export const CHECKOUT = 'checkout'
export const ADDRESS_BOOK = 'address-book'
export const ADDRESS_SHIPPING = 'Shipping'
export const ADDRESS_BILLING = 'Billing'
export const ADDRESS_SHIPPING_BILLING = 'ShippingAndBilling'
export const NEW_ADDRESS = 'newAddress'
export const ADDRESS_LINE = 'addressLine'
export const ADDRESSLINE1 = 'addressLine1'
export const ADDRESSLINE2 = 'addressLine2'
export const ADDRESS_1 = 'address1'
export const ADDRESS_2 = 'address2'
export const PHONE1 = 'phone1'

//Fiscal Code
export const FISCAL_CODE_PREFIX = 'xaddr'
export const FISCAL_CODE = 'fiscalCode'

//Admin Tools
export const IBM_ASSIGNED_ROLE_DETAILS = 'IBM_Assigned_Roles_Details'
export const BUYER_ADMIN_ROLE = '-21'
export const BUYER_APPROVAL_ROLE = '-22'

export const ADDRESS_TYPE_MAP = new Map()
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING, 'AddressBook.ShippingLabel')
ADDRESS_TYPE_MAP.set(ADDRESS_BILLING, 'AddressBook.BillingLabel')
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING_BILLING, 'AddressBook.ShippingBillingLabel')

//keycodes
export const KEY_CODES = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
}

export const RX_CONF_SELECTOR = 'rxcApp'

export const CommerceEnvironment = {
  categorySkeleton: {
    id: '',
    type: 'Child PIM categories',
    categoryInternal: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  productSkeleton: {
    id: '',
    type: 'Product',
    productInternal: '',
    productDesc: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  suggestionSkeleton: {
    arrIndex: '',
    id: '',
    type: 'Suggestion',
    name: '',
    url: {},
  },
  errorParamsSkeleton: {
    zero: '',
    one: '',
    two: '',
  },
  defaultLang: 'en_US',
  languageMap: {
    '-1': 'en_us',
    '-2': 'fr_fr',
    '-3': 'de_de',
    '-4': 'it_it',
    '-5': 'es_es',
    '-6': 'pt_br',
    '-7': 'zh_cn',
    '-8': 'zh_tw',
    '-10': 'ja_jp',
    '-20': 'ru_ru',
    '-21': 'ro_ro',
    '-24': 'en_ca',
    '-25': 'fr_ca',
    '-39': 'de_at',
    '-40': 'fr_be',
    '-41': 'nl_be',
    '-42': 'pt_pt',
    '-43': 'en_uk',
    '-44': 'nl_nl',
    '-45': 'en_ie',
    '-54': 'en_nz',
    '-47': 'en_au',
    '-99': 'en_ww',
  },
  reverseLanguageMap: {
    en_us: '-1',
    fr_fr: '-2',
    de_de: '-3',
    it_it: '-4',
    es_es: '-5',
    pt_br: '-6',
    zh_cn: '-7',
    zh_tw: '-8',
    ja_jp: '-10',
    ru_ru: '-20',
    ro_ro: '-21',
    en_ca: '-24',
    fr_ca: '-25',
    de_at: '-39',
    fr_be: '-40',
    nl_be: '-41',
    pt_pt: '-42',
    en_gb: '-43',
    en_uk: '-43',
    nl_nl: '-44',
    en_ie: '-45',
    en_nz: '-54',
    en_au: '-47',
    en_ww: '-99',
  },
  dxLanguageMap: {
    '-1': 'en',
    '-2': 'fr',
    '-3': 'de',
    '-4': 'it',
    '-5': 'es',
    '-6': 'pt',
    '-7': 'zh',
    '-8': 'zh-tw',
    '-10': 'ja',
    '-20': 'ru',
    '-21': 'ro',
    '-24': 'ca',
    '-25': 'fr-ca',
    '-40': 'fr-be',
    '-41': 'nl-be',
    '-42': 'pt',
    '-43': 'en',
    '-44': 'nl',
    '-45': 'en',
    '-47': 'en',
    '-49': 'at',
    '-54': 'en',
    '-99': 'en',
  },
  localeLangMap: [
    {
      countryCode: 'at',
      langCode: 'de_at',
    },
    {
      countryCode: 'ca',
      langCode: 'en_ca',
    },
    {
      countryCode: 'ca',
      langCode: 'fr_ca',
    },
    {
      countryCode: 'na',
      langCode: 'en_nz',
    },
    {
      countryCode: 'au',
      langCode: 'en_au',
    },
    {
      countryCode: 'de',
      langCode: 'de_de',
    },
    {
      countryCode: 'es',
      langCode: 'es_es',
    },
    {
      countryCode: 'fr',
      langCode: 'fr_fr',
    },
    {
      countryCode: 'ie',
      langCode: 'en_ie',
    },
    {
      countryCode: 'it',
      langCode: 'it_it',
    },
    {
      countryCode: 'nl',
      langCode: 'en_nl',
    },
    {
      countryCode: 'pt',
      langCode: 'en_pt',
    },
    {
      countryCode: 'uk',
      langCode: 'en_uk',
    },
    {
      countryCode: 'us',
      langCode: 'en_us',
    },
    {
      countryCode: 'ww',
      langCode: 'en_ww',
    },
    {
      countryCode: 'be',
      langCode: 'nl_be',
    },
    {
      countryCode: 'be',
      langCode: 'fr_be',
    },
    {
      countryCode: 'nl',
      langCode: 'nl_nl',
    },
  ],
  itemizedTaxCountryCodes: ['ca'] as string[],
  hrefLangMap: {
    'fr-fr': 'fr-fr',
    'fr-be': 'fr-be',
    'fr-ca': 'fr-ca',
    'nl-nl': 'nl-nl',
    'nl-be': 'nl-be',
    'en-au': 'en-au',
    'en-ca': 'en-ca',
    'en-gb': 'en-gb',
    'en-nz': 'en-nz',
    'en-ie': 'en-ie',
    'es-es': 'es',
    'it-it': 'it',
    'en-ww': 'x-default',
    'x-default': 'x-default',
  },
  directInsuranceBilling: {
    en_ca: { enabled: true },
    fr_ca: { enabled: true },
  },
}

export const LANGUAGE_CODE_MAP = {
  en_us: 'en-us',
  fr_fr: 'fr-fr',
  de_de: 'de-de',
  it_it: 'it-it',
  es_es: 'es-es',
  pt_be: 'pt-br',
  zh_cn: 'zh-ch',
  zh_tw: 'zh-tw',
  ja_jp: 'js-jp',
  ru_ru: 'ru-ru',
  ro_ro: 'ro-ro',
  en_ca: 'en-ca',
  fr_ca: 'fr-ca',
  de_at: 'de-at',
  pt_pt: 'pt-pt',
  en_uk: 'en-uk',
  en_gb: 'en-gb',
  nl_nl: 'nl-nl',
  en_ie: 'en-ie',
  en_ww: 'en-ww',
  en_nz: 'en-nz',
  en_au: 'en-au',
  fr_be: 'fr-be',
  nl_be: 'nl-be',
}

export const STORE_URL_MAP = {
  'en-au': '.com.au',
  'en-ca': '.ca',
  'en-ie': '.ie',
  'en-gb': '.co.uk',
  'en-nz': '.co.nz',
  'en-ww': '.co.uk',
  'en-it': '.it',
  'fr-fr': '.fr',
  'fr-ca': '.ca',
  'es-es': '.es',
  'fr-be': '.be',
  'nl-nl': '.nl',
  'nl-be': '.be',
  'it-it': '.it',
  'de-de': '.de',
}

export const INVENTORY = {
  NON_ATP: -2,
}

// e.g. https://www.visiondirect.be/nl
export const LOCALES_WITH_LANGUAGE_IN_URL = ['nl_BE', 'fr_BE']

export const PAGES_EXCLUDED_FROM_INDEXING = ['upsell-1', 'upsell-2', 'upsell-3', 'upsell-4', 'upsell-5']
export const PAGES_TO_TRIGGER_PHI_CONSENT_POPUP = [
  `/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`,
  `/${ACCOUNT}/${ACCOUNT_CHILDREN.ORDER_HISTORY}`,
]
export const PAGE_TYPES_TO_CHECK_INDEXING = ['plp', 'search']

export const SUCCESS_MSG_PREFIX = 'success-message.'
export const DEFAULT_LANG_ID = '-1'

// Shipping
export const IS_PERSONAL_ADDRESS_ALLOWED = 'x_isPersonalAddressesAllowedForShipping'
export const ORG_ADDRESS_DETAILS = 'orgAddressDetails'
export const ORG_ADDRESS = 'orgAddress'
export const ORDER_ID = 'orderid'
export const HYPHEN = '-'
export const UNDERSCORE = '_'
export const SPACE = ' '

// Discover
export const DISCOVER_FEATURE = 'Discover'
//Image
export const IMAGE = 'IMAGE'
export const VIDEO = 'VIDEO'
export const ATTACHMENTS = 'Attachments'
//DX
export const DX_IMAGE_PATH_STARTS_WITH = '/dx/'
export const DX_IMAGE_THUMBNAIL_TRUE = '?thumbnail=true'
//Teaser
export const DASH_BUTTON_TEASER_VIEWTYPE = 'PLP_dash_button'
export const FULL_WIDTH_BANNER_VIEWTYPE = 'full-width-banner'
export const PLP_BOX_TEASER_VIEWTYPE = 'plp-box'

//Filters
export const GROUPING_PROFILE_NAME = 'LX_findProductsByCategory_Grouping'
export const GROUPING_DISABLED_PROFILE_NAME = 'LX_findProductsByCategory_With_Attachments'
export const SEARCH_TERM_GROUPING_PROFILE_NAME = 'LX_findProductsBySearchTerm_Grouping'
export const SEARCH_TERM_PROFILE_NAME = 'LX_findProductsBySearchTerm'
export const SUGGESTIONS_KEYWORDS_PROFILE_NAME = 'LX_findNavigationSuggestion_Keywords'
export const BREADCRUMB_PROFILE = 'HCL_BreadCrumbByCategoryUniqueId'
export const CLUSTERING_PROFILE = 'LX_findProductByPartNumber_Details_Grouping'
export const ITEM_DETAILS_PROFILE_NAME = 'LX_findItemByIds_Details'
export const PROFILE_NAME_PARAM = 'profileName'
export const SORTING_BY_PRICE_LOW = '3'
export const SORTING_BY_PRICE_HIGH = '4'
export const SORTING_BY_NEW_ARRIVALS = '5'
export const SORTING_NOT_CLUSTERED = [SORTING_BY_PRICE_LOW, SORTING_BY_PRICE_HIGH]

export const PDF_MIME_TYPE = 'data:application/pdf;base64,'
export const ANALYTICS_PRODUCT_CATEGORY_MAP = {
  'CONTACT-LENSES': 'CONTACT LENSES',
  OPTICAL: 'OPTICS',
  'CONTACT LENSES ACCESSORIES': 'ACCESSORIES',
  FRAMES: 'EYEWEAR',
}

export const ADJUSTMENT_USAGE = {
  DISCOUNT: 'discount',
  SURCHARGE: 'surcharge',
}

export const USER_SEGMENT_GUEST = 'Guest'
export const USER_SEGMENT_REGISTERED_USERS = 'RegisteredUsers'
export const USER_SEGMENT_LOGGED = 'Logged'
export const USER_SEGMENT_PAID_SEARCH = 'PaidSearch'
export const USER_SEGMENT_GOOGLE_SHOPPING = 'GoogleShopping'

export const SPECIAL_PRICE_SEGMENTS = [USER_SEGMENT_GOOGLE_SHOPPING, USER_SEGMENT_PAID_SEARCH]

//Order History
export const CANCELLED_ORDERS = 'CancelledOrders'
export const ORDER_HISTORY_ORDERS_PER_PAGE = 4
export const ORDER_HISTORY_START_DATE = '2021-10-01'

//Order Reminder
export const DEFAULT_NUMBER_OF_LENS_WEAR_DAYS = 5
export const LENS_WEAR_DAYS_OPTIONS = [7, 6, 5, 4, 3, 2, 1]

export const AUTOLOGIN_LOGIN_ID_KEY = 'l_id'
export const AUTOLOGIN_TOKEN_KEY = 'l_token'

export const SIGNIN_CMS_PAGEID = 'sign-in-page'
export const CHECKOUT_CMS_PAGEID = 'cart'
export const ORDER_CONFIRMATION_CMS_PAGEID = 'order-confirmation'

export const TOP_REASONS_CMS_PDP_PLACEMENTID = 'PDP_placement_3'
export const TOP_REASONS_CMS_ORDER_CONFIRMATION_PLACEMENTID = 'main_placement_1'
export const TOP_REASONS_CMS_CHECKOUT_PLACEMENTID = 'cart_placement_2'

export const SELECTED_COUNTRY = 'selectedCountry'

//Newsletter
export const DEFAULT_COUNTRY_CODE = 'ca'
export const DEFAULT_LANG_CODE = 'en'
export const DEFAULT_BASE_URL = 'www.clearly.ca'
export const PROMO_URL_PARAM = 'promo'
export const EARLY_ACCESS_PROMO = 'EarlyAccess'
export const USER_SEGMENT_EARLY_ACCESS = 'EarlyAccess'
export const NEWSLETTER_DRAWER_DEFAULT_ID = 'newsletter-subscribe'
export const NEWSLETTER_DRAWER_PROMO_ANZ_ID = 'newsletter-promo-subscribe-anz'
export const NEWSLETTER_DRAWER_PROMO_CA_ID = 'newsletter-promo-subscribe-ca'

//HTTP
export const HTTP_CODE_OK = '200'
export const HTTP_CODE_CONFLICT = '409'

export const ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE = 'RecurringFrequency'

export const RTK_QUERY_STATUS = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
}

export const LANDING_PAGES_HEADER_TITLE_REMOVED = ['subscribe', 'clearly-subscription-program']
