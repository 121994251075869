import { useEffect } from 'react'

interface UseServiceWorkerOptions {
  clearCache?: boolean
  log?: boolean
}

function usePerformanceServiceWorker(swPath: string = '/sw.js', options: UseServiceWorkerOptions = {}): void {
  useEffect(() => {
    if (typeof window === 'undefined') return

    const { clearCache = true, log = true } = options

    if (process.env.NODE_ENV === 'production') {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register(swPath)
          .then(reg => {
            if (log) {
              console.log('[SW] Registered:', reg)
            }
          })
          .catch(err => {
            if (log) {
              console.error('[SW] Registration failed:', err)
            }
          })
      } else {
        console.log('[SW] are not supported in this browser.')
      }
    } else {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations: readonly ServiceWorkerRegistration[]) => {
          registrations.forEach(reg => {
            reg.unregister().then(() => {
              if (log) {
                console.log('[SW] Unregistered in development')
              }
            })
          })
        })
      }

      if (clearCache && 'caches' in window) {
        caches.keys().then((cacheNames: string[]) => {
          cacheNames.forEach(cache => {
            caches.delete(cache).then(() => {
              if (log) {
                console.log(`[SW] Deleted cache: ${cache}`)
              }
            })
          })
        })
      }
    }
  }, [swPath, options.clearCache, options.log])
}

export default usePerformanceServiceWorker
