import { AxiosError } from 'axios'
import { ErrorForAnalytics } from '../interfaces'

export const getFormattedError = (error: AxiosError): ErrorForAnalytics => {
  const statusCode = error?.response?.status || '500'
  const errorKey = error?.response?.data?.errors?.[0]?.errorKey || ''
  const errorMessage = error?.response?.data?.errors?.[0]?.errorMessage || ''
  return {
    id: 'Event',
    Events_Error: '1',
    Error_Source: 'Server',
    Error_Code: String(statusCode),
    Error_Details: errorKey,
    Error_Message: errorMessage,
  }
}
