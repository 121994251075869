import { GeneralUserFields } from './user'
import { GeneralFormFields } from './form'
import { CheckoutName, Icon } from '@constants/paymentMethods'
import { PAYMENTS_LIST_DESCRIPTION } from '@constants/paymentMethods'

export interface SubscriptionData {
  recordSetComplete: boolean
  recordSetCount: number
  recordSetTotal: number
  resourceId: string
  resourceName: string
  resultList: SubscriptionResult[]
}

export interface SubscriptionResult {
  subscriptionInfo: SubscriptionInfo
  state: SUBSCRIPTION_STATES
  purchaseDetails: PurchaseDetails
  subscriptionIdentifier: SubscriptionIdentifier
}

export interface SubscriptionInfo {
  userData: UserData
  fulfillmentSchedule: FulfillmentSchedule
  paymentSchedule: PaymentSchedule
  paymentInfo: PaymentInfo
}

export type SubscriptionRecurrency = {
  fulfillmentInterval?: string
  fulfillmentIntervalUOM?: string
}
export interface SubscriptionInfoSummary {
  identifier: string
  active: boolean
  recurrency?: SubscriptionRecurrency
}

export interface UserData {
  userDataField: UserDataField[]
}

export interface UserDataField {
  value: string
  key: string
}

export interface FulfillmentSchedule {
  startInfo: StartInfo
  userData: null | Record<string, unknown>
  frequencyInfo: FrequencyInfo
  endInfo: EndInfo
}

export interface StartInfo {
  userData: null | Record<string, unknown>
  startDate: string
}

export interface FrequencyInfo {
  nextOccurence: string
  userData: null | Record<string, unknown>
  remainingOccurrences: null | number
  completedOccurrences: null | number
  frequency: Frequency
}

export interface Frequency {
  uom: string
  value: number
}

export interface EndInfo {
  duration: null | number
  totalOccurrences: null | number
  userData: null | Record<string, unknown>
  endDate: string
}

export interface PaymentSchedule {
  startInfo: null | Record<string, unknown>
  userData: UserData
  frequencyInfo: FrequencyInfo
  endInfo: null | Record<string, unknown>
}

export interface PaymentInfo {
  userData: UserData
  amountPaid: null | number
  amountToCharge: null | number
  totalCost: TotalCost
}

export interface TotalCost {
  currency: string
  value: number
}

export interface PurchaseDetails {
  userData: UserData
  parentOrderIdentifier: ParentOrderIdentifier
}

export interface ParentOrderIdentifier {
  parentOrderId: string
}

export interface SubscriptionIdentifier {
  subscriptionId: string
}

export interface ProductData {
  productImgUrl: string
  productName: string
  productUrl: string
  x_contactLens: Record<string, unknown>
}

export interface SubscriptionCardData {
  subscriptionId: string
  status: SubscriptionStatus
  frequency: string
  nextOrderCreationDate: string | null
  manageSubscriptionLabel: string
  productImageUrl: string
  orderItemsCount: number
}

export interface SubscriptionStatus {
  status: SUBSCRIPTION_STATES | null
  statusLabelText: SUBSCRIPTION_STATES_LABELS | null
  statusLabelColor: string
  isEditable: boolean
  badgeColor: string
  message: string
  Icon: any
  color: string
}

export enum SUBSCRIPTION_STATES {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
  CANCELLED = 'Cancelled',
}

export enum SUBSCRIPTION_STATES_LABELS {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  CANCELLED = 'Cancelled',
}

export enum SUBSCRIPTION_FREQUENCY_UNITS {
  WEEK = 'WEEK',
  MONTH = 'MON',
}

export enum ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS {
  INACTIVE = '0',
  ACTIVE = '1',
  CANCELLED = '3',
}

export interface SubscriptionStateToggleResponse {
  subscriptionType: [string]
  resourceName: string
  state: [SUBSCRIPTION_STATES]
  subscriptionId: [string]
}

export type SubscriptionWallet = Pick<Wallet, 'issuer' | 'payMethodId' | 'protectedCCNumber' | 'expireDate'> & {
  walletId: string
  payMethodName: CheckoutName
}

export type Address = Required<
  Pick<GeneralFormFields, 'addressId' | 'city' | 'country' | 'addressType' | 'state' | 'zipCode' | 'addressLine'>
> &
  Pick<GeneralUserFields, 'firstName' | 'lastName' | 'phone1' | 'personTitle'> & {
    buzzerCode: string
  }

export interface SubscriptionDetails extends SubscriptionData {
  x_data: SubscriptionDetailXData
}

export type subscriptionPaymentData = SubscriptionWallet | SubscriptionPaymentInfo | null

export type SubscriptionPayMethodId = (typeof PAYMENTS_LIST_DESCRIPTION)[keyof typeof PAYMENTS_LIST_DESCRIPTION]

export interface SubscriptionPaymentInfo {
  payMethodId: SubscriptionPayMethodId
  payMethodName: CheckoutName
}

export type Price = {
  currency: string
  value: string
}

export interface SubscriptionDetailXData {
  wallet?: SubscriptionWallet
  prescriptionId: string
  statusReason: string
  nextDeliveryDate: string
  products: string
  addresses: Address[]
  PaymentInfo?: SubscriptionPaymentInfo
  deliveryCost: Price
  subscriptionDiscount: string
}

export interface DeliveryDateUpdateResponse {
  nextFulfillmentDate: string
  resourceName: string
  subscriptionId: number
}

export interface DeliveryFrequencyUpdateResponse {
  orderId: number
  resourceName: string
}

export interface SubscriptionPaymentMethodBox {
  Icon: Icon | null
  cardLastFourDigitsLabel: string
  isExpired: boolean
  expiredLabel: string
  iconFullWidth?: boolean
}

export interface SubscriptionProduct {
  itemQty: string
  productImgUrl: string
  x_contactLens?: XContactLens
  subscriptionExpectedPercDiscount: string
  discountedPrice: number
  itemAdjustment: number
  itemPrice: string
  productUrl: string
  productName: string
}

export interface SubscriptionProductAccmulated {
  itemQty: number
  productImgUrl: string
  L_eye?: XContactLens
  R_eye?: XContactLens
  subscriptionExpectedPercDiscount: string
  discountedPrice: number
  itemAdjustment: number
  itemPrice: string
  productUrl: string
  productName: string
  isContactLens: boolean
  packSize: number | null
  itemTotalPrice: number
  itemDiscountedTotalPrice: number | null
}

export interface XContactLens {
  x_productId?: string
  cl_pack_size?: string
  x_baseCurve?: string
  x_cylinder?: any
  x_color?: any
  x_spherePower?: string
  x_axis?: any
  x_addition?: any
  x_partNumber?: string
  x_eye?: string
  x_dominance?: string
  x_diameter?: string
}

export type KeyValue = { key: string; value: unknown }

export interface Wallet {
  walletId: string
  protectedCCNumber: string
  expireDate: string
  issuer: string
  payMethodId: string
}

export interface AddressInfo {
  lastName: string
  firstName: string
  country: string
  zipCode: string
  state: string
  city: string
  addressType: string
  addressLine: (string | null)[]
  addressId: string
}

export interface SubscriptionInfoObject {
  userData?: UserData
  fulfillmentSchedule: FulfillmentSchedule
  paymentSchedule: PaymentSchedule
  paymentInfo: PaymentInfo
}

export interface PurchaseDetail {
  userData: UserData
  parentOrderIdentifier: ParentOrderIdentifier
}

export interface SubscriptionItem extends Record<string, unknown> {
  subscriptionInfo: SubscriptionInfoObject
  state: string
  purchaseDetails: PurchaseDetail
  subscriptionIdentifier: SubscriptionIdentifier
}

interface BaseMarketingSpotActivity {
  contentFormatName: string
  baseMarketingSpotActivityName: string
  contentClickAction: string
  contentId: string
  baseMarketingSpotActivityID: string
  contentName: string
  baseMarketingSpotDataType: string
  activityIdentifierID: string
  marketingContentDescription: {
    marketingText: string
    language: string
  }[]
  contentFormatId: string
  activityPriority: string
  properties: {
    baseMarketingKey: string
    baseMarketingValue?: string
  }[]
  contentStoreId: string
}

export interface MarketingSpotData {
  nextTimeLimit: string
  defaultContentDisplaySequence: {
    resultId: string
    resultFormat: string
    displaySequence: string
  }[]
  marketingSpotIdentifier: string
  behavior: string
  eSpotName: string
  baseMarketingSpotActivityData: BaseMarketingSpotActivity[]
}

export type DeleteSubscriptionPayload = {
  storeId: string
  subscriptionId: string
}

export type CartSubscriptionResponse = {
  resourceName: string
  status: number
  statusText: string
  subscriptionInfo?: SubscriptionInfoSummary
}

export type SubscriptionsProps = {
  enabled: boolean | undefined
  items: {
    [key: string]: SubscriptionInfo
  }
}

export type SubscriptionListRequestPayload = {
  storeId: string
}

export type SubscriptionRequestPayload = {
  storeId: string
  subscriptionId: string
}

export type ActivateSubscriptionPayload = {
  orderId: string
  storeId: string
  subscriptionId: string
  fulfillmentInterval?: string
  fulfillmentIntervalUOM?: string
}

// Change subscription frequency types

export interface SubscriptionFrequencyPayload {
  storeId: string
  subscriptionId: string
  body: {
    fulfillmentInterval: string
    fulfillmentIntervalUOM: string
  }
}

// Change subscription fulfillment date types

export interface SubscriptionFulfillmentPayload {
  storeId: string
  subscriptionId: string
  body: {
    nextFulfillmentDate: string
  }
}

// Change subscription delivery address types

export interface SubscriptionAddressPayload {
  storeId: string
  subscriptionId: string
  body: {
    shippingAddressId: string
  }
}

export type ChangeSubscriptionStatusResponse = {
  resourceName: string
  subscriptionId: string[]
  subscriptionType: string[]
  state: string[]
}

export type UpdateSubscriptionWalletPayload = {
  storeId: string
  subscriptionId: string
  body: {
    walletId: string
  }
}

export type SubscriptionPayload = {
  storeId: string
  orderItemId?: string
  recurringFrequency?: string
}

export type CancelSubscriptionPayload = {
  orderId: string
  storeId: string
  subscriptionId: string
}

export interface SubscriptionPostponeResponse {
  nextOccurence: string
  orderId: number
  resourceName: string
}

export interface SubscriptionPostponePayload {
  storeId: string
  subscriptionId: string
  periodsToPostpone?: number
}

export interface SubscriptionExtendedData {
  prescriptionId: string
  addresses: AddressInfo[]
  wallet: Wallet
  statusReason?: string | null
  nextDeliveryDate: string
  products: string
}
export interface SubscriptionItemExtended extends Record<string, unknown> {
  subscription: SubscriptionItem
  x_data: SubscriptionExtendedData
}

export interface APIResponseBase<T = unknown> {
  recordSetCount: number
  resourceId: string
  recordSetStartNumber: number
  resourceName: string
  resultList: T
  recordSetTotal: number
  recordSetComplete: boolean
}

export enum SUBSCRIPTION_TYPE {
  ONE_TIME_PURCHASE = 'oneTimePurchase',
  SUBSCRIPTION = 'subscription',
}

export interface SubscriptionModuleValue {
  type: SUBSCRIPTION_TYPE
  period: number
}

export interface SubscriptionPeriodOption {
  key: string | number
  value: number
}

export enum SUBSCRIPTION_FREQUENCY_UNIT {
  DAY = 'day',
  MON = 'month',
  YEAR = 'year',
}

export type RecurrencySelectProps = {
  defaultValue: string
  onChange: (value: string) => void
  showStacked?: boolean
  customLabel?: string
  customPlaceHolder?: string
}
