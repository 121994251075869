import { TFunction } from 'i18next'
import {
  INSURANCE_DISCOUNT_ATTRIBUTE_NAME,
  INSURANCE_NAME_ATTRIBUTE_NAME,
  ORDER_EXTEND_ATTRIBUTE_NAMES,
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES,
  ORDER_STATUS,
  PARTIALLY_DISCONTINUED_SKUS_KEY,
} from '../constants/order'
import { PAYMENT_METHODS } from '../constants/paymentMethods'
import { IOrderSliceState } from '../features/order/IOrderSliceState'
import Log from '../services/Log'
import RequestService from '../services/RequestService'
import { OrderItemContactLensData } from '../types/cart'
import { PrescriptionItemsMapByType, PrescriptionMacroGroup } from '../types/checkout'
import {
  Attribute,
  Cart,
  IOrderDetails,
  IOrderItem,
  Order,
  ORDER_TYPE_CODE,
  OrderItemWithRoxProps,
  ParsedOrderItemsMapByItemType,
  PrescriptionItemType,
  PromotionCode,
  SummaryOrder,
} from '../types/order'
import {
  ContactLensData,
  ContactLensesData,
  EyeContanctLensOption,
  PrescriptionDetailsResponse,
} from '../types/prescription'
import { ProductTypesEnum } from '../types/product'
import { getDifferenceInDays } from './dateUtils'
import { chunk, countBy, flattenDeep, sum, uniq, uniqBy } from './helpers'
import { isCLAccessoriesProduct, isContactLensesProduct, isSunProduct } from './product'
import {
  getAllProductAttributes,
  getAnnualSupplyBadge,
  getNaturalAttribute,
  PRODUCT_ATTRIBUTES_IDENTIFIERS,
} from './productAttributes'
import { isRox, isRxCart, isRxLens, parseRxOrderItems } from './rx'
import { AdjustmentTypes } from '../types/Basket'
import { SPECIAL_PRICE_SEGMENTS } from '../constants/common'
import { sessionStorageUtil } from '../foundation/utils/storageUtil'
import { max, min, parse } from 'date-fns'
import { DATE_FORMAT_PATTERN } from '@constants/date'

interface CalculateDiscountLabelAmountArgs {
  viewableDiscountsTotal: number
  order: SummaryOrder
  shipping: number
  shippingThresholdReached: boolean
  shippingAdjustment: number
}

interface ShowPromoAppliedArgs {
  order: SummaryOrder
  hasDiscount: boolean
  viewableDiscountsTotal: number
}

export const getQuantity = (oi?: IOrderItem): number | undefined => Number(oi?.quantity)

export const getContactLensesParams = (oi: IOrderItem) =>
  oi.orderItemExtendAttribute.find(attr => attr.attributeName === 'x_contactLens')

export const cartHasContactLenses = (orderExtendAttribute: Order['orderExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(a => a.attributeName === 'hasContactLens' && a.attributeValue === 'true')
}

export const isClOrderItem = (orderExtendAttribute: IOrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(
    a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['IS_CONTACT_LENS'] && a.attributeValue === 'true'
  )
}

export const hasClOrderItem = (orderExtendAttribute: IOrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(
    a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['HAS_CONTACT_LENS'] && a.attributeValue === 'true'
  )
}

export const hasReminderDate = (orderExtendAttribute: IOrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['ORDER_REMINDER_DATE'])
}

export const isOrderWithSubscription = (orderTypeCode: ORDER_TYPE_CODE | undefined): boolean => {
  if (!orderTypeCode) return false

  return orderTypeCode === ORDER_TYPE_CODE.RECURRING
}

export const isAccessoriesOrderItem = (attribute: IOrderItem['attributes']): boolean => {
  return !!attribute?.find(
    a =>
      a.identifier === 'PRODUCT_TYPE' && a.values[0].value.toUpperCase() === ProductTypesEnum.Accessories.toUpperCase()
  )
}

export const hasActiveSubscriptionInCart = (cart: Cart): boolean => {
  if (!cart) return false

  const orderItems = cart?.orderItem || []

  return (
    orderItems.some((orderItem: IOrderItem) => {
      return orderItem?.orderItemExtendAttribute?.some(
        a => a?.attributeName === ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES['RECURRING_FREQUENCY']
      )
    }) ?? false
  )
}

export const getSubscriptionFrequencyValue = (cart: Cart): string | undefined => {
  if (!cart) return undefined
  const orderItems = cart.orderItem || []
  const attribute = orderItems
    .flatMap(orderItem => orderItem?.orderItemExtendAttribute || [])
    .find(attribute => attribute?.attributeName === ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES['RECURRING_FREQUENCY'])
  return attribute?.attributeValue
}

export const isClAccessoriesOrderItem = (attribute: IOrderItem['attributes']): boolean => {
  return !!attribute?.find(
    a =>
      a.identifier === 'PRODUCT_TYPE' &&
      a.values[0].value.toUpperCase() === ProductTypesEnum.ContactLensesAccessories.toUpperCase()
  )
}

export const orderHasPrescriptionUploaded = (oi: IOrderItem | OrderItemWithRoxProps): boolean => {
  try {
    return !!oi.prescriptionDetails
  } catch (e) {
    return false
  }
}

export const orderHasPrescriptionPeriodExcedeed = (od: IOrderDetails, expirationPeriodDays: number): boolean => {
  try {
    const orderDate = od?.lastUpdateDate?.split('T')[0] || null
    const difference = getDifferenceInDays(new Date(), new Date(orderDate || ''))
    return (
      difference > expirationPeriodDays &&
      (isRxCart(od['orderExtendAttribute']) || cartHasContactLenses(od['orderExtendAttribute']))
    )
  } catch (e) {
    return false
  }
}

export const getContactLensOrderItemData = (
  orderExtendAttribute: IOrderItem['orderItemExtendAttribute'] | null
): ContactLensesData | {} => {
  try {
    return (
      JSON.parse(
        orderExtendAttribute?.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['X_CONTACT_LENS'])
          ?.attributeValue || ''
      ) || null
    )
  } catch (e) {
    Log.error('Could not get contact lens order item attributes data')
    return {}
  }
}

export const getContactLensOrderItemData_Old = (orderItem: IOrderItem | null): ContactLensData | null => {
  try {
    const orderItemData: ContactLensData = JSON.parse(
      orderItem?.orderItemExtendAttribute?.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['X_CONTACT_LENS'])
        ?.attributeValue || ''
    )

    return orderItemData
      ? {
          ...orderItemData,
          x_productId: orderItem?.productId || orderItemData.x_productId,
        }
      : null
  } catch (e) {
    Log.error('Could not get contact lens order item attributes data')
    return null
  }
}

export const generateQuantityOptions = (
  max = 10,
  start = 1,
  itemSingular?: string,
  itemPlural?: string,
  itemsTotal?: number,
  translate?: TFunction<'translation', undefined>
) => {
  const useSingularOrPlural = !!itemSingular && !!itemPlural && !!itemsTotal && !!translate
  const options: EyeContanctLensOption[] = []
  let i: number = start
  while (i <= max) {
    options.push({
      text:
        `${i} ` +
        (useSingularOrPlural
          ? translate(`${i === 1 ? itemSingular : itemPlural}`, {
              count: i,
              total: itemsTotal * i,
            })
          : ''),
      value: `${i}`,
      index: i,
    })
    i++
  }
  return options
}

/**
 * function to aggregate order items by part number
 * @param { IOrderItem[] } orderItems current cart order items list
 */

export const getGroupedOrderItemsByPartNumber = (orderItems: IOrderItem[]): IOrderItem[] | null => {
  try {
    const orderItemsCountByPartNumber = countBy(orderItems, 'partNumber')
    const uniqOrderItemsByPartNumber = uniqBy(orderItems, 'partNumber')
    const orderItemWithQuantity = uniqOrderItemsByPartNumber.map((oi: IOrderItem) => ({
      ...oi,
      quantity: orderItemsCountByPartNumber[oi.partNumber].toString(),
    }))
    return orderItemWithQuantity
  } catch (e: any) {
    Log.error('Could not get grouped order items by partnumber', e)
    return null
  }
}

/**
 * function to parse order items in cart recap according to product type
 * @param { Cart } cart current cart session info
 * @param { IOrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderItems = (
  //cart: Cart,
  orderItems: IOrderItem[] | null,
  updatedItemId?: string,
  updatedItemQuantity?: string
): IOrderItem[] | null => {
  try {
    const orderItemsNew = mapAttributesIntoProductAttributes(orderItems)
    const defaultOrderItems: IOrderItem[] | null = !!orderItemsNew
      ? orderItemsNew.filter(oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute']))
      : null
    const clOrderItems: IOrderItem[] | null = !!orderItemsNew
      ? getGroupedOrderItemsByAttrCorrelation(
          orderItemsNew?.filter(oi => isContactLensesProduct(oi)),
          'xitem_field1'
        )
      : null

    const clAccessoriesOrderItems: IOrderItem[] | null = !!orderItemsNew
      ? getClAccessoriesGroupedOrderItemsByPartNumber(
          orderItemsNew?.filter(oi => isCLAccessoriesProduct(oi)),
          updatedItemId,
          updatedItemQuantity
        )
      : null

    const rxItems: IOrderItem[] | null = !!orderItemsNew
      ? parseRxOrderItems(orderItemsNew.filter(oi => isRox(oi['orderItemExtendAttribute'])))
      : null

    const rest = orderItemsNew?.filter(
      oi =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parsed order items', e)
    return null
  }
}

const mapAttributesIntoProductAttributes = (orderItems: IOrderItem[] | null): IOrderItem[] | undefined => {
  return orderItems?.map(item => {
    return {
      ...item,
      productAttributes: getAllProductAttributes(item.attributes),
    }
  })
}

/**
 * function to parse order items in cart recap according to product type
 * @param { IOrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderRecapItems = (
  //_cart: Cart,
  orderItems: IOrderItem[] | null,
  updatedItemId?: string,
  updatedItemQuantity?: string
): IOrderItem[] | null => {
  try {
    const orderItemsNew = mapAttributesIntoProductAttributes(orderItems)
    const defaultOrderItems: IOrderItem[] | null = !!orderItemsNew
      ? getGroupedOrderItemsByPartNumber(
          orderItemsNew?.filter(oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute']))
        )
      : null
    const clOrderItems: IOrderItem[] | null = !!orderItemsNew
      ? getGroupedOrderItemsByAttrCorrelation(
          orderItemsNew?.filter(oi => isContactLensesProduct(oi)),
          'xitem_field1'
        )
      : null

    const clAccessoriesOrderItems: IOrderItem[] | null = !!orderItemsNew
      ? getClAccessoriesGroupedOrderItemsByPartNumber(
          orderItemsNew?.filter(oi => isCLAccessoriesProduct(oi)),
          updatedItemId,
          updatedItemQuantity
        )
      : null

    const rxItems: IOrderItem[] | null = !!orderItemsNew
      ? parseRxOrderItems(orderItemsNew.filter(oi => isRox(oi['orderItemExtendAttribute'])))
      : null

    const rest = orderItemsNew?.filter(
      oi =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parsed order recap items', e)
    return null
  }
}

/**
 * function to aggregate order items by attribute correlation
 * @param { IOrderItem[] } orderItems current cart order items list
 * @param { string } attribute attribute string to look for in other items
 */
export const getGroupedOrderItemsByAttrCorrelation = (
  orderItems: IOrderItem[],
  attribute: string
): IOrderItem[] | null => {
  try {
    const filteredOrderItems = orderItems.filter(orderItem => !!orderItem[attribute])
    const groupedOrderItems: IOrderItem[] = []
    filteredOrderItems?.map((filteredItem, i) => {
      const found = filteredOrderItems.find(
        item => item?.orderItemId !== filteredItem?.orderItemId && item?.orderItemId === filteredItem?.[attribute]
      )

      if (!!found) {
        filteredItem = {
          ...filteredItem,
          groupedItem: true,
          sibilingOrderItem: found,
          groupedOrderItemsId: [filteredItem.orderItemId, found.orderItemId],
        }
        groupedOrderItems.push(filteredItem)
        delete filteredOrderItems[i]
      }
    })
    return [...groupedOrderItems, ...orderItems.filter(orderItem => !orderItem[attribute])]
  } catch (e: any) {
    Log.error('Could not get grouped order items', e)
    return null
  }
}

export const getClAccessoriesGroupedOrderItemsByPartNumber = (
  orderItems: IOrderItem[],
  updatedId?: string,
  updatedQuantity?: string
): IOrderItem[] | null => {
  try {
    const ClOrderItems = orderItems
    const filteredOrderItems: IOrderItem[] = []

    // grouped items by partNumber
    const groupByPartNumber = ClOrderItems.reduce((group, product) => {
      const { partNumber, freeGift } = product
      const key = `${partNumber}_${freeGift}`
      group[key] = group[key] ?? []
      group[key].push(product)
      return group
    }, {})

    Object.keys(groupByPartNumber).forEach(function (partNumber) {
      // if there are sibilings, sum quantity and price on each item and group the ids
      if (groupByPartNumber[partNumber].length > 1) {
        let filteredItem: any = {}
        const orderItemsId: string[] = []
        let itemQuantity = 0
        let itemPrice = 0

        groupByPartNumber[partNumber].map((item, i) => {
          itemQuantity = parseInt(item.quantity) + itemQuantity
          itemPrice = parseFloat(item.orderItemPrice) + itemPrice
          if (i === 0) {
            filteredItem = item
          }
          const foundSameOrderId = orderItemsId.includes(item?.orderItemId)
          if (!foundSameOrderId) {
            orderItemsId.push(item?.orderItemId)
          }
        })

        const found = orderItemsId.find(id => id === updatedId)
        // if i'm updating from the select label
        if (!!found && updatedQuantity) {
          itemQuantity = parseInt(updatedQuantity)
          itemPrice = itemQuantity * parseFloat(filteredItem.unitPrice)
        }

        filteredItem = {
          ...filteredItem,
          quantity: itemQuantity.toString(),
          orderItemPrice: itemPrice.toString(),
          groupedOrderItemsId: orderItemsId,
        }
        filteredOrderItems.push(filteredItem)
      } else {
        const singleItem = groupByPartNumber[partNumber][0]
        filteredOrderItems.push(singleItem)
      }
    })

    return [...filteredOrderItems]
  } catch (e: any) {
    Log.error('Could not get grouped order items', e)
    return null
  }
}

export const filterClData = (
  data: ContactLensData | null,
  isDominanceFieldActive: boolean,
  attributeKeys?: string[]
): ContactLensData | null => {
  try {
    !!data &&
      Object.keys(data)
        ?.filter(clAttr => attributeKeys?.includes(clAttr))
        .map(filteredAttr => {
          if (filteredAttr !== 'x_dominance' || !isDominanceFieldActive) {
            delete data[filteredAttr]
          }

          return data
        })

    return data
  } catch (e: any) {
    Log.error('Could not filter contact lens order item data', e)
    return null
  }
}

export const formatOrderRecapItemPrices = (orderItem: IOrderItem) => {
  let productUnitPrice: number | null = null
  let productOrderItemPrice: number | null = null
  let x_offerpriceRx: number | null = null
  let x_offerDiscountpriceRx: number | null = null

  try {
    productOrderItemPrice = orderItem?.groupedItem
      ? parseFloat(
          sum([Number(orderItem.orderItemPrice), Number(orderItem.sibilingOrderItem?.orderItemPrice)]).toFixed(2)
        ) || null
      : parseFloat(orderItem.orderItemPrice) || null
    productUnitPrice = orderItem.unitPrice ? parseFloat(orderItem.unitPrice) : null
    x_offerpriceRx = orderItem?.x_offerpriceRx ? parseFloat(orderItem.x_offerpriceRx) : null
    x_offerDiscountpriceRx = orderItem?.x_offerDiscountpriceRx ? parseFloat(orderItem?.x_offerDiscountpriceRx) : null
  } catch (e) {
    Log.error('Could not parse order item price')
  }

  return {
    productUnitPrice,
    productOrderItemPrice,
    x_offerpriceRx,
    x_offerDiscountpriceRx,
  }
}

export const formatOrderRecapItemClquantity = (quantity: string): string | null => {
  let contactLensquantity: string | null = null

  try {
    contactLensquantity = parseInt(quantity).toString()
  } catch (e) {
    contactLensquantity = null
    Log.error('Could not parse order item quantity')
  }

  return contactLensquantity
}

export const getOrderItemContactLensesData = (
  orderItem: IOrderItem,
  filteredKeys?: string[]
): OrderItemContactLensData | null => {
  let leftEyeContactLensData: {
    data: ContactLensData | null
    quantity: string | null
    orderItemId: string | null
  } | null = null

  let rightEyeContactLensData: {
    data: ContactLensData | null
    quantity: string | null
    orderItemId: string | null
  } | null = null

  try {
    const isDominanceFieldActive = isDominanceSectionActive(orderItem)
    const contactLensCurrentOrderItemData = !!orderItem?.orderItemExtendAttribute
      ? getContactLensOrderItemData_Old(orderItem)
      : null

    const contactLensSibilingOrderItemData = !!orderItem?.sibilingOrderItem?.orderItemExtendAttribute
      ? getContactLensOrderItemData_Old(orderItem?.sibilingOrderItem)
      : null

    leftEyeContactLensData =
      !!contactLensCurrentOrderItemData && Object.values(contactLensCurrentOrderItemData)?.includes('LCON')
        ? {
            data: contactLensCurrentOrderItemData,
            quantity: orderItem.quantity,
            orderItemId: orderItem.orderItemId || null,
          }
        : {
            data: contactLensSibilingOrderItemData,
            quantity: orderItem?.sibilingOrderItem?.quantity || null,
            orderItemId: orderItem?.sibilingOrderItem?.orderItemId || null,
          }

    rightEyeContactLensData =
      !!contactLensCurrentOrderItemData && Object.values(contactLensCurrentOrderItemData)?.includes('RCON')
        ? {
            data: filterClData({ ...contactLensCurrentOrderItemData }, isDominanceFieldActive, filteredKeys),
            quantity: orderItem.quantity || null,
            orderItemId: orderItem.orderItemId || null,
          }
        : {
            data: filterClData({ ...contactLensSibilingOrderItemData }, isDominanceFieldActive, filteredKeys),
            quantity: orderItem.sibilingOrderItem?.quantity || null,
            orderItemId: orderItem.sibilingOrderItem?.orderItemId || null,
          }

    const finalOrderItemContactLensesData = {
      left: {
        data: filterClData({ ...leftEyeContactLensData.data }, isDominanceFieldActive, filteredKeys),
        quantity: leftEyeContactLensData?.quantity,
        orderItemId: leftEyeContactLensData?.orderItemId,
      },
      right: {
        data: filterClData({ ...rightEyeContactLensData.data }, isDominanceFieldActive, filteredKeys),
        quantity: rightEyeContactLensData?.quantity,
        orderItemId: rightEyeContactLensData?.orderItemId,
      },
    }

    return finalOrderItemContactLensesData
  } catch (e) {
    Log.error('Could not parse contact lenses data')
    return null
  }
}

export const getAllCartItemsOrderIds = (data?: IOrderItem[] | OrderItemWithRoxProps[]): string[] | null => {
  try {
    return flattenDeep(
      uniq(
        data?.map((oi: IOrderItem | OrderItemWithRoxProps) => {
          return isContactLensesProduct(oi)
            ? [oi.orderItemId, oi.sibilingOrderItem?.orderItemId || '']
            : [
                oi.roxableServices
                  ?.filter(rs => {
                    return isRxLens(rs.orderItemExtendAttribute)
                  })
                  ?.map((rs: IOrderItem) => {
                    return rs.orderItemId
                  }) || '',
              ]
        }) || []
      )
    ).filter(id => id !== '')
  } catch (e) {
    Log.error('Could not get order item ids')
    return null
  }
}

export const getSingleItemsOrderIds = (oi?: IOrderItem | OrderItemWithRoxProps): string[] | null => {
  try {
    return flattenDeep(
      uniq(
        isContactLensesProduct(oi)
          ? [oi?.orderItemId || '', oi?.sibilingOrderItem?.orderItemId || '']
          : [
              oi?.roxableServices
                ?.filter(rs => {
                  return isRxLens(rs.orderItemExtendAttribute)
                })
                ?.map((rs: IOrderItem) => {
                  return rs.orderItemId
                }) || '',
            ]
      )
    ).filter(id => id !== '')
  } catch (e) {
    Log.error('Could not get single order item ids')
    return null
  }
}

export const getOrderItemsMap = (orderItems: IOrderItem[]): ParsedOrderItemsMapByItemType | null => {
  try {
    const parsedOrderItems = getParsedOrderRecapItems(orderItems)
    const clOrderItems: IOrderItem[] | null = parsedOrderItems?.filter(oi => isContactLensesProduct(oi)) || []
    const rxOrderItems: IOrderItem[] | null =
      parsedOrderItems?.filter(oi => isRox(oi['orderItemExtendAttribute'])) || []
    const sunOrderItems: IOrderItem[] | null =
      parsedOrderItems?.filter(oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])) || []
    const clAccessoriesOrderItems: IOrderItem[] | null =
      parsedOrderItems?.filter(oi => isCLAccessoriesProduct(oi)) || []
    const defaultItems = orderItems?.filter(
      oi =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return {
      rx: rxOrderItems,
      cl: clOrderItems,
      'cl-acc': clAccessoriesOrderItems,
      sun: sunOrderItems,
      default: defaultItems,
    }
  } catch (e) {
    Log.error('Could not parse order items')
    return null
  }
}

export const getPrescriptionItemsMap = (
  orderItems: IOrderItem[] | OrderItemWithRoxProps[],
  filterPrescriptionNeededItems?: boolean,
  filterPrescriptionItemType?: PrescriptionItemType,
  isDesktop?: boolean
): PrescriptionMacroGroup[] | null => {
  let prescriptionMacroGroups: PrescriptionMacroGroup[] = []
  let orderItemsPrescriptionMap: PrescriptionItemsMapByType = []
  try {
    const parsedOrderItems = getParsedOrderRecapItems(orderItems)
    const clOrderItems: IOrderItem[] | null = filterPrescriptionNeededItems
      ? parsedOrderItems?.filter(oi => isContactLensesProduct(oi) && !oi.prescriptionUploaded) || null
      : parsedOrderItems?.filter(oi => isContactLensesProduct(oi)) || null

    const rxOrderItems: IOrderItem[] | null = filterPrescriptionNeededItems
      ? parsedOrderItems?.filter(oi => isRox(oi['orderItemExtendAttribute']) && !oi.prescriptionUploaded) || null
      : parsedOrderItems?.filter(oi => isRox(oi['orderItemExtendAttribute'])) || null

    rxOrderItems &&
      rxOrderItems.length > 0 &&
      orderItemsPrescriptionMap.push({
        data: {
          type: 'rx',
          items: rxOrderItems || [],
        },
      })

    clOrderItems &&
      clOrderItems.length > 0 &&
      orderItemsPrescriptionMap.push({
        data: {
          type: 'cl',
          items: clOrderItems || [],
        },
      })

    orderItemsPrescriptionMap = !!filterPrescriptionItemType
      ? orderItemsPrescriptionMap.filter(group => group.data.type === filterPrescriptionItemType)
      : orderItemsPrescriptionMap
    prescriptionMacroGroups = orderItemsPrescriptionMap.map((group, i) => {
      return {
        id: i,
        selectedItemIndex: 0,
        isSamePrescriptionSelected: isDesktop ? true : false,
        orderItems: group.data.items || [],
        skippedrPrescriptionItems: [],
        prescriptionData: {
          orderItemId: !!group.data.items ? getAllCartItemsOrderIds(group.data.items || [])?.join(',') : '',
        },
        completedPrescriptionItems: [],
        itemType: group.data.type,
      }
    })
    return prescriptionMacroGroups
  } catch (e) {
    Log.error('Could not parse prescription order items')
    return null
  }
}

export const getFlattenParsedOrderItemsList = (
  obj: ParsedOrderItemsMapByItemType
): IOrderItem[] | OrderItemWithRoxProps[] | null => {
  const flattenItemsMap: IOrderItem[] | OrderItemWithRoxProps[] = []
  try {
    for (const key in obj) {
      if (!!obj[key]) {
        const orderItems = obj[key] || []
        flattenItemsMap.push(...orderItems)
      }
    }
    return flattenItemsMap
  } catch (e) {
    return null
  }
}

export const isOrderComplete = (orderStatus: string, orderPaymentMethodId): boolean => {
  try {
    switch (true) {
      case [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription].includes(orderStatus) &&
        orderPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY:
        return true

      case [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription, ORDER_STATUS.PendingPayment].includes(orderStatus):
        return true
      default:
        return false
    }
  } catch (e) {
    return false
  }
}

export const fetchOrderItemDetailsByIds = (catentryIdList: string[], storeId: string) => {
  const promiseArray: Promise<any>[] = []
  const ids = chunk(catentryIdList, 50)
  ids.forEach(id => {
    const params = {
      id,
      profileName: 'LX_findItemByIds_Details',
    }
    promiseArray.push(
      RequestService.request({
        extraParams: { siteContextKey: 'search' },
        method: 'GET',
        path: '/api/v2/products',
        queryParams: params,
        pathParams: { storeId },
      })
    )
  })
  return Promise.all(promiseArray).then(rs => {
    let contents = []
    rs.forEach(r => {
      if (r?.contents) {
        contents = contents.concat(r.contents)
      }
    })
    return contents
  })
}

export const fetchOrderItemsPrescriptionData = async (
  orderItems: IOrderItem[] | OrderItemWithRoxProps[],
  storeId: string,
  orderId: string
): Promise<IOrderItem[] | OrderItemWithRoxProps[]> => {
  const orderItemsPromises = orderItems?.map(async (oi: IOrderItem | OrderItemWithRoxProps) => {
    let orderItemObj: IOrderItem | OrderItemWithRoxProps = {
      ...oi,
    }

    if (isRxLens(oi.orderItemExtendAttribute) || isClOrderItem(oi.orderItemExtendAttribute)) {
      try {
        let orderPrescriptionDetailsRes: PrescriptionDetailsResponse | null
        let orderPrescriptionDetailsImage: any | null

        try {
          orderPrescriptionDetailsRes = isRxLens(oi.orderItemExtendAttribute)
            ? await RequestService.request({
                method: 'GET',
                path: `/store/${storeId}/prescription/orderId/${orderId}/orderItemId/${oi.orderItemId}`,
              })
            : null
          !!orderPrescriptionDetailsRes?.results
            ? (orderItemObj = {
                ...orderItemObj,
                prescriptionDetails: {
                  data: orderPrescriptionDetailsRes.results || null,
                },
              })
            : (orderItemObj = oi)
        } catch (error: any) {
          Log.error('Could not retrieve order details prescription details', error)
          throw new Error(error)
        }
        try {
          //only rx have prescription details
          //cl only show prescription image
          const shouldGetPrescriptionFile: boolean =
            (isRxLens(oi.orderItemExtendAttribute) &&
              !!oi.orderItemExtendAttribute.find(
                a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['RX_FILE_UPLOAD_DATE']
              ) &&
              !!orderPrescriptionDetailsRes?.results.fileName) ||
            (isClOrderItem(oi.orderItemExtendAttribute) &&
              !!oi.orderItemExtendAttribute.find(
                a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['RX_FILE_UPLOAD_DATE']
              )) ||
            false

          orderPrescriptionDetailsImage =
            (shouldGetPrescriptionFile &&
              (await RequestService.requestForRtk({
                method: 'GET',
                path: `/store/${storeId}/contactLens/rxUploadedFile/orderId/${orderId}/orderItemId/${oi.orderItemId}`,
                extraParams: {
                  responseType: 'arraybuffer',
                },
              }))) ||
            null

          !!orderPrescriptionDetailsImage
            ? (orderItemObj = {
                ...orderItemObj,
                prescriptionDetails: {
                  ...orderItemObj.prescriptionDetails,
                  file: {
                    content: orderPrescriptionDetailsImage.data || null,
                    type: orderPrescriptionDetailsImage.headers['content-type'] || null,
                  },
                },
              })
            : (orderItemObj = orderItemObj)
          return orderItemObj
        } catch (error: any) {
          Log.error('Could not retrieve order details prescription image', error)
          throw new Error(error)
        }
      } catch (error: any) {
        Log.error('Could not retrieve order details prescription data', error)
        //Cannot retrieve prescription details; return order items as-is
        return orderItemObj
      }
    } else {
      //Order item has no prescription; return order items as-is
      return orderItemObj
    }
  })
  const res = await Promise.all(orderItemsPromises)
  return res
}

export const getOrderItemCatEntries = async (
  catentriesIdArray: string[],
  storeId: string
): Promise<IOrderSliceState['catentries'] | undefined> => {
  try {
    let catentries: IOrderSliceState['catentries']
    const catentryIdList = [...new Set(catentriesIdArray)]
    const contents = await fetchOrderItemDetailsByIds(catentryIdList, storeId)
    if (contents) {
      catentries = contents.reduce((acc, p: any) => {
        acc[p.id] = {
          ...p,
          productAttributes: getAllProductAttributes(p.attributes),
        }
        return acc
      }, {})
    }
    return catentries
  } catch (error: any) {
    Log.error('Could not retrieve products', error)
    //Cannot retrieve catentry details; return order items as-is
    return undefined
  }
}

/**
 * function to retrive returned status of order.
 * It takes first status, but if order has more then one statuses and one of is `CAN` we have to return other status.
 * Example states = [CAN, CLO] => CLO
 * @param { Order } order current order
 */
export const getOrderReturnedStatus = (order: Order | IOrderItem): string | null => {
  const isOrderItem = (order: Order | IOrderItem): order is Order => 'orderExtendAttribute' in order

  let orderStatuses: Attribute<string>[] = []
  if (isOrderItem(order)) {
    orderStatuses = order.orderExtendAttribute.filter(s => s.attributeName.includes('RMAStatus'))
  } else {
    orderStatuses = order?.orderItemExtendAttribute?.filter(s => s.attributeName.includes('RMAItemStatus'))
  }

  const orderCANstatus = orderStatuses?.find(s => s.attributeValue === 'CAN')

  const status =
    orderStatuses?.length > 1 && orderCANstatus
      ? orderStatuses.find(s => s.attributeValue !== 'CAN')?.attributeValue
      : orderStatuses?.[0]?.attributeValue

  return status || null
}

const getDiscounts = (orderItem?: IOrderItem | null) => {
  const discounts = orderItem?.adjustment?.filter(({ usage }) => usage !== 'Shipping Adjustment') || []

  return discounts
}

export const getPromoCodeDiscount = (orderItem?: IOrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(({ code }) => !code.includes('CL_CUSTOM_PROMO'))

  return promoCode ? Number(promoCode?.amount.replace('-', '')) : 0
}

export const getAnnualDiscounts = (orderItem?: IOrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const hasSupplyData = getAnnualSupplyBadge(orderItem) || ''

  const hasDiscount = hasSupplyData && discounts.find(({ code }) => code.includes('CL_CUSTOM_PROMO'))

  return hasDiscount ? Number(hasDiscount.amount.replace('-', '')) : 0
}

export const isDiscountOnItemLevel = (orderItem?: IOrderItem | null) => {
  if (!orderItem) return false
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(({ code }) => !code.includes('CL_CUSTOM_PROMO'))

  return promoCode?.displayLevel === 'IOrderItem'
}

/**
 * Collects an assembly of item delivery dates from order history `x_delivery_estimated_dates`.
 *
 * @param {Object} xDeliveryEstimatedDates - The `x_delivery_estimated_dates` object, containing delivery estimates.
 * @returns {Record<string, Date[]>} array of estimated delivery dates.
 */

export const getOrderDeliveryDatesFromHistory = (xDeliveryEstimatedDates: string): Record<string, Date[]> => {
  const dates: Record<string, Date[]> = {
    [ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.EXPECTED_DELIVERY_DATE]: [],
    [ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.ADJUST_ESTIMATED_DATE]: [],
    [ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.ESTIMATED_SHIPPING_DATE]: [],
  }

  if (!xDeliveryEstimatedDates) {
    return dates
  }

  let parsedDates

  try {
    parsedDates = JSON.parse(xDeliveryEstimatedDates)
  } catch {
    return dates
  }

  if (!Array.isArray(parsedDates) || parsedDates.length === 0) {
    return dates
  }

  parsedDates.forEach((item: Record<string, string>) => {
    Object.keys(dates).forEach(key => {
      const rawDate = item[key] ? item[key].replaceAll('-', '/') : null
      const parsedDate = rawDate ? parse(rawDate, DATE_FORMAT_PATTERN['dd/MM/yyyy'], new Date()) : null
      if (parsedDate && parsedDate.toString() !== 'Invalid Date') {
        dates[key].push(parsedDate)
      }
    })
  })

  return dates
}
/**
 *
 * Collects an assembly of item delivery dates
 *
 * @param orderItems
 * @returns
 */
export const getOrderDeliveryDatesFromItems = (orderItems: IOrderItem[]): Record<string, Date[]> => {
  const itemDeliveryDates: Record<string, Date[]> = {
    [ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.EXPECTED_DELIVERY_DATE]: [],
    [ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.ADJUST_ESTIMATED_DATE]: [],
    [ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.ESTIMATED_SHIPPING_DATE]: [],
  }

  orderItems.forEach(item => {
    Object.keys(itemDeliveryDates).forEach(key => {
      const attributeValue = getOrderItemExtendAttributeValue(item, key)
      const estimatedDate = attributeValue.replaceAll('-', '/')
      const parsedDate = estimatedDate ? parse(estimatedDate, DATE_FORMAT_PATTERN['dd/MM/yyyy'], new Date()) : null
      if (parsedDate && parsedDate.toString() !== 'Invalid Date') {
        itemDeliveryDates[key].push(parsedDate)
      }
    })
  })

  return itemDeliveryDates
}

/**
 * Filters the order items dates for the furthest dates
 *
 * @param orderItems
 * @returns
 */
export const getOrderDeliveryDatesFurthest = (deliveryDates: Record<string, Date[]>): Record<string, Date | null> => {
  const furthestDates = Object.keys(deliveryDates).reduce((acc, key) => {
    const dates = deliveryDates[key]
    const furthestDate = dates.length ? max(dates) : null
    acc[key] = furthestDate
    return acc
  }, {})

  return furthestDates
}

/**
 * Filters the order items dates for the closest dates
 *
 * @param deliveryDates
 * @returns
 */
export const getOrderDeliveryDatesClosest = (deliveryDates: Record<string, Date[]>): Record<string, Date | null> => {
  const closestDates = Object.keys(deliveryDates).reduce((acc, key) => {
    const dates = deliveryDates[key]
    const closestDate = dates.length ? min(dates) : null
    acc[key] = closestDate
    return acc
  }, {})

  return closestDates
}

export const getOrderItemExtendAttributeValue = (orderItem: IOrderItem, attributeName: string): string => {
  return orderItem?.orderItemExtendAttribute?.find(attr => attr.attributeName === attributeName)?.attributeValue || ''
}

/**
 * Checks if an order has promo code or discount adjustments
 *
 * @param {SummaryOrder} order
 * @returns {boolean} true if the order has a promo code or discount adjustment, otherwise false
 */
export const orderHasDiscountAdjustments = (order: SummaryOrder): boolean => {
  return (
    !!order?.promotionCode?.[0]?.code ||
    order?.adjustment?.some(adjustment => adjustment.usage === AdjustmentTypes.Discount)
  )
}

/**
 * Returns true if the order had promo adjustments before being placed
 * Caution: the flag disappears after the order is placed
 *
 * @param {SummaryOrder } order
 * @returns {boolean} true if x_promoHasAdjustment is true
 */
export const promoHasAdjustments = (order: SummaryOrder): boolean => {
  return order.x_promoHasAdjustment === 'true'
}

/**
 * Checks if an order has an active promo code
 *
 * @param {SummaryOrder} order
 * @returns {boolean} true if a at least one promo code exists, otherwise false
 */
export const orderHasPromoCode = (order: SummaryOrder): boolean => {
  return !!order?.promotionCode?.length
}

/**
 * Checks if an order contains a promo via adjustments or an active promo code
 *
 * @param {SummaryOrder } order
 * @returns {boolean} true if the order has promo flag or an active promo code, otherwise false
 */
export const orderContainsPromo = (order: SummaryOrder): boolean => {
  return promoHasAdjustments(order) || orderHasPromoCode(order)
}

export const formatPromotionCodeAdjustmentName = (promotionCode?: PromotionCode): string => {
  return `${promotionCode?.code}-${promotionCode?.associatedPromotion?.[0]?.promotionId}`
}

/**
 * Gets the total promo code discount amount for an order.
 *
 * @param {SummaryOrder} order
 * @param {number} giftProductsDiscounts Discount applied to gift products
 * @returns {number} Total discount amount including gift product discounts
 */
export const getPromoCodeDiscountAmount = (order: SummaryOrder, promoCodeAdjustmentId: string): number => {
  // No promo & no discount adjustments - nothing to calculate
  if (!orderHasPromoCode(order) || !orderHasDiscountAdjustments(order)) {
    return 0
  }

  const promoAdjustment = order?.adjustment?.find(adjustment => adjustment?.code === promoCodeAdjustmentId)

  return promoAdjustment ? Number(promoAdjustment.amount) : 0
}

export const getNoPromoCodeDiscountAmount = (order: SummaryOrder, promoCodeAdjustmentId: string): number => {
  // Promo, but no discount adjustments - nothing to calculate
  if (!orderHasDiscountAdjustments(order)) {
    return 0
  }

  const noPromoDiscounts = order.adjustment?.reduce(
    (total, adjustment) =>
      adjustment?.usage === AdjustmentTypes.Discount && adjustment?.code !== promoCodeAdjustmentId
        ? total + +adjustment?.amount
        : total,
    0
  )

  return noPromoDiscounts
}

export const getShippingDiscountAmount = (order: SummaryOrder): number => {
  const shippingDiscountAmount =
    order.adjustment?.find(adjustment => adjustment?.usage === AdjustmentTypes.ShippingAdjustment)?.amount ?? 0
  return +shippingDiscountAmount
}

export const getPromoCodeId = order => {
  return (
    order?.promotionCode?.[0]?.associatedPromotion?.[0]?.promotionId ||
    order?.promotionCode?.[0]?.associatedPromotion?.[0]?.promotionIdentifier?.uniqueID
  )
}

export const calculateGiftProductsDiscounts = (parsedOrderItems: IOrderItem[]): number => {
  if (parsedOrderItems?.length === 0) return 0

  return parsedOrderItems?.reduce((acc: number, item: IOrderItem): number => {
    return item?.freeGift === 'true' ? acc + +item?.orderItemPrice : acc
  }, 0)
}

export const getInsuranceDiscountValue = (order: SummaryOrder): string => {
  const discountTotalAmount =
    Number(
      order?.orderExtendAttribute?.find(attribute => attribute.attributeName === INSURANCE_DISCOUNT_ATTRIBUTE_NAME)
        ?.attributeValue
    ) ?? 0

  return isNaN(discountTotalAmount) ? '0' : `-${discountTotalAmount}`
}

export const getInsurerName = (order: SummaryOrder): string => {
  const insurerName =
    order?.orderExtendAttribute?.find(attribute => attribute.attributeName === INSURANCE_NAME_ATTRIBUTE_NAME)
      ?.attributeValue ?? ''

  return insurerName
}

const isDominanceSectionActive = (orderItem: IOrderItem): boolean => {
  const naturalDominanceFieldOptions: string[] = JSON.parse(
    getNaturalAttribute(orderItem, PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_DOMINANCE)
  )
  const isDominanceSectionActive = naturalDominanceFieldOptions.includes('YES')

  return isDominanceSectionActive
}

export const showPromoApplied = ({ order, hasDiscount, viewableDiscountsTotal }: ShowPromoAppliedArgs): boolean => {
  return orderHasPromoCode(order) || (!!hasDiscount && viewableDiscountsTotal < 0)
}

export const calculateDiscountLabelAmount = ({
  viewableDiscountsTotal = 0,
  order,
  shipping,
  shippingThresholdReached,
  shippingAdjustment,
}: CalculateDiscountLabelAmountArgs): number => {
  if (viewableDiscountsTotal) {
    return viewableDiscountsTotal
  }

  if (orderHasPromoCode(order) && shipping === 0) {
    if (shippingThresholdReached) {
      return 0
    } else {
      return shippingAdjustment
    }
  }
  return 0
}

export const isOneHundredPercentDiscountWithFreeShipping = (
  shippingAdjustment: number,
  subtotalWithDiscounts: number,
  grandTotal: number,
  order: SummaryOrder
): boolean => {
  return shippingAdjustment + subtotalWithDiscounts === grandTotal && orderHasPromoCode(order)
}

export const isOneHundredPercentDiscountWithoutFreeShipping = (
  subtotalWithDiscounts: number,
  grandTotal: number,
  order: SummaryOrder
): boolean => {
  return subtotalWithDiscounts === 0 && grandTotal > 0 && orderHasPromoCode(order)
}

// TODO: Temporary fix - handle "special price applied" in cooperation with BE in the future
export const isSpecialPriceApplied = (customerSegments: string[]): boolean => {
  return customerSegments.some(segment => SPECIAL_PRICE_SEGMENTS.includes(segment))
}

export const removePartiallyDiscontinuedSKUsKey = (): void => {
  sessionStorageUtil.remove(PARTIALLY_DISCONTINUED_SKUS_KEY)
}

export const isMtoOrderItem = (orderItem?: IOrderItem) => orderItem?.productAttributes?.['CL_MTO_ENABLED'] === 'True'
