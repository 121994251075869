import { isEmpty, toNumber } from 'lodash-es'
import {
  AddressInfo,
  KeyValue,
  MarketingSpotData,
  SubscriptionInfoSummary,
  SubscriptionItem,
  SubscriptionRecurrency,
  Wallet,
} from '@typesApp/productSubscription'
import { isJson } from '@utils/helpers'
import DateService from '@services/DateService'
import orderService from '@foundation/apis/transaction/order.service'
import { PRODUCT_TYPES_KEYS } from '@constants/product'
import { IOrderItem, Cart, IOrderDetails, Adjustment, Order } from '@typesApp/order'
import { isContactLensesProduct, isClAccessoriesProduct } from '@utils/product'
import {
  DEFAULT_DATE_FORMAT,
  SUBSCRIPTION_DISCOUNT_ADJUSTMENTS,
  SUBSCRIPTION_FALLBACK_RECURRENCY,
  SUBSCRIPTION_RECURRENCY_STRING,
  SUBSCRIPTION_STATES,
  SUBSCRIPTION_TERMS_AND_CONDITIONS_ESPOT,
} from './constants'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '@constants/order'
import { isMtoOrderItem } from '@utils/order'
import dayjs from 'dayjs'
import storeUtil from '@utils/storeUtil'
import { findByCountryCode } from '../PhoneNumberField/CountryCodeMap'
import { SiteInfo, ProductSubscriptionReducerState } from '@redux/reducers'

type PaymentInfo = {
  issuer: string
  protectedCCNumber: string
  expirationDate: string
}

type ProductData = {
  productImgUrl?: string | null
  x_contactLens?: {
    x_productId?: string | null
    x_baseCurve?: string | null
    x_cylinder?: string | null
    x_color?: string | null
    x_spherePower?: string | null
    x_axis?: string | null
    x_addition?: string | null
    x_partNumber?: string | null
    x_eye?: string | null
    x_dominance?: string | null
    x_diameter?: string | null
  }
  productUrl?: string | null
  productName?: string | null
}

/**
 * Helper to find shipping address
 *
 * @param userData array of key value pairs
 * @returns the shipping address
 */
export function getShippingAddress(userData: KeyValue[]) {
  const shippingString = userData.find(item => item.key === 'addresses')?.value as string
  return isJson(shippingString) ? JSON.parse(shippingString)[0] : {}
}

/**
 * Helper to find shipping address JSON string in userData array and format it to human readable address
 *
 * @param userData array of key value pairs
 * @returns formatted shipping string
 */
export function getShippingString(userData: KeyValue[]): string {
  const shipping = getShippingAddress(userData)
  return !isEmpty(shipping)
    ? `${shipping?.firstName} ${shipping?.lastName}, ${shipping?.addressLine?.join(' ').trim()}, ${shipping?.city}, ${
        shipping?.zipCode
      }, ${shipping?.country}`
    : ''
}

/**
 * Helper function that converts address object to an array of address lines
 * @param address {AddressInfo} address object
 * @returns array of formatted strings
 */
export function getAddressArray(address?: AddressInfo): string[] {
  if (!address || isEmpty(address)) return []
  const { firstName, lastName, country, state, city, zipCode, addressLine } = address
  const countryObj = findByCountryCode(country)
  const addressLineStr = addressLine.filter(item => !!item).join(' ')
  return [`${firstName} ${lastName}`, addressLineStr, `${city}, ${state}, ${countryObj?.label || country} ${zipCode}`]
}

/**
 * Helper function that converts wallet data into array of formatted strings
 * @param wallet wallet object
 * @returns array of formatted strings
 */
export function getPaymentDetailsArray(wallet?: Wallet) {
  if (!wallet || isEmpty(wallet)) return []
  const { protectedCCNumber, expireDate, issuer } = wallet
  const expirationDateFormatted = DateService.format(expireDate, 'MM/yy')
  const numberFormatted = protectedCCNumber.replaceAll('*', '')
  return [issuer, `**** **** **** ${numberFormatted}`, expirationDateFormatted]
}

/**
 * Helper to find wallet information JSON string in userData array and return parsed payment information
 *
 * @param userData array of key value pairs
 * @returns parsed payment information object
 */
export function getPaymentMethod(userData: KeyValue[]): PaymentInfo {
  const paymentString = userData.find(item => item.key === 'wallet')?.value as string
  const paymentObj = isJson(paymentString) ? JSON.parse(paymentString) : {}
  return {
    issuer: paymentObj?.issuer || '',
    protectedCCNumber: paymentObj?.protectedCCNumber || '',
    expirationDate: paymentObj?.expireDate || '',
  }
}

/**
 * Helper to parse json string into json object, which unescapes unicode special characters to make them browser compatible
 *
 * @param str
 * @returns
 */
export function parseProductString(str: string): ProductData {
  // NOTE: replace with regex is used to unescape unicode characters so that they properly render in the browser
  return isJson(str) ? JSON.parse(str.replace(/\\{2,}/g, '\\'))[0] : {}
}

/**
 * Helper to find product information JSON string in userData array and return parsed product object
 *
 * @param userData array of key value pairs
 * @returns parsed product object
 */
export function getProductData(userData: KeyValue[]): ProductData {
  const productString = userData.find(item => item.key === 'products')?.value as string
  return parseProductString(productString)
}

/**
 * Helper to find next delivery date string in userData array and return formatted date string
 * @param userData array of key value pairs
 * @param locale string
 * @returns formatted date string
 */
export function getDeliveryDate(userData: KeyValue[], locale: string): string {
  const deliveryString = userData.find(item => item.key === 'nextDeliveryDate')?.value as string
  return DateService.format(deliveryString, DEFAULT_DATE_FORMAT)
}

/**
 * Get the subscruption recurrency for a given order item
 * @param orderItem the specific order Item object
 * @returns the string representation of the subscription recurrency
 */
export const getSubscriptionRecurrency = (orderItem: IOrderItem | undefined): string => {
  return orderItem ? orderService.getSubscriptionRecurrency(orderItem.orderItemExtendAttribute) : ''
}
/**
 * Check if the order item is an active subscription
 * @param orderItem the specific order Item object
 * @returns a boolean representation if the order item is a subscription item
 */
export const getSubscriptionActive = (orderItem?: IOrderItem): boolean => {
  return !!getSubscriptionRecurrency(orderItem)
}

/**
 * Check if the order item is subscribed
 * @param orderItem the specific order Item object
 * @returns a boolean representation if the order item is subscribed
 */
export const isItemSubscribed = (orderItem: IOrderItem): boolean => {
  return !!orderService.getSubscriptionRecurrency(orderItem.orderItemExtendAttribute)
}

/**
 * Check if the cart has subscribed items
 * @param cart the current cart object
 * @param orderDetails the current order details object
 * @returns a boolean representation if the cart has one or more subscribed items
 */
export const hasSubscribedItemsInOrder = (cart: Cart, orderDetails: IOrderDetails | null): boolean => {
  if (orderDetails) {
    return (
      cart.orderId === orderDetails?.orderId &&
      orderDetails?.orderItem.filter(item => isItemSubscribed(item)).length > 0
    )
  }

  return !!cart.orderItem.filter(item => isItemSubscribed(item)).length
}

/**
 * Checks if order has subscription by checking presence of parent order id.
 * @param order the order or order details object
 * @returns a boolean representing if order is part of subscription
 */
export const isSubscriptionOrder = (order: IOrderDetails | Order): boolean =>
  !!order.orderExtendAttribute?.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.PARENT_ORDER_ID)

/**
 * Format the subscription recurrency for a given order item
 * @param orderItem the specific order Item object
 * @param useFallBack if true will return a predefined value when none found (ex: '2|MON')
 * @returns the formatted string representation of the subscription recurrency
 */
export const getFormattedSubscriptionRecurrency = (
  orderItem: IOrderItem | undefined,
  useFallBack?: boolean
): SubscriptionRecurrency => {
  let subscriptionFrequency = getSubscriptionRecurrency(orderItem)
  if (!subscriptionFrequency && useFallBack) {
    subscriptionFrequency = SUBSCRIPTION_FALLBACK_RECURRENCY
  }

  const [subscriptionValue, subscriptionInterval] = subscriptionFrequency.split(
    SUBSCRIPTION_RECURRENCY_STRING.SEPARATOR
  )

  return {
    fulfillmentInterval: subscriptionValue,
    fulfillmentIntervalUOM: subscriptionInterval,
  }
}

/**
 * Get the subscription info for he order item
 * @param orderItem the specific order Item object
 * @returns The subscription info for the order Item
 */
export const getOrderItemSubscriptionInfo = (orderItem: IOrderItem): SubscriptionInfoSummary => {
  let subscriptionFrequency = getSubscriptionRecurrency(orderItem)
  const [subscriptionValue, subscriptionInterval] = subscriptionFrequency.split(
    SUBSCRIPTION_RECURRENCY_STRING.SEPARATOR
  )

  return {
    identifier: orderItem.orderItemId,
    active: !!subscriptionValue,
    recurrency: {
      fulfillmentInterval: subscriptionValue,
      fulfillmentIntervalUOM: subscriptionInterval,
    },
  }
}

/**
 * Convert a subscription recurrency object to a subscription recurrency string
 * @param recurrency the object to be converted
 * @returns a string representation of the objec (ex: '1|MON')
 */
export const stringifySubscriptionRecurrency = (recurrency: SubscriptionRecurrency | undefined): string => {
  return `${recurrency?.fulfillmentInterval}|${recurrency?.fulfillmentIntervalUOM}`
}

/**
 * Given a recurrency string (ex: '1|MON') convert it to a subscription recurrency object
 * @param recurrencyString The string to be parsed
 * @returns the Object representation of the recurrency (ex: {value: 2, interval: 'MON'})
 */
export const parseRecurrencyString = (recurrencyString: string | undefined): SubscriptionRecurrency => {
  const { SEPARATOR, VALUE_INDEX, RECURRENCY_INDEX } = SUBSCRIPTION_RECURRENCY_STRING
  return {
    fulfillmentInterval: toNumber(recurrencyString?.split(SEPARATOR)[VALUE_INDEX] ?? 1),
    fulfillmentIntervalUOM: recurrencyString?.split(SEPARATOR)[RECURRENCY_INDEX] ?? 'MON',
  }
}

/**
 * Return all the subscribed items from an array of order items
 * @param orderItems the array object to be searched
 * @returns the filtered array of order items
 */
export const getSubscribedItems = (orderItems: IOrderItem[]) => {
  return orderItems.filter(item => isItemSubscribed(item))
}

/**
 * Get the adjustment from subscriptions in the current cart or order item
 * @param item the current cart or order item representation
 * @returns the adjustment object
 */
export const getAdjustment = (item: Cart | IOrderItem): Adjustment | undefined => {
  const adjustments = item?.adjustment || []
  return adjustments.find(adjustment => {
    const adjustmentText = adjustment?.code?.toString().toUpperCase() || ''

    return (
      adjustmentText.startsWith(SUBSCRIPTION_DISCOUNT_ADJUSTMENTS.ITEM) &&
      adjustmentText.indexOf('SHIPPING') === -1 &&
      adjustmentText.indexOf('HANDLING') === -1
    )
  })
}

/**
 * Get the description from the subscription item in the current cart or order item
 * @param eSpotData the marketing eSpot definition to be read
 * @returns the description
 */
export const getDescriptionFromMarketingSpot = (eSpotData: MarketingSpotData[] | undefined): string | undefined => {
  return eSpotData?.find(spot => spot.eSpotName === SUBSCRIPTION_TERMS_AND_CONDITIONS_ESPOT)
    ?.baseMarketingSpotActivityData?.[0].marketingContentDescription[0]?.marketingText
}

export type DiscountType = {
  discountStringValue: string
  discountAbsoluteValue: number
  discountPercentValue: number
} | null

export const gestDiscountFromFromMarketingSpot = (eSpotData: MarketingSpotData[] | undefined): DiscountType => {
  const regex = /\b\d+%?\b/
  const description = getDescriptionFromMarketingSpot(eSpotData) || ''
  const match = description.match(regex)
  const discount = match && !!parseFloat(match?.[0]) ? match[0] : null

  return discount
    ? {
        discountStringValue: discount + '%',
        discountAbsoluteValue: Number(discount),
        discountPercentValue: Number(discount) / 100,
      }
    : null
}
/**
 * Get the description from the subscription adjustment in the current cart or order item
 * @param item the current cart or order item representation
 * @returns the description
 */
export const getDescriptionFromAdjustment = (item: Cart | IOrderItem): string | undefined => {
  return getAdjustment(item)?.description
}

/**
 * Calculates values for discount and full order price based on order items
 * @param orderItems
 * @returns array of prices, first - total order discount, second - total order price (list prices summed up)
 */
export const getDiscountAndFullPrice = (orderItems?: IOrderItem[]): [number, number] => {
  if (!orderItems || !orderItems.length) return [0, 0]
  const [discount, fullPrice] = orderItems.reduce(
    (accumulator, currentValue) => {
      const totalAdjustment =
        accumulator[0] +
        parseFloat(currentValue?.adjustment?.find(adj => adj.code.indexOf('SUBSCRIPTION_PROMO') > -1)?.amount || '0')
      const totalPrice = accumulator[1] + parseFloat(currentValue.unitPrice) * parseInt(currentValue.quantity)
      return [totalAdjustment, totalPrice]
    },
    [0, 0]
  )
  return [discount, fullPrice]
}

/**
 * Get the adjustment amount from subscriptions in the current cart or order item
 * @param {IOrderItem} currentItem - the current cart or order item representation
 * @param {IOrderItem[]} orderItems - a list of all the items in the order or cart to look for grouped discounts
 * @param {boolean} showAbsValue - whether to show as a positive value or not
 * @returns {number} - the adjustment amount
 */
export const getTotalAdjustments = (
  currentItem: IOrderItem,
  orderItems: IOrderItem[],
  showAbsValue = false
): number => {
  let totalAdjustment = 0
  const itemsToCheck = !!orderItems.length ? orderItems : [currentItem]

  itemsToCheck.forEach(item => {
    if (item.orderItemId === currentItem.orderItemId || currentItem.groupedOrderItemsId?.includes(item.orderItemId)) {
      const adjustment = getAdjustment(item)
      totalAdjustment += toNumber(adjustment?.amount || 0)
    }
  })

  return showAbsValue ? Math.abs(totalAdjustment) : totalAdjustment
}

/**
 *
 * @param orderItem Check if the order item is in the list of subscrible items
 * @param subscriptionConfig the environment configuration object
 * @returns true if the order item is in the list of subscrible items, false otherwise
 */
export const isSubscriptionAllowedForItem = (
  orderItem: IOrderItem,
  mySite: SiteInfo,
  subscriptionConfig: ProductSubscriptionReducerState
): boolean => {
  const isMTO = storeUtil.isMTOEnabled(mySite) && isMtoOrderItem(orderItem)
  return (
    !isMTO &&
    !!(
      subscriptionConfig.enabled &&
      ((isContactLensesProduct(orderItem) &&
        subscriptionConfig.allowedProducts?.includes(PRODUCT_TYPES_KEYS.CONTACT_LENSES)) ||
        (isClAccessoriesProduct(orderItem) &&
          subscriptionConfig.allowedProducts?.includes(PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES)))
    )
  )
}

/**
 * Gets the min interval of all the order subscriptions
 * @param orderItems the order items array
 * @returns the min amount or 0 if none
 */
export const getMinSubscriptionInterval = (orderItems: IOrderItem[]) => {
  let minRecurrency = 0
  const subscribedItems = getSubscribedItems(orderItems)
  subscribedItems.forEach(orderItem => {
    const intervalString = orderService.getSubscriptionRecurrency(orderItem.orderItemExtendAttribute)
    const [recurency] = intervalString.split('|')
    if (minRecurrency === 0) {
      minRecurrency = toNumber(recurency)
    } else {
      minRecurrency = Math.min(minRecurrency, toNumber(recurency))
    }
  })
  return minRecurrency
}

/**
 * Returns the subscription status based on the original state and reason code
 * @param subscription the subscription information
 * @param statusReason the status reason or blank if none
 * @returns the Subscription status:  Active, Suspended, InActive, Cancelled, PendingCancel, Paused or UNKNOWN
 */
export const getSubscriptionStatus = (
  subscription: SubscriptionItem | undefined,
  statusReason?: string | undefined | null
) => {
  let subscriptionStatus = subscription?.state || 'UNKNOWN'
  if (statusReason == '5' && subscriptionStatus === SUBSCRIPTION_STATES.InActive) {
    subscriptionStatus = SUBSCRIPTION_STATES.Suspended
  } else if (subscription?.state === SUBSCRIPTION_STATES.InActive && (!statusReason || statusReason == '1')) {
    subscriptionStatus = SUBSCRIPTION_STATES.Paused
  }
  return subscriptionStatus
}

/**
 * Returns merged adjustment with the amounts combined from 2 different adjustment arrays.
 * Needed to combine orderItem with sibilingOrderItem
 * @param adjustments array of adjustment arrays
 * @returns adjustment array with combined amount values for each item
 */
export const mergeAdjustments = (adjustments: Adjustment[][]): Adjustment[] => {
  const mergedAdjustments: { [code: string]: Adjustment } = adjustments.reduce((acc, adjustmentList) => {
    adjustmentList.forEach(item => {
      if (acc[item.code]) {
        acc[item.code].amount = (parseFloat(acc[item.code].amount) + parseFloat(item.amount)).toString()
      } else {
        acc[item.code] = { ...item }
      }
    })
    return acc
  }, {})

  return Object.values(mergedAdjustments)
}
export const isSubscriptionWalletValid = (expirationDate: string) => {
  const today = dayjs()
  const cardDate = dayjs(expirationDate)
  const isCardValid = today.isBefore(cardDate)

  return isCardValid
}
