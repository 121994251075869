import { GeneralFormFields, IFormField } from './form'
import { ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS } from './productSubscription'
import { ServerError } from '@typesApp/common'

export interface ContactAttributes {
  value: string
  key: string
}
export interface GeneralUserFields {
  personTitle?: string
  dateOfBirth?: string
  email1: string
  firstName: string
  gender?: string
  lastName: string
  nickName?: string
  phone1?: string
  attributes?: Array<ContactAttributes>
}

export type PersonalDetails = Omit<GeneralUserFields, 'nickName'>

export type Contact = Omit<GeneralFormFields, 'addressLine1' | 'addressLine2'> &
  Pick<GeneralUserFields, 'personTitle' | 'email1' | 'firstName' | 'lastName' | 'nickName' | 'phone1' | 'attributes'>

export type ContactRequest = Contact & {
  addressLine: string[]
}

interface AttributeValue {
  storeId: string
  value: string[]
}
interface ContextAttribute {
  attributeName: string
  attributeValue: AttributeValue[]
}

export type XDataSubscriptions = {
  subscriptionStatus: ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS
  subscriptionId: string
  addressIds: string[]
}

export interface PersonResponse
  extends Pick<
      GeneralFormFields,
      'addressId' | 'address1' | 'address2' | 'addressType' | 'city' | 'country' | 'state' | 'zipCode'
    >,
    Pick<GeneralUserFields, 'dateOfBirth' | 'email1' | 'firstName' | 'gender' | 'lastName' | 'nickName' | 'phone1'> {
  accountStatus: string
  challengeQuestion: string
  contact?: Contact[]
  contactMap?: Contact[]
  contextAttribute: ContextAttribute[]
  distinguishedName: string
  lastUpdate: string
  logonId: string
  orgizationId: string
  organizationDistinguishedName: string
  passwordExpired: string
  preferredLanguage: string
  preferredCurrency: string
  profileType: string
  registrationApprovalStatus: string
  registrationDateTime: string
  registrationStatus: string
  resourceId: string
  resourceName: 'person'
  userId: string
  fiscalCode?: string
  attributes?: [
    {
      contactInfoAttrValue: string
      contactInfoAttrKey: string
    },
  ]
  x_data: {
    hasNewsletter: boolean
    lastUserLogin: string
    wishlistItemCount: number
    customerSegments?: { name: string }[]
    subscriptions?: XDataSubscriptions[]
  }
  x_optIn?: {
    x_phiconsent?: 'true' | 'false'
  }
}

export type UserDetails = PersonResponse

export type PersonalInfoFormData = Pick<
  PersonResponse,
  | 'preferredLanguage'
  | 'preferredCurrency'
  | 'gender'
  | 'dateOfBirth'
  | 'email1'
  | 'logonId'
  | 'firstName'
  | 'lastName'
  | 'phone1'
  | 'fiscalCode'
>

export type PersonalInfoFormField = IFormField<keyof PersonalInfoFormData>

export type RegistrationFormData = {
  firstName: string
  lastName: string
  email: string
  email2: string
  receiveEmail?: string
  dateOfBirth?: string
  newPassword: string
  confirmPassword?: string
  privacy1: boolean
  privacy2: boolean
  privacy3?: boolean
  fiscalCode?: string
  gender?: string
}

export type SettingsFormData = {
  currentPassword: string
  newPassword: string
  confirmPassword?: string
}

export interface PersonWalletResponse {
  wallet: Wallet[]
  recordTotalCount: number
  resourseName: string
}

interface WalletSubscriptions {
  subscriptionId: string
  subscriptionStatus: string
}

export type Wallet = {
  paymentToken: string
  owner: string
  identifier: number
  isDefault: boolean
  creditCardBin: string
  protectedCCNumber: string
  expireDate: string
  creditCardPan: string
  issuer: string
  payMethodId: string
  creation: string
  addressId: number
  subscriptions?: WalletSubscriptions[]
}

export interface Card {
  id: string
  cardHolderName: string
  cardNumber: string
  expiryDate: string
  cvv?: string
  billingEmail?: string
  billingAddress?: string
  billingCity?: string
  billingProvince?: string
  billingPostalCode?: string
  billingCountry?: string
  isDefault: boolean
  payMethodId?: string
}

export type ReminderFormData = {
  email?: string
  phone?: string
  date?: string
  frequency?: number
}

export enum RegisterType {
  R = 'R', // Registered
  G = 'G', // Guest
}

export interface RegistrationBodyData {
  firstName: string
  lastName: string
  logonId: string
  logonPassword: string
  registerType: string
  profileType: string
  email1: string
  phone1: string
  storeId: string
  catalogId: string
  preferredLanguage: string
  preferredCurrency: string
  receiveEmail: string | undefined
  receiveEmailPreference: {
    value: string | undefined
    storeID: string
  }[]
  challengeQuestion: string
  challengeAnswer: string
  x_optIn: {
    x_privacy: boolean
    x_newsLetter: boolean
    x_profiling: boolean
  }
}

export type RegisterEvaluationResult = {
  success: boolean
  errorData?: ServerError
}

export interface UserPHIConsentUpdatePayload {
  x_phiconsent: '1' | '0'
  x_from: string
  storeId: string
}

export interface UserPHIConsentUpdateResponse {
  addressId: string
  resourceName: string
  userId: string
}
